define('busy-app/routes/employees/index', ['exports', '@busy-web/utils', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment'], function (exports, _utils, _authenticatedRouteMixin, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		subscription: Ember.inject.service(),

		queryParams: {
			group: {
				refreshModel: true
			},
			search: {
				refreshModel: true
			},
			page: {
				refreshModel: true
			},
			page_size: {
				refreshModel: true
			},
			filter: {
				refreshModel: true
			}
		},

		onSetup: Ember.on('activate', function () {
			this.setDefaultPageSize();
		}),

		resetController: function resetController(controller) {
			controller.resetFilters();
			this.setDefaultPageSize();
		},
		setDefaultPageSize: function setDefaultPageSize() {
			var qp = Ember.get(this, '_qp');
			var pageSize = parseInt(_utils.LocalStorage.getProperty('member-pagination-size') || 30, 10);

			if (pageSize && qp && qp.qps) {
				var obj = qp.qps.findBy('prop', 'page_size');
				if (obj) {
					var defaultValue = parseInt(obj.defaultValue || 30, 10);
					if (defaultValue !== pageSize) {
						obj.defaultValue = pageSize;
						obj.serializedDefaultValue = pageSize;
						obj.undecoratedDefaultValue = pageSize;
					}
				}
			}
		},


		/**
   * model setup
   *
   * @method model
   * @param {Object} params
   * @returns {RSVP}
   */
		model: function model(params) {
			var _this = this;

			var accessParams = this.getFilterPermission(Ember.get(params, 'filter'));
			var accessObj = this.getAccess(accessParams);
			var hasAccess = accessObj.self && accessObj.same && accessObj.lower && accessObj.higher;
			if (!hasAccess) {
				params.positions = this.getAccessForPermision(accessParams);
				hasAccess = Ember.get(params, 'positions.length') > 0;
			}

			var controller = this.controllerFor('employees/index');
			Ember.set(this, 'controller', controller);
			Ember.set(this, 'controller.isLoading', true);

			return this.getMembersQueryParams(params, accessObj).then(function (queryParams) {
				return _this.getMembers(queryParams);
			}).then(function (members) {
				var index = 0;
				members.forEach(function (member) {
					Ember.set(member, 'index', index++);
					Ember.set(member, 'isSelected', false);
				});

				var ids = members.map(function (member) {
					return Ember.get(member, 'id');
				});

				controller.getGroups();
				return _this.getMemberData(members, ids);
			}).catch(function (err) {
				return Ember.set(_this, 'controller.error', err) && Ember.RSVP.reject(err);
			}).finally(function () {
				return Ember.set(_this, 'controller.isLoading', false);
			});
		},
		getMembers: function getMembers(queryParams) {
			if (!Ember.isNone(Ember.get(queryParams, '_inFilter'))) {
				var key = Ember.get(queryParams, '_inFilter.key');
				var values = Ember.get(queryParams, '_inFilter.values');
				delete queryParams._inFilter;

				if (!Ember.get(values, 'length')) {
					return Ember.RSVP.resolve([]);
				}

				// call member findWhereIn
				return this.store.findWhereIn('member', key, values, queryParams);
			} else {
				// call member query
				return this.store.query('member', queryParams);
			}
		},
		getMembersQueryParams: function getMembersQueryParams(_ref, accessObj) {
			var _this2 = this;

			var _ref$group = _ref.group,
			    group = _ref$group === undefined ? null : _ref$group,
			    _ref$search = _ref.search,
			    search = _ref$search === undefined ? '' : _ref$search,
			    _ref$page_size = _ref.page_size,
			    page_size = _ref$page_size === undefined ? 30 : _ref$page_size,
			    _ref$page = _ref.page,
			    page = _ref$page === undefined ? 1 : _ref$page,
			    _ref$positions = _ref.positions,
			    positions = _ref$positions === undefined ? null : _ref$positions,
			    _ref$filter = _ref.filter,
			    filter = _ref$filter === undefined ? null : _ref$filter;

			var selfMember = Ember.get(this, 'access.auth.memberId');

			var query = {
				_asc: ['first_name', 'last_name'],
				page_size: page_size,
				page: page
			};

			// NOTES:
			// positions array States
			// positions equals null => auth member has admin access
			// positions equals empty array => auth member has no access to members exepct self
			// positions has results => auth member has access to these position plus self
			var positionQuery = { archived_on: null };
			if (group === 'archived') {
				positionQuery = { archived_on: '!_-NULL-_' };
			}

			return Ember.RSVP.hash({
				membersByPos: this.store.findWhereIn('member', 'position_id', positions || [], positionQuery).then(function (pos) {
					return pos.map(function (item) {
						return Ember.get(item, 'id');
					});
				}),
				filterIds: this.getFilterList(filter, Object.assign({}, query))
			}).then(function (data) {
				if (!Ember.isEmpty(positions) && accessObj.self) {
					if (data.membersByPos.indexOf(selfMember) === -1) {
						data.membersByPos.push(selfMember);
					}
				}

				if (group === 'archived') {
					query.archived_on = '!_-NULL-_';
					query.deleted_on = '_-DISABLE-_';
				} else if (group === 'ungrouped') {
					query.member_group_id = null;
					query.archived_on = null;
				} else {
					if (!Ember.isEmpty(group)) {
						query.member_group_id = group;
					}
					query.archived_on = null;
				}

				if (!Ember.isEmpty(search)) {
					query._search = search;
				}

				var filterIds = [];
				if (!Ember.isEmpty(data.filterIds) && !Ember.isEmpty(data.membersByPos)) {
					data.filterIds.forEach(function (id) {
						if (positions.indexOf(id) !== -1) {
							filterIds.push(id);
						}
					});
				} else if (Ember.isEmpty(data.filterIds) && !Ember.isEmpty(data.membersByPos)) {
					filterIds = data.membersByPos;
				} else if (!Ember.isEmpty(data.filterIds) && Ember.isEmpty(data.membersByPos)) {
					filterIds = data.filterIds;
				}

				if (!Ember.isEmpty(filterIds)) {
					query._inFilter = { key: 'id', values: filterIds };
				}

				if (Ember.get(_this2, 'auth.useGroupPermissionScoping')) {
					var memberGroupId = Ember.get(_this2, 'auth.member.memberGroupId');

					if (Ember.isNone(memberGroupId)) {
						Ember.set(query, 'id', selfMember);
					} else {
						Ember.set(query, 'member_group_id', memberGroupId);
					}
				}

				return query;
			});
		},
		getMemberData: function getMemberData(members, ids) {
			var _this3 = this;

			var _getDayBoundaryDateSt = this.getDayBoundaryDateStamps(),
			    dayStart = _getDayBoundaryDateSt.dayStart,
			    dayEnd = _getDayBoundaryDateSt.dayEnd;

			var relatedModels = ['timeEntries', 'openEntry', 'timeOffEntries'];

			if (Ember.get(this, 'features.locks')) {
				relatedModels.push('memberLocks');
			}

			return this.store.filter('member').getRelatedModelsByRangeForMembers(dayStart, dayEnd, ids, relatedModels).then(function (props) {
				var entries = [];
				members.forEach(function (member) {
					// get ralated models for this member
					var relationships = {};
					relationships.timeEntries = props.timeEntries.filterBy('memberId', Ember.get(member, 'id')).sortBy('startTime');
					relationships.timeOffEntries = props.timeOffEntries.filterBy('memberId', Ember.get(member, 'id'));
					relationships.openEntry = props.openEntry.findBy('memberId', Ember.get(member, 'id'));
					if (Ember.get(_this3, 'features.locks') && !Ember.isNone(Ember.get(props, 'memberLocks'))) {
						relationships.memberLock = props.memberLocks.findBy('memberId', Ember.get(member, 'id'));
					}

					// save a reference to the last time entry
					if (!Ember.isNone(Ember.get(relationships, 'timeEntries.lastObject'))) {
						entries.push(Ember.get(relationships, 'timeEntries.lastObject'));
					}

					// save a reference to the open entry and start the timer
					if (!Ember.isNone(Ember.get(relationships, 'openEntry'))) {
						Ember.get(relationships, 'openEntry').setTimeInterval(60000);
						entries.push(Ember.get(relationships, 'openEntry'));
					}

					// add the relationships to this member
					member.setProperties(relationships);
				});
				return members;
			});
		},


		/**
   * rely on pass by reference
   *
   * @method attachTimeEntryRelatedModels
   */
		attachTimeEntryRelatedModels: function attachTimeEntryRelatedModels(timeEntry) {
			if (!Ember.isNone(timeEntry)) {
				this.store.filter('time-entry').getRelatedModels(timeEntry).then(function (props) {
					props.timeEntryBreaks = props.breaks;
					timeEntry.setProperties(props);
				});
			}
		},


		/**
   * returns startTime and endTime epoch timestamps for the given startDate and endDate, defaults to current day
   *
   * @method getDayBoundaryDateStamps
   */
		getDayBoundaryDateStamps: function getDayBoundaryDateStamps() {
			var startDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _utils.Time.date();
			var endDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : startDate;
			(true && !(_moment.default.isMoment(startDate)) && Ember.assert('startDate must be a Moment Object', _moment.default.isMoment(startDate)));
			(true && !(_moment.default.isMoment(endDate)) && Ember.assert('endDate must be a Moment Object', _moment.default.isMoment(endDate)));


			var dayStart = startDate.startOf('day').unix();
			var dayEnd = endDate.endOf('day').unix();

			return { dayStart: dayStart, dayEnd: dayEnd };
		},
		getFilterPermission: function getFilterPermission(type) {
			var accessParams = {
				isManageEmployee: false,
				isManageTime: false,
				isTrackTime: false,
				isMemberLock: false
			};

			if (type === 'clock-in' || type === 'clock-out' || type === 'switch' || type === 'go-on-break' || type === 'go-off-break' || type === 'locations') {
				accessParams.isTrackTime = true;
			} else if (type === 'clock-in-at' || type === 'clock-out-at' || type === 'new-time-entry' || type === 'new-time-off') {
				accessParams.isManageTime = true;
				// } else if(type === 'locations') {
				// 	accessParams.isManageEmployee = true;
			} else if (type === 'archive') {
				accessParams.isManageEmployee = true;
			} else if (type === 'edit-lock-date') {
				accessParams.isMemberLock = true;
			} else {
				accessParams.isManageEmployee = true;
				accessParams.isManageTime = true;
				accessParams.isTrackTime = true;
				accessParams.isMemberLock = true;
			}
			return accessParams;
		},
		getFilterList: function getFilterList(type, query) {
			var _this4 = this;

			if (Ember.isNone(type)) {
				return Ember.RSVP.resolve([]);
			}

			if (type === 'clock-in' || type === 'clock-in-at') {
				return this.store.findAll('time-entry', { end_time: null, deleted_on: null }).then(function (entry) {
					return _this4.store.findAll('member', { archived_on: null }).then(function (member) {
						var entryIds = _this4.getIdsFromModel(entry, 'memberId');
						var model = member.filter(function (item) {
							return entryIds.indexOf(item.id) === -1;
						});

						return _this4.getIdsFromModel(model, 'id');
					});
				});
			} else if (type === 'clock-out' || type === 'clock-out-at' || type === 'switch' || type === 'go-on-break') {
				return this.store.findAll('time-entry', { end_time: null, deleted_on: null }).then(function (model) {
					return _this4.getIdsFromModel(model, 'memberId');
				});
			} else if (type === 'go-off-break') {
				return this.store.findAll('time-entry-break', { end_time: null }).then(function (breaks) {
					return _this4.store.findWhereIn('time-entry', 'id', _this4.getIdsFromModel(breaks, 'timeEntryId'), { end_time: null, deleted_on: null }).then(function (model) {
						return _this4.getIdsFromModel(model, 'memberId');
					});
				});
			} else if (type === 'locations') {
				return this.store.findAll('time-entry', { end_time: null, deleted_on: null }).then(function (model) {
					model = model.filter(function (item) {
						if (!Ember.isNone(Ember.get(item, 'startLocationId')) || !Ember.isNone(Ember.get(item, 'endLocationId'))) {
							return true;
						}
						return false;
					});
					return _this4.getIdsFromModel(model, 'memberId');
				});
			} else if (type === 'archive') {
				// exclude self and owner from archive results.
				var authMember = Ember.get(this, 'access.auth.member.id');
				var ownerMember = Ember.get(this, 'access.auth.organization.ownedBy');
				var notList = [authMember];
				if (authMember !== ownerMember) {
					notList.push(ownerMember);
				}

				query._not_in = { id: notList };

				return Ember.RSVP.resolve(null);
			} else {
				return Ember.RSVP.resolve(null);
			}
		},
		getAccess: function getAccess(_ref2) {
			var _ref2$isManageEmploye = _ref2.isManageEmployee,
			    isManageEmployee = _ref2$isManageEmploye === undefined ? false : _ref2$isManageEmploye,
			    _ref2$isManageTime = _ref2.isManageTime,
			    isManageTime = _ref2$isManageTime === undefined ? false : _ref2$isManageTime,
			    _ref2$isTrackTime = _ref2.isTrackTime,
			    isTrackTime = _ref2$isTrackTime === undefined ? false : _ref2$isTrackTime,
			    _ref2$isMemberLock = _ref2.isMemberLock,
			    isMemberLock = _ref2$isMemberLock === undefined ? false : _ref2$isMemberLock;

			var access = Ember.get(this, 'access');
			var self = false,
			    same = false,
			    lower = false,
			    higher = false;

			if (!Ember.isNone(access)) {
				var permission = Ember.get(access, 'auth.authMemberPosition');
				if (!Ember.isNone(permission)) {
					var manageEmp = access.parseSetting(Ember.get(permission, 'manageEmployees'));
					var trackTime = access.parseSetting(Ember.get(permission, 'timeEvents'));
					var manageTime = access.parseSetting(Ember.get(permission, 'manageTimeEntries'));
					var memberLock = access.parseSetting(Ember.get(permission, 'memberLock'));

					var validatePermission = function validatePermission(type) {
						return isManageEmployee && Ember.get(manageEmp, type) || isTrackTime && Ember.get(trackTime, type) || isManageTime && Ember.get(manageTime, type) || isMemberLock && Ember.get(memberLock, type);
					};

					self = validatePermission('self');
					same = validatePermission('same');
					lower = validatePermission('lower');
					higher = validatePermission('higher');
				}
			}
			return { self: self, same: same, lower: lower, higher: higher };
		},
		getAccessForPermision: function getAccessForPermision(_ref3) {
			var _ref3$isManageEmploye = _ref3.isManageEmployee,
			    isManageEmployee = _ref3$isManageEmploye === undefined ? false : _ref3$isManageEmploye,
			    _ref3$isManageTime = _ref3.isManageTime,
			    isManageTime = _ref3$isManageTime === undefined ? false : _ref3$isManageTime,
			    _ref3$isTrackTime = _ref3.isTrackTime,
			    isTrackTime = _ref3$isTrackTime === undefined ? false : _ref3$isTrackTime,
			    _ref3$isMemberLock = _ref3.isMemberLock,
			    isMemberLock = _ref3$isMemberLock === undefined ? false : _ref3$isMemberLock;

			var permission = Ember.get(this, 'access.auth.authMemberPosition');
			var accessObj = this.getAccess({ isManageEmployee: isManageEmployee, isManageTime: isManageTime, isTrackTime: isTrackTime, isMemberLock: isMemberLock });
			var accessTo = [];

			Ember.get(this, 'access.auth.positions').sortBy('level').forEach(function (item) {
				var lower = Ember.get(accessObj, 'lower') && Ember.get(item, 'level') > Ember.get(permission, 'level');
				var same = Ember.get(accessObj, 'same') && Ember.get(item, 'level') === Ember.get(permission, 'level');
				var higher = Ember.get(accessObj, 'higher') && Ember.get(item, 'level') < Ember.get(permission, 'level');

				if (lower || higher || same) {
					accessTo.push(Ember.get(item, 'id'));
				}
			});
			return accessTo;
		},
		getIdsFromModel: function getIdsFromModel(model, path) {
			return model.map(function (item) {
				return Ember.get(item, path);
			});
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});