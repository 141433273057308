define('busy-app/utils/export-csv', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		data: null,
		dataMap: null,

		setDataMap: function setDataMap(dataMap) {
			(true && !(Ember.typeOf(dataMap) === 'object') && Ember.assert('setDataMap requires an key:value object where key is the data mapped path and value is the header title', Ember.typeOf(dataMap) === 'object'));


			var _map = Ember.Map.create();
			Ember.$.each(dataMap, function (key, value) {
				if (dataMap.hasOwnProperty(key)) {
					_map.set(key, value);
				}
			});
			this.set('dataMap', _map);
		},
		setData: function setData(data) {
			(true && !(Ember.typeOf(data) === 'array') && Ember.assert('setData requires an array of objects', Ember.typeOf(data) === 'array'));

			this.set('data', data);
		},
		buildHeader: function buildHeader(dataMap) {
			var header = [];
			dataMap.forEach(function (value) {
				header.push(value);
			});
			header.join(',');
			return header + '\n';
		},
		buildContent: function buildContent(dataMap, data) {
			var _this = this;

			var line = [];
			dataMap.forEach(function (value, key) {
				var val = Ember.get(data, key) || "";
				line.push(_this.csvSafeString(val));
			});
			line.join(',');
			return line + '\n';
		},
		csvSafeString: function csvSafeString(str) {
			// replace non breaking spaces with regular space
			str = str.replace(/\u00a0/g, ' ');

			// remove begining and end quotes
			str = str.replace(/^"/, '');
			str = str.replace(/"$/, '');

			// escape any quotes left inside the string
			str = str.replace(/"/g, '""');

			// add the begining and ending quotes
			return '"' + str + '"';
		},
		buildContentString: function buildContentString() {
			var _this2 = this;

			var data = this.get('data');
			var dataMap = this.get('dataMap');
			var content = '';
			content += this.buildHeader(dataMap);
			data.forEach(function (item) {
				content += _this2.buildContent(dataMap, item);
			});
			return content;
		},
		generate: function generate(name) {
			var date = _utils.Time.date().format('MM-DD-YYYY');
			var content = this.buildContentString();
			var filename = name + '_' + date + '.csv';
			this.triggerDownload(content, filename);
		},
		triggerDownload: function triggerDownload(content, filename) {
			var type = filename.split('.')[1];

			(true && !(typeof content === 'string') && Ember.assert('content must be string', typeof content === 'string'));
			(true && !(!Ember.isNone(type)) && Ember.assert("filename must include the file extension", !Ember.isNone(type)));


			if (window.navigator.msSaveOrOpenBlob) {
				var blob = new window.Blob([decodeURIComponent(encodeURI(content))], {
					type: 'text/' + type + ';charset=utf-8;'
				});
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var fileContent = "data:application/octet-stream;base64,";
				fileContent += this.encodeBase64(content);

				var link = document.createElement('a');
				document.body.appendChild(link);
				link.setAttribute('href', fileContent);
				link.setAttribute('download', filename);

				link.click();
			}
		},
		encodeBase64: function encodeBase64(str) {
			return window.btoa(this.encodeUTF8(str));
		},
		encodeUTF8: function encodeUTF8(str) {
			return window.unescape(encodeURIComponent(str));
		}
	});
});