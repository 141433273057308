define('busy-app/components/busy-map-locations', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-map-locations'],

		/** public property list */
		map: null,
		mapMarkers: null,
		mapAutocomplete: null,
		canEdit: false,
		locationsInfo: null,
		selectedEmployees: null,

		activeInfoWindow: null,

		model: null,

		init: function init() {
			this._super();
			this.initializer();
		},


		/**
   * inititalizes the Map
   *
   * @private
   * @method initializer
   * @returns {void}
   */
		initializer: Ember.observer('model', 'model.length', function () {
			if (this.get('model.length') > 0) {
				this.loadMap(this.get('model'));
			}
		}),

		/**
   * Loads the Map based on info
   * from locationsInfo. If no locationsInfo
   * it will load the default Map which is
   * busybusy office building.
   *
   * @public
   * @method loadMap
   * @returns {void}
   */
		loadMap: function loadMap(locations) {
			var _this = this;

			if (this.$('#map-canvas') && this.$('#map-canvas').length > 0 && !Ember.isNone(locations) && locations.get('length') > 0) {
				var markers = [];
				locations.forEach(function (item) {
					var obj = _this.createLocationObject(item);
					markers.pushObject(obj);
				});
				this.set('mapMarkers', markers);
				this.loadMapByLocation(markers);
			}
		},


		/**
   * Creates a location object from a timeEntry
   *
   * @public
   * @method createLocationObject
   * @param location {object} The timeEntry to get locations on
   * @returns {obj}
   */
		createLocationObject: function createLocationObject(location) {
			var locationType = '';
			var clockType = '';
			var timeStr = '';

			if (!Ember.isNone(location.get('startTimeEntry.id'))) {
				timeStr = _utils.Time.date(location.get('startTimeEntry.startTime')).format('h:mm A');
				locationType = 'startLocation';
				clockType = 'Clocked In @ ';
			} else if (!Ember.isNone(location.get('endTimeEntry.id'))) {
				timeStr = _utils.Time.date(location.get('endTimeEntry.endTime')).format('h:mm A');
				locationType = 'endLocation';
				clockType = 'Clocked Out @ ';
			} else if (!Ember.isNone(location.get('breakStart'))) {
				timeStr = _utils.Time.date(location.get('breakStart.startTime')).format('h:mm A');
				locationType = 'breakStartLocation';
				clockType = 'Break started @ ';
			} else if (!Ember.isNone(location.get('breakEnd'))) {
				timeStr = _utils.Time.date(location.get('breakEnd.endTime')).format('h:mm A');
				locationType = 'breakEndLocation';
				clockType = 'Break ended @ ';
			}

			var obj = {
				image: location.get('member.imageThumbUrl'),
				name: location.get('member.fullName'),
				lat: location.get('latitude'),
				lng: location.get('longitude'),
				locationType: locationType,
				clockString: clockType + timeStr
			};
			return obj;
		},


		/**
   * Loads the Map based on params
   *
   * @public
   * @method loadMapByLocation
   * @param latitude {number} The latitude of the location
   * @param longitude {number} The longitude of the location
   * @param radius {number} The accuracy or radius of location.
   * @returns {void}
   */
		loadMapByLocation: function loadMapByLocation(markersObj) {
			var _this2 = this;

			var noLocation = true;
			if (markersObj.length > 0) {
				this.clearMap();

				var google = window.google;
				var container = this.$('#map-canvas').get(0);
				var bounds = new google.maps.LatLngBounds();

				// var mapCenter = this.getMapCenter(markersObj.objectAt(0));
				// var mapZoom = this.getMapZoom();

				//Map Options
				var options = {
					// zoom: mapZoom,
					// center: mapCenter,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				};

				var map = new google.maps.Map(container, options);

				this.set('map', map);

				markersObj.forEach(function (mapMarker) {
					var position = new window.google.maps.LatLng(mapMarker.lat, mapMarker.lng);
					bounds.extend(position);
					this.createMarker(mapMarker);
				}, this);

				map.fitBounds(bounds);
				noLocation = false;

				// add listener to close any open InfoWindow when the map is clicked
				map.addListener('click', function () {
					_this2.closeActiveInfoWindow();
				});
			}

			this.set('noLocation', noLocation);
		},
		clearMap: function clearMap() {
			var map = this.get('map');
			var container = this.$('#map-canvas');

			if (!Ember.isNone(map)) {
				this.set('map', null);

				window.google.maps.event.clearInstanceListeners(window);
				window.google.maps.event.clearInstanceListeners(document);
				window.google.maps.event.clearInstanceListeners(container[0]);

				container.children().detach();
			}
		},


		/**
   * Loads the Default Map which is
   * busybusy office building.
   *
   * @public
   * @method loadDefaultMap
   * @returns {void}
   */
		loadDefaultMap: function loadDefaultMap() {
			var latitude = 37.100999;
			var longitude = -113.538680;
			this.loadMapByLocation(latitude, longitude);
		},


		/**
   * Finds the center of the gps locations array and returns the latlng
   * as the center of the map.
   *
   * TODO:
   * Finish this someday!!!
   *
   * @public
   * @method getMapCenter
   * @param marker {object} The first location object until this is improved
   * @returns {Google.LatLng}
   */
		getMapCenter: function getMapCenter(marker) {
			return new window.google.maps.LatLng(marker.lat, marker.lng);
		},
		getMapZoom: function getMapZoom() {
			return 17;
		},
		createMarker: function createMarker(mapMarker) {
			var _this3 = this;

			var map = this.get('map');
			var marker = new window.google.maps.Marker({ //jshint ignore:line
				map: map,
				position: new window.google.maps.LatLng(mapMarker.lat, mapMarker.lng),
				animation: window.google.maps.Animation.DROP,
				icon: this.setMarkerType(mapMarker.locationType),
				shape: {
					coords: [1, 1, 1, 30, 20, 30, 30, 1],
					type: 'poly'
				}
			});

			var infoWindow = new window.google.maps.InfoWindow({
				content: this.markerHTML(mapMarker.image, mapMarker.name, mapMarker.clockString)
			});

			marker.addListener('click', function () {
				_this3.closeActiveInfoWindow();

				infoWindow.open(map, marker);
				_this3.set('activeInfoWindow', infoWindow);
			});
		},
		markerHTML: function markerHTML(imageUrl, memberName, clockString) {
			imageUrl = !Ember.isNone(imageUrl) ? imageUrl : '/images/imageplaceholder.png';

			return '<div class="map-marker-info">' + '<span class="image">' + '<img src="' + imageUrl + '">' + '</span>' + '<span class="info">' + '<h3 class="name">' + memberName + '</h3>' + '<p>' + clockString + '</p>' + '</span>' + '</div>';
		},
		setMarkerType: function setMarkerType(locationType) {
			var image = {
				url: '/images/icons/map_icons_sprite_22x32.png',
				size: new window.google.maps.Size(22, 32),
				anchor: new window.google.maps.Point(10, 32)
			};

			if (locationType === 'startLocation') {
				image.origin = new window.google.maps.Point(23, 0);
			} else if (locationType === 'endLocation') {
				image.origin = new window.google.maps.Point(0, 0);
			} else if (locationType === 'breakStartLocation') {
				image.origin = new window.google.maps.Point(45, 0);
			} else if (locationType === 'breakEndLocation') {
				image.origin = new window.google.maps.Point(67, 0);
			} else {
				(true && !(false) && Ember.assert('Location Id is Invalid'));

				return null;
			}
			return image;
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.loadMap(this.get('model'));
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			this.clearMap();
		},
		closeActiveInfoWindow: function closeActiveInfoWindow() {
			var activeInfoWindow = this.get('activeInfoWindow');

			if (!Ember.isNone(activeInfoWindow)) {
				return activeInfoWindow.close();
			} else {
				return false;
			}
		}
	});
});