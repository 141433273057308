define('busy-app/utils/round-time', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.round = round;
	exports.roundDown = roundDown;
	exports.roundUp = roundUp;
	exports.roundTime = roundTime;
	exports.roundOrgTime = roundOrgTime;
	function round(number, interval) {
		var dist = number % interval;
		var low = interval - dist;
		if (low > dist) {
			number -= dist;
		} else {
			number += low;
		}
		return number;
	} /**
    * @module Util
    *
    */
	function roundDown(number, interval) {
		var diff = number % interval;
		return number - diff;
	}

	function roundUp(number, interval) {
		var diff = interval - number % interval;
		return number + diff;
	}

	function roundTime(time, interval) {
		var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'nearest';

		var date = _utils.Time.date(time);
		(true && !(date.isValid()) && Ember.assert('roundTime requires a valid timestamp in seconds', date.isValid()));


		// make sure the interval is an integer value not a float
		interval = parseInt(interval, 10);

		// get the timestamp minutes
		var minutes = date.minute();

		// only round time if the time is not already
		// on a interval
		if (minutes % interval !== 0) {
			if (type === 'nearest') {
				minutes = round(minutes, interval);
			} else if (type === 'down') {
				minutes = roundDown(minutes, interval);
			} else if (type === 'up') {
				minutes = roundUp(minutes, interval);
			}
		}

		// set the new timestamp minutes and zero the
		// seconds so the clock still looks rounded
		// even when clocking in on the interval
		date.minute(minutes).seconds(0);

		// return seconds timestamp
		return date.unix();
	}

	function roundOrgTime(organization, time, _ref) {
		var _ref$isStart = _ref.isStart,
		    isStart = _ref$isStart === undefined ? false : _ref$isStart,
		    _ref$isEnd = _ref.isEnd,
		    isEnd = _ref$isEnd === undefined ? false : _ref$isEnd,
		    _ref$isBreak = _ref.isBreak,
		    isBreak = _ref$isBreak === undefined ? false : _ref$isBreak,
		    _ref$isSwitch = _ref.isSwitch,
		    isSwitch = _ref$isSwitch === undefined ? false : _ref$isSwitch;

		if (organization && Ember.get(organization, 'timeRounding')) {
			var interval = parseInt(Ember.getWithDefault(organization, 'timeRoundingInterval', 1) / 60, 10);
			var roundType = Ember.get(organization, 'timeRoundingType');

			// set default to roundType 10 => nearest
			var type = 'nearest';
			if (roundType === 20) {
				// type 20 => round down
				type = 'down';
			} else if (roundType === 30) {
				// type 30 => round up
				type = 'up';
			} else if (roundType === 40) {
				// type 40 => employee favorable
				if (isBreak || isSwitch) {
					// breaks round to nearest on employee favorable
					type = 'nearest';
				} else if (isStart) {
					// start times are rounded down
					type = 'down';
				} else if (isEnd) {
					// end times are rounded up
					type = 'up';
				} else {
					throw new Error("Employee favorable types require isStart, isEnd, isBreak, or isSwitch to be passed in");
				}
			}

			time = roundTime(time, interval, type);
		}
		return time;
	}
});