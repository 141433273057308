define('busy-app/components/time/time-entry/create-new', ['exports', 'moment', '@busy-web/utils', 'busy-app/mixins/safety-signoff', 'busy-app/utils/round-time'], function (exports, _moment, _utils, _safetySignoff, _roundTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_safetySignoff.default, {
    analytics: Ember.inject.service('analytics'),
    auth: Ember.inject.service('auth'),
    store: Ember.inject.service('store'),
    features: Ember.inject.service('features'),

    /**
     * @public
     * @property classNames
     * @type {array}
     * @default ['v-time-card-dialog-new-time-entry']
     */
    classNames: ['v-time-card-dialog-new-time-entry'],

    /**
     * @public
     * @property startDateTime
     * @default null
     */
    startDateTime: null,

    /**
     * @public
     * @property oldStartDate
     * @default null
     */
    oldStartDate: null,

    /**
     * @public
     * @property endDateTime
     * @default null
     */
    endDateTime: null,

    /**
     * @public
     * @property date
     * @default null
     */
    date: null,

    /**
     * @public
     * @property maxdate
     * @default ''
     */
    maxdate: null,

    /**
     * @public
     * @property totalTime
     * @type {number}
     * @default 0
     */
    totalTime: 0,

    /**
     * @public
     * @property totalHours
     * @type {number}
     * @default 0
     */
    totalHours: 0,

    /**
     * @public
     * @property totalMinutes
     * @type {number}
     * @default 0
     */
    totalMinutes: 0,

    /**
     * @public
     * @property timeEntry
     * @type {Models.TimeEntry}
     * @default null
     */
    timeEntry: null,

    /**
     * @public
     * @property member
     * @type {Models.Member}
     * @default null
     */
    member: null,
    memberEntries: null,

    /**
     * @public
     * @property memberLock
     * @type {timestamp}
     * @default null
     */
    memberLock: null,

    costCodesAutoOpen: false,
    projectsAutoOpen: false,
    setEndTimeToHour: false,
    isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
    disableCostCodeSelection: Ember.computed('timeEntry.project.id', 'isLinkedCostCodesEnabled', function () {
      return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'timeEntry.project.id'));
    }),

    /**
     * @private
     * @method init
     * @constructor
     */
    init: function init() {
      var _this = this;

      this._super();

      // open the dialog to this date or default to now
      var now = _utils.Time.timestamp();
      var endOfDay = _utils.Time.date().endOf('day').unix();
      var date = Ember.get(this, 'date');

      if (Ember.isNone(date) || date > endOfDay) {
        date = now;
      }

      // sets maximum date that the user can select
      // mindate is set by member.lockDate in the template
      this.set('maxdate', endOfDay);

      // sets the placeholder for start and end time input fields
      var endTime = _utils.Time.date(date).hours(16).minutes(30).seconds(0).unix();

      if (this.get('auth.organization.defaultEndTime')) {
        endTime = _utils.Time.date(date).hours(this.get('auth.organization.endTimeHours')).minutes(this.get('auth.organization.endTimeMinutes')).seconds(0).unix();
      }

      var startTime = _utils.Time.date(date).hours(7).minutes(30).seconds(0).unix();

      if (this.get('auth.organization.defaultStartTime')) {
        startTime = _utils.Time.date(date).hours(this.get('auth.organization.startTimeHours')).minutes(this.get('auth.organization.startTimeMinutes')).seconds(0).unix();
      }

      // round the initial times if that feature is enabled
      if (this.get('auth.organization.timeRounding')) {
        startTime = this.roundTimestamp(startTime, 'start');
        endTime = this.roundTimestamp(endTime, 'end');
      }

      this.setProperties({
        endDateTime: endTime,
        oldStartDate: _utils.Time.date(startTime).date(),
        startDateTime: startTime
      });

      this.setNewStartTime();

      if (this.get('features.safetySignOff')) {
        if (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy')) {
          this.set('closeOnSave', true);
        }
      }

      // load lock date information
      this.set('isLoading', true);
      this.loadLockDate().finally(function () {
        _this.set('isLoading', false);

        _this._setupEntry();
        _this.adjustEndTime();
        _this.totalAdjusted();
        _this.totalTimeString();
        _this.setTimeRoundingInterval();
      });
    },


    closeOnSave: false,

    setNewStartTime: Ember.observer('member', function () {
      var _this2 = this;

      var now = _utils.Time.timestamp();
      var date = Ember.get(this, 'date');
      if (Ember.isNone(date)) {
        date = now;
      }
      var endOfDay = _utils.Time.date(date).endOf('day').unix();

      if (Ember.isPresent(this.get('member'))) {
        this.get('store').query('time-entry', { member_id: this.get('member.id') }).then(function (entries) {
          if (entries.get('length') >= 1) {
            var currentDayEntries = entries.filter(function (entry) {
              if (_utils.Time.date(entry.get('endTime')).endOf('day').unix() === endOfDay) {
                return entry;
              }
            });
            var latestTimeEntryForDay = currentDayEntries.sortBy('endTime').reverse()[0];
            if (Ember.isPresent(latestTimeEntryForDay)) {
              var latestEndTime = latestTimeEntryForDay.get('endTime');

              if (_utils.Time.date(latestEndTime).endOf('day').unix() === endOfDay) {
                var startTime = latestEndTime;
                _this2.setProperties({
                  oldStartDate: _this2.get('startDateTime'),
                  startDateTime: startTime,
                  setEndTimeToHour: true
                });
              }
            }
          }
        });
      }
    }),

    roundTimestamp: function roundTimestamp(timestamp, type) {
      return (0, _roundTime.roundOrgTime)(this.get('auth.organization'), timestamp, { isStart: type === 'start', isEnd: type === 'end' });
    },
    loadLockDate: function loadLockDate() {
      var _this3 = this;

      if (this.get('features.locks')) {
        if (!Ember.isEmpty(this.get('memberEntries'))) {
          // map all members by a resolved member lock model get
          return Ember.RSVP.all(this.get('memberEntries').map(function (i) {
            return Ember.RSVP.resolve(i.get('memberLock').then(function () {
              return i;
            }));
          })).then(function (data) {
            // reduce the lock date to a a single date that is the greatest value.
            var lockDate = data.reduce(function (a, b) {
              return a < b.get('lockDate') ? b.get('lockDate') : a;
            }, 0);

            // set the lock date
            _this3.set('memberLock', lockDate);
          });
        } else {
          // resolve the member lock model
          return Ember.RSVP.resolve(this.get('member.memberLock')).then(function (memberLock) {
            // set the loack date
            _this3.set('memberLock', memberLock);
          });
        }
      } else {
        // feature flag is off so resolve the nothing
        return Ember.RSVP.resolve();
      }
    },


    shouldShowEquipment: Ember.computed('member', 'memberEntries.[]', function () {
      return !Ember.isNone(this.get('member')) || !Ember.isEmpty(this.get('memberEntries')) && this.get('memberEntries.length') === 1;
    }),

    /**
     * @method adjustEndTime
     */
    adjustEndTime: Ember.observer('startDateTime', function () {
      var _this4 = this;

      if (!Ember.isNone(this.get('startDateTime'))) {
        var oldStart = this.get('oldStartDate');
        var start = this.get('startDateTime');
        var breakTime = this.get('timeEntry.breakTime');
        var end = this.get('endDateTime');

        if (oldStart !== _utils.Time.date(start).date()) {
          var totalTime = this.get('totalTime');
          var curDuration = totalTime + breakTime;

          end = _utils.Time.date(start).add(curDuration, 'seconds').unix();

          // adjust breaks with the new startTime
          if (this.get('timeEntry.timeEntryBreaks')) {
            this.get('timeEntry.timeEntryBreaks').forEach(function (item) {
              var moveDuration = end - _this4.get('timeEntry.endTime');
              var bStart = _utils.Time.date(item.get('startTime')).add(moveDuration, 'seconds').unix();
              var bEnd = _utils.Time.date(item.get('endTime')).add(moveDuration, 'seconds').unix();
              item.set('startTime', bStart);
              item.set('endTime', bEnd);
            });
          }

          if (this.get('setEndTimeToHour')) {
            end = _utils.Time.date(start).add(1, 'hours').unix();
          }

          this.set('endDateTime', end);
          this.set('timeEntry.endTime', end);
        } else {
          var seconds = end - start - breakTime;
          this.set('totalTime', seconds);
        }

        this.set('oldStartDate', _utils.Time.date(this.get('startDateTime')).date());
        this.set('timeEntry.startTime', start);
      }
    }),

    /**
     * @method totalAdjusted
     */
    totalAdjusted: Ember.observer('totalTime', function () {
      if (!Ember.isNone(this.get('totalTime'))) {
        var start = this.get('startDateTime');
        var total = this.get('totalTime');
        var breakTime = this.get('timeEntry.breakTime');

        var totalTime = total + breakTime;

        var end = _utils.Time.date(start).add(totalTime, 'seconds').unix();
        this.set('endDateTime', end);
        this.set('timeEntry.endTime', end);
      }
    }),

    /**
     * function that calculates the amount of hours and minutes
     * from clock-in to clock-out input boxes.
     *
     * @public
     * @method totalTimeString
     */
    totalTimeString: Ember.observer('endDateTime', 'timeEntry.breakTime', function () {
      //variables for setting up seconds, minutes, and hours
      if (!Ember.isNone(this.get('endDateTime')) && !Ember.isNone(this.get('startDateTime'))) {
        var seconds = this.get('endDateTime') - this.get('startDateTime') - this.get('timeEntry.breakTime');

        this.set('timeEntry.endTime', this.get('endDateTime'));
        this.set('totalTime', seconds);
      } else {
        this.set('totalTime', 0);
      }
    }),

    /**
     * @method setTimeRoundingInterval
     */
    setTimeRoundingInterval: function setTimeRoundingInterval() {
      var interval = 1;

      if (this.get('auth.organization.timeRounding')) {
        interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
      }

      this.set('timeRoundingInterval', interval);
    },


    /**
     * This is a save functions for a time entry
     *
     * @private
     * @method _onSave
     * @param {object} dialog this is the dialog
     * @return {EmberPromise}
     */
    _setupEntry: function _setupEntry() {
      var startTime = this.get('startDateTime');
      var endTime = this.get('endDateTime');

      // Create a time entry
      var params = { startTime: startTime, endTime: endTime };
      var entry = this.get('store').createRecord('time-entry', params);

      // set project id and cost code id
      entry.set('projectId', this.get('timeEntry.projectId'));
      entry.set('costCodeId', this.get('timeEntry.costCodeId'));
      entry.set('equipmentId', this.get('timeEntry.equipmentId'));

      this.set('timeEntry', entry);
    },
    isDateLocked: function isDateLocked(compareDate) {
      var lockDate = this.get('memberLock.effectiveDate');
      if (Ember.isNone(lockDate)) {
        return false;
      } else {
        compareDate = _moment.default.isMoment(compareDate) ? compareDate.unix() : compareDate;
        return compareDate <= lockDate;
      }
    },


    /**
     * Is `features.locks` enabled and the startDateTime is before the member's lock date
     *
     * @public
     * @property isSelectedStartTimeLocked
     * @type {boolean}
     * @default false
     */
    isSelectedStartTimeLocked: Ember.computed('features.locks', 'startDateTime', 'memberLock.effectiveDate', function () {
      return this.get('features.locks') ? this.isDateLocked(_utils.Time.date(this.get('startDateTime'))) : false;
    }),

    /**
     * Does the form contain an error
     *
     * @public
     * @property isFormInvalid
     * @type {boolean}
     * @default false
     */
    isFormInvalid: Ember.computed.or('isSelectedStartTimeLocked'),

    saveTimeEntry: function saveTimeEntry(member, forceSave) {
      var _this5 = this;

      var isGroup = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      (true && !(!Ember.isNone(member) && !Ember.isEmpty(member.id)) && Ember.assert("member model was not found", !Ember.isNone(member) && !Ember.isEmpty(member.id)));


      var timeEntry = this.get('timeEntry');
      var startTime = this.get('startDateTime');
      var endTime = this.get('endDateTime');

      var entry = this.get('store').createRecord('time-entry', {
        id: _utils.UUID.generate(),
        actionType: 50,
        memberId: member.id,
        startTime: startTime,
        endTime: endTime,
        projectId: timeEntry.get('projectId'),
        costCodeId: timeEntry.get('costCodeId'),
        equipmentId: timeEntry.get('equipmentId'),
        description: timeEntry.get('description')
      });

      timeEntry.get('timeEntryBreaks').forEach(function (item) {
        entry.createBreak(item.get('startTime'), item.get('endTime'));
      });

      // validate the entry before showing the safety signoff dialogs
      entry.populate();
      return entry.validate(forceSave === true ? false : true).then(function () {
        return _this5.verifySafetyAndSave(isGroup ? null : member, 'showSafetyDialog', 'showSignOffRequiredDialog', function () {
          return entry.save();
        }).then(function (data) {
          return data;
        });
      });
    },


    actions: {
      /**
       * @public
       * @event projectSelected
       * @param item
       */
      projectSelected: function projectSelected(item) {
        // Logger.info(this, 'projectSelected', item, get(item, 'title'));

        this.set('timeEntry.project', item);
        this.set('timeEntry.projectId', !Ember.isNone(item) ? item.id : null);

        if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
          Ember.set(this, 'timeEntry.costCode', null);
          Ember.set(this, 'timeEntry.costCodeId', null);
        }
      },


      /**
       * @public
       * @event costCodeSelected
       * @param item
       */
      costCodeSelected: function costCodeSelected(item) {
        // Logger.info(this, 'costCodeSelected', item, get(item, 'costCode'));

        this.set('timeEntry.costCode', item);
        this.set('timeEntry.costCodeId', !Ember.isNone(item) ? item.id : null);
      },
      equipmentSelected: function equipmentSelected(item) {
        this.set('timeEntry.equipment', item);
        this.set('timeEntry.equipmentId', !Ember.isNone(item) ? item.id : null);
      },


      /**
       * closes dialog if clicked on cancel button or clicked in background window
       *
        * @public
       * @event costCodeSelected
       */
      close: function close() {
        this.sendAction('onClose');
      },


      /**
       * @public
       * @event timecardSave
       * @param dialog
       * @async
       * @returns {EmberPromise}
       */
      timecardSave: function timecardSave(dialog) {
        var _this6 = this;

        var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.get('isFormInvalid')) {
          this.get('analytics').addEvent(84);
          return Ember.RSVP.reject((0, _utils.loc)('Errors must be fixed before saving.'));
        } else {
          var promise = void 0;

          // group entry create if memberEntries exists
          var isGroup = true;
          if (!Ember.isEmpty(this.get('memberEntries'))) {
            forceSave = true;
            if (this.get('memberEntries.length') === 1 && this.get('memberEntries').isAny('id', this.get('auth.member.id'))) {
              isGroup = false;
              forceSave = false;
            }
            // override the validate, send false to forces safety signoff check, and pass isGroup true.
            promise = Ember.RSVP.all(this.get('memberEntries').map(function (member) {
              return _this6.saveTimeEntry(member, forceSave, isGroup);
            }));
          } else {
            promise = this.saveTimeEntry(this.get('member'), forceSave);
          }

          return promise.then(function (model) {
            if (typeof model !== 'string') {
              _this6._setupEntry();
              _this6.sendAction('onSave');
            }
            return model;
          });
        }
      },
      closeSafetyDialog: function closeSafetyDialog() {
        this.cancelSafetySignoff();
        this.set('showSafetyDialog', false);
      },
      closeSafetyRequiredDialog: function closeSafetyRequiredDialog() {
        this.saveSafetySignoff();
        this.set('showSignOffRequiredDialog', false);
      },
      safetyDialogSaveEntry: function safetyDialogSaveEntry() {
        this.saveSafetySignoff();
        this.set('showSafetyDialog', false);
      },
      projectMenuClosed: function projectMenuClosed(didSelectValue /*, selectedItem, openedBy*/) {
        var _this7 = this;

        if (Ember.get(this, 'isLinkedCostCodesEnabled') && didSelectValue) {
          Ember.run.next(this, function () {
            Ember.setProperties(_this7, {
              costCodesAutoOpen: Date.now()
            });
          });
        }
      },
      costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
        var _this8 = this;

        // Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

        // if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
        if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
          Ember.run.next(this, function () {
            Ember.setProperties(_this8, {
              costCode: null,
              projectsAutoOpen: Date.now()
            });
          });
        }
      }
    }
  });
});