define('busy-app/models/cost-code', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		title: _emberData.default.attr('string'),
		costCode: _emberData.default.attr('string'),
		unitTitle: _emberData.default.attr('string'),
		costCodeGroupId: _emberData.default.attr('string'),
		insuranceGroupId: _emberData.default.attr('string'),
		generalLiabilityId: _emberData.default.attr('string'),
		workersCompId: _emberData.default.attr('string'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		archivedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),

		workersComp: _emberData.default.belongsTo('workers-comp'),
		generalLiability: _emberData.default.belongsTo('general-liability'),
		costCodeGroup: _emberData.default.belongsTo('cost-code-group'),
		projectCostCodeLinks: _emberData.default.hasMany('project-cost-code-link'),

		costCodeLabel: Ember.computed('costCode', 'titleFormatted', function () {
			return this.get('costCode') + '&nbsp;&nbsp;' + this.get('titleFormatted');
		}),

		titleFormatted: Ember.computed('title', function () {
			return '<i>' + (this.get('title') || '') + '</i>';
		}),

		getChildren: function getChildren() {
			if (!Ember.isNone(this.get('workersCompId'))) {
				this._getWorkersComp();
			}

			if (!Ember.isNone(this.get('generalLiabilityId'))) {
				this._getGeneralLiability();
			}
			return this;
		},
		_getWorkersComp: function _getWorkersComp() {
			var _this = this;

			(true && !(!Ember.isNone(this.get('workersCompId'))) && Ember.assert('workersCompId must be set to a valid workers-comp model id before calling getWorkersComp', !Ember.isNone(this.get('workersCompId'))));


			return this.store.findRecord('workers-comp', this.get('workersCompId')).then(function (model) {
				_this.set('workersComp', model);
				return model;
			});
		},
		_getGeneralLiability: function _getGeneralLiability() {
			var _this2 = this;

			(true && !(!Ember.isNone(this.get('generalLiabilityId'))) && Ember.assert('generalLiabilityId must be set to a valid general-liability model id before calling getGeneralLiability', !Ember.isNone(this.get('generalLiabilityId'))));


			return this.store.findRecord('general-liability', this.get('generalLiabilityId')).then(function (model) {
				_this2.set('generalLiability', model);
				return model;
			});
		}
	});
});