define('busy-app/components/mod-util/bound-model-range', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-bound-model-range'],

		start: null,
		end: null,
		comparator: null,
		model: null,

		boundModel: Ember.computed('start', 'end', 'model', function () {
			var start = Ember.get(this, 'start');
			var end = Ember.get(this, 'end');
			var model = Ember.get(this, 'model');
			if (!Ember.isNone(model)) {
				var comparator = Ember.get(this, 'comparator');

				(true && !(typeof comparator === 'function') && Ember.assert('comparator must be set to a function (model, startBound, endBound) => boolean', typeof comparator === 'function'));


				return comparator(model, start, end);
			}
			return model;
		})
	});
});