define('busy-app/components/company/time-keeping', ['exports', '@busy-web/utils', 'moment'], function (exports, _utils, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /***/
  var kMinDisclaimerLength = 1;
  var kMaxDisclaimerLength = 256;

  /**
   * `Components/Company/TimeKeeping`
   *
   * @class TimeKeeping
   * @namespace Components.Company
   * @extends Component
   */
  exports.default = Ember.Component.extend({
    classNames: ['c-company-time-keeping'],

    features: Ember.inject.service('features'),
    store: Ember.inject.service('store'),
    payPeriodService: Ember.inject.service('payPeriod'),
    auth: Ember.inject.service('auth'),
    access: Ember.inject.service('access'),
    router: Ember.inject.service('router'),

    // database content set to these variables in init
    organization: null,
    payPeriod: null,
    payPeriodChange: null,

    // in the upcoming pay period changes, these are the values - set in init
    payPeriodEffectiveDate: '',
    payPeriodEffectiveType: '',

    // values used when saving a new pay period
    newPayPeriodType: null,
    newDayOne: null,
    newDayTwo: null,
    payPeriodChangeDate: null,

    // value change from int to string in current pay period
    periodType: '',

    // all of the pages dialogs
    showGeneralDialog: false,
    overtimeDialog: false,
    overtimePreviewDialog: false,
    payPeriodDialog: false,
    customOptions: false,
    customOptionsDialog: false,
    showApprovalDialog: false,
    showWarningCostCodesRequired: false, // enabling both requireCostCode and useProjectCostCodeScoping can cause login issues
    showWarningCostCodesRequiredTrigger: null, // remember the property change that triggers the warning dialog
    showSignInDialog: false,

    disabledRequireProjectControl: Ember.computed.and('features.projectCostCodes', 'organization.{requireCostCode,useProjectCostCodeScoping}'),

    // values shown on the time keeping homepage
    overtimeValue: '',
    payPeriodValue: '',

    currentOvertimePeriod: null,
    scheduledOvertimePeriod: null,

    // allows them to add a new change if they dont already have a scheduled upcoming change
    overtimeClickDisabled: false,
    payPeriodClickDisabled: false,

    // value called on init for the companys daily time flag
    dailyTimeFlagValue: null,

    // values for select menues rendered in the general settings
    timeFlagOptions: '',
    overtimeRules: '',

    // min and max values for the timepicker
    mindate: '',
    maxdate: '',

    // values for the pay period dialog
    weeklySelected: false,
    biWeeklySelected: false,
    semiMonthlySelected: false,
    monthlySelected: false,

    // pay period values rendered for select menues in pay period dialog
    payPeriodTypeMenu: '',
    payPeriodWeekday: null,
    payPeriodDay: '',

    //custom overtime dialog
    seventhDayOvertimeList: null,
    saturdayOvertimeList: null,
    sundayOvertimeList: null,

    customOvertime: null,
    saveLoadData: null,
    booleanList: null,
    setLists: null,
    setOvertimeList: null,

    //change to scheduled here and all cases where used
    customOvertimeDate: null,

    isLoading: false,
    loadingMessage: (0, _utils.loc)('Loading...'),

    cWeeklyOvertime: true,
    cDailyOvertime: true,
    cDailyDoubletime: true,
    cSeventhDayOvertime: true,
    cSeventhDayDoubletime: true,
    cSaturdayMultiplier: true,
    cSundayMultiplier: true,

    enableDigitalSignatures: null,
    maxDisclaimerLength: kMaxDisclaimerLength,
    payPeriodDates: null,

    defaultStartTime: null,
    defaultEndTime: null,
    safetySelectedArray: [],
    accuracySelectedArray: [],
    breakSelectedArray: [],

    currentRoundingInterval: Ember.computed('organization.timeRoundingInterval', function () {
      return this.get('organization.timeRoundingInterval') / 60 + (0, _utils.loc)(' Minutes');
    }),

    currentRoundingType: Ember.computed('organization.timeRoundingType', function () {
      var type = this.get('organization.timeRoundingType');
      var readableType = '';

      if (type === 10) {
        readableType = (0, _utils.loc)('Nearest');
      } else if (type === 20) {
        readableType = (0, _utils.loc)('Round Down');
      } else if (type === 30) {
        readableType = (0, _utils.loc)('Round Up');
      } else {
        readableType = (0, _utils.loc)('Employee Favorable');
      }
      return readableType;
    }),

    currentMinimumAppVersion: Ember.computed('organization.minimumAppVersion', function () {
      var version = this.get('organization.minimumAppVersion');
      if (version === '' || !version) {
        return 'None';
      } else {
        return version;
      }
    }),

    californiaBreaksDisabled: Ember.computed('organization.breakPolicy', function () {

      return !this.get('organization.breakPolicy');
    }),

    breakPolicyObserver: Ember.observer('organization.breakPolicy', function () {
      this.set('organization.breakPolicyCalifornia', this.get('organization.breakPolicy'));
    }),

    employeeExplanation: Ember.computed('', function () {
      return (0, _utils.loc)('Customize whether employee time should be rounded up, down to the nearest interval, or always favor the employee. When the "Employee Favorable" type is set, the time will round down on clock in, round up on clock out and round breaks to the nearest time interval.');
    }),

    // some options under Time Card Approval are only available at the time the setting is enabled
    // follow up visists will have
    enableDigitalSignaturesThisSession: false,

    init: function init() {
      this._super();

      this.set('payPeriodDates', []);
      this.set('mindate', _utils.Time.date().add(5, 'days').startOf('day').unix());
      this.set('maxdate', _utils.Time.date().add(6, 'months').startOf('day').unix());
      this.set('payPeriodChangeDate', _utils.Time.date().add(5, 'days').startOf('day').unix());
      this.set('overtimeChangeDate', _utils.Time.date().add(5, 'days').startOf('day').unix());
      this.getClockInQuestions();

      this.set('timeFlagOptions', [Ember.Object.create({ key: '0', value: (0, _utils.loc)('Off') }), Ember.Object.create({ key: '1', value: (0, _utils.loc)('1 hour') }), Ember.Object.create({ key: '2', value: (0, _utils.loc)('2 hours') }), Ember.Object.create({ key: '3', value: (0, _utils.loc)('3 hours') }), Ember.Object.create({ key: '4', value: (0, _utils.loc)('4 hours') }), Ember.Object.create({ key: '5', value: (0, _utils.loc)('5 hours') }), Ember.Object.create({ key: '6', value: (0, _utils.loc)('6 hours') }), Ember.Object.create({ key: '7', value: (0, _utils.loc)('7 hours') }), Ember.Object.create({ key: '8', value: (0, _utils.loc)('8 hours') }), Ember.Object.create({ key: '9', value: (0, _utils.loc)('9 hours') }), Ember.Object.create({ key: '10', value: (0, _utils.loc)('10 hours') }), Ember.Object.create({ key: '11', value: (0, _utils.loc)('11 hours') }), Ember.Object.create({ key: '12', value: (0, _utils.loc)('12 hours') })]);

      this.set('minimumAppVersionOptions', [Ember.Object.create({ key: '2020.6', value: '2020.6' }), Ember.Object.create({ key: '2020.5', value: '2020.5' }), Ember.Object.create({ key: '2020.4', value: '2020.4' }), Ember.Object.create({ key: '2020.3', value: '2020.3' }), Ember.Object.create({ key: '2020.2', value: '2020.2' }), Ember.Object.create({ key: '2020.1', value: '2020.1' }), Ember.Object.create({ key: '2019.9', value: '2019.9' }), Ember.Object.create({ key: '2019.8', value: '2019.8' }), Ember.Object.create({ key: '2019.7', value: '2019.7' }), Ember.Object.create({ key: '2019.6', value: '2019.6' }), Ember.Object.create({ key: '2019.5', value: '2019.5' }), Ember.Object.create({ key: '5.18', value: '5.18' }), Ember.Object.create({ key: '5.17', value: '5.17' }), Ember.Object.create({ key: '5.16', value: '5.16' }), Ember.Object.create({ key: '5.15', value: '5.15' }), Ember.Object.create({ key: '5.14', value: '5.14' }), Ember.Object.create({ key: '5.13', value: '5.13' }), Ember.Object.create({ key: '5.12', value: '5.12' }), Ember.Object.create({ key: '_-NULL-_', value: 'None' })]);

      this.set('payPeriodTypeMenu', [Ember.Object.create({ key: '10', value: (0, _utils.loc)('Weekly') }), Ember.Object.create({ key: '20', value: (0, _utils.loc)('Biweekly') }), Ember.Object.create({ key: '30', value: (0, _utils.loc)('Semimonthly') }), Ember.Object.create({ key: '40', value: (0, _utils.loc)('Monthly') })]);

      this.set('seventhDayOvertimeList', [Ember.Object.create({ key: true, value: (0, _utils.loc)('Yes') }), Ember.Object.create({ key: false, value: (0, _utils.loc)('No') })]);

      this.set('saturdayOvertimeList', [Ember.Object.create({ key: 1.5, value: '1.5' }), Ember.Object.create({ key: 2, value: '2.0' })]
      // EmberObject.create({key: 2.5, value: '2.5'})
      );

      this.set('sundayOvertimeList', [Ember.Object.create({ key: 1.5, value: '1.5' }), Ember.Object.create({ key: 2, value: '2.0' })]
      // EmberObject.create({key: 2.5, value: '2.5'})
      );

      this.set('payPeriodWeekday', [Ember.Object.create({ key: 0, value: (0, _utils.loc)('Sunday') }), Ember.Object.create({ key: 1, value: (0, _utils.loc)('Monday') }), Ember.Object.create({ key: 2, value: (0, _utils.loc)('Tuesday') }), Ember.Object.create({ key: 3, value: (0, _utils.loc)('Wednesday') }), Ember.Object.create({ key: 4, value: (0, _utils.loc)('Thursday') }), Ember.Object.create({ key: 5, value: (0, _utils.loc)('Friday') }), Ember.Object.create({ key: 6, value: (0, _utils.loc)('Saturday') })]);

      this.set('payPeriodDay', this.generatePeriodDay());
      this.set('payPeriodDayOne', this.generatePeriodDayOne());

      this.set('overtimeRules', [Ember.Object.create({ key: 'standard', value: (0, _utils.loc)('Standard') }), Ember.Object.create({ key: 'california', value: (0, _utils.loc)('California') }), Ember.Object.create({ key: 'custom', value: (0, _utils.loc)('Custom') })]);

      this.setBreakOptions();
      this.getCompanyPayPeriod();
      this.getCompanyOvertime();
      this.setupSafetyDialog();

      if (this.get('features.signatures')) {
        this.initSignatureSettings();
      }

      this.initDefaultEntryTimes();
      this.getCompanyMembers();
      this.getCompanyOvertimeHistory();
    },
    initDefaultEntryTimes: function initDefaultEntryTimes() {
      var convertDuration = function convertDuration(duration) {
        return _utils.Time.date().startOf('day').add(duration, 'seconds').unix();
      };

      this.setProperties({
        defaultStartTime: convertDuration(this.get('auth.organization.defaultStartTime')),
        defaultEndTime: convertDuration(this.get('auth.organization.defaultEndTime')),
        breakMinutes: this.get('auth.organization.breakTimeMinutes'),
        breakHours: this.get('auth.organization.breakTimeHours')
      });
    },
    generatePeriodDay: function generatePeriodDay() {
      return this.generateKeyValueArray(31);
    },
    generatePeriodDayOne: function generatePeriodDayOne() {
      return this.generateKeyValueArray(27);
    },


    /*
      create array of ember objects in pattern:
        [EmberObject.create({ key: '1', value: '1'}), EmberObject.create({ key: '2', value: '2'}), ...]
          1. initialize empty N length array
        2. populate array with string values 1-length
        3. create ember objects
     */
    generateKeyValueArray: function generateKeyValueArray(length) {
      return Array.from({ length: length }).map(function (_, i) {
        return (i + 1).toString();
      }).map(function (value) {
        return Ember.Object.create({ key: value, value: value });
      });
    },


    breakHoursLabel: Ember.computed('breakHours', function () {
      return this.get('breakHours') + ' Hours';
    }),

    breakMinutesLabel: Ember.computed('breakMinutes', function () {
      return this.get('breakMinutes') + ' Minutes';
    }),

    timeRoundingInterval: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
      var interval = 1;

      if (this.get('auth.organization.timeRounding')) {
        interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
      }

      return interval;
    }),

    // needs to be a computed in order to react to the organization's timeRoundingInterval settings changing
    breakMinutesOptions: Ember.computed('timeRoundingInterval', function () {
      var breakMinutesOptions = [];

      for (var breakMinute = 0; breakMinute < 60; breakMinute += this.get('timeRoundingInterval')) {
        breakMinutesOptions.push(Ember.Object.create({
          key: breakMinute.toString(),
          value: breakMinute + ' ' + (0, _utils.loc)('Minutes')
        }));
      }

      return breakMinutesOptions;
    }),

    setBreakOptions: function setBreakOptions() {
      var breakHoursOptions = Array.from({ length: 25 }).map(function (_, hour) {
        return Ember.Object.create({
          key: hour.toString(),
          value: hour + ' ' + (0, _utils.loc)('Hours')
        });
      });

      this.setProperties({
        breakHoursOptions: breakHoursOptions
      });
    },
    setupSafetyDialog: function setupSafetyDialog() {
      // set safety message
      if (!Ember.isEmpty(this.get('organization.safetySignatureMessage'))) {
        this.set('safetyMessage', this.get('organization.safetySignatureMessage'));
      } else {
        this.set('safetyMessage', (0, _utils.loc)('If you were injured, please contact your supervisor. If your injury is serious, contact emergency services immediately.'));
      }

      // set accuracy message
      if (!Ember.isEmpty(this.get('organization.timeAccuracyMessage'))) {
        this.set('accuracyMessage', this.get('organization.timeAccuracyMessage'));
      } else {
        this.set('accuracyMessage', (0, _utils.loc)('If your time is not correct, please notify your supervisor as soon as possible to resolve the issue.'));
      }

      // set break message
      if (!Ember.isEmpty(this.get('organization.breakPolicyMessage'))) {
        this.set('breakPolicyMessage', this.get('organization.breakPolicyMessage'));
      } else {
        this.set('breakPolicyMessage', (0, _utils.loc)('You certify that you either took the required breaks or voluntarily gave them up according to company policy.'));
      }

      // set CA break message
      this.set('breakPolicyMessageCali', (0, _utils.loc)('You certify that you took the required breaks or they were waived by mutual consent as outlined in the California break laws.'));
    },
    getClockInQuestions: function getClockInQuestions() {
      var _this = this;

      this.get('store').findAll('busy-sign-in-question').then(function (results) {
        _this.set('questions', results);
      });
    },


    /**
     * gets the companies pay period settings and sets the page up accordingly
     *
     */
    getCompanyPayPeriod: function getCompanyPayPeriod() {
      var _this2 = this;

      return this.get('store').query('organization-pay-period', { _desc: ['start_date'] }).then(function (model) {
        if (!_this2.get('isDestroyed')) {
          var currentModel = model.objectAt(0);
          var scheduledModel = null;

          if (!Ember.isNone(model.objectAt(1)) && currentModel.get('startDate') > _utils.Time.timestamp()) {
            currentModel = model.objectAt(1);
            scheduledModel = model.objectAt(0);
          }

          _this2.set('currentPayPeriod', currentModel);
          _this2.set('scheduledPayPeriod', scheduledModel);
        }

        return model;
      });
    },


    // gets the companies overtime settings and sets up the page accordingly
    getCompanyOvertime: function getCompanyOvertime() {
      var _this3 = this;

      return this.get('store').query('organization-overtime-period', { _desc: ['start_time'], page_size: 2, deleted_on: '_-NULL-_' }).then(function (model) {
        if (!_this3.get('isDestroyed')) {
          var currentModel = model.objectAt(0);
          var scheduledModel = null;
          if (!Ember.isNone(model.objectAt(1)) && currentModel.get('startTime') > _utils.Time.timestamp()) {
            currentModel = model.objectAt(1);
            scheduledModel = model.objectAt(0);
          }
          _this3.set('currentOvertimePeriod', currentModel);
          _this3.set('scheduledOvertimePeriod', scheduledModel);
        }
        return model;
      });
    },
    getCompanyOvertimeHistory: function getCompanyOvertimeHistory() {
      var _this4 = this;

      this.get('store').findAll('organization-overtime-period', {}).then(function (results) {
        _this4.set('overtimeHistory', results.sortBy('startTime').reverse());
      });
    },
    setOverTimeObject: function setOverTimeObject(type) {
      if (type === 'custom') {
        this.set('customOptions', true);
        this.set('newOvertimePeriod.weeklyOvertime', 40);
        this.set('newOvertimePeriod.dailyOvertime', 8);
        this.set('newOvertimePeriod.dailyDoubletime', 12);
        this.set('newOvertimePeriod.seventhDayOvertime', true);
        this.set('newOvertimePeriod.seventhDayDoubletime', 10);
        this.set('newOvertimePeriod.saturdayMultiplier', 1.5);
        this.set('newOvertimePeriod.sundayMultiplier', 1.5);
      } else if (type === 'california') {
        this.set('customOptions', false);
        this.set('newOvertimePeriod.weeklyOvertime', 40);
        this.set('newOvertimePeriod.dailyOvertime', 8);
        this.set('newOvertimePeriod.dailyDoubletime', 12);
        this.set('newOvertimePeriod.seventhDayOvertime', true);
        this.set('newOvertimePeriod.seventhDayDoubletime', 8);
        this.set('newOvertimePeriod.saturdayMultiplier', null);
        this.set('newOvertimePeriod.sundayMultiplier', null);
      } else if (type === 'standard') {
        this.set('customOptions', false);
        this.set('newOvertimePeriod.weeklyOvertime', 40);
        this.set('newOvertimePeriod.dailyOvertime', null);
        this.set('newOvertimePeriod.dailyDoubletime', null);
        this.set('newOvertimePeriod.seventhDayOvertime', false);
        this.set('newOvertimePeriod.seventhDayDoubletime', null);
        this.set('newOvertimePeriod.saturdayMultiplier', null);
        this.set('newOvertimePeriod.sundayMultiplier', null);
      }
    },


    // saves the overtime period scheduled change
    addOvertimePeriod: function addOvertimePeriod() {
      var _this5 = this;

      var current = this.get('currentOvertimePeriod');

      if (!Ember.isNone('overtimeChangeDate')) {
        this.get('store').createRecord('organization-overtime-period', {
          organizationId: this.get('organization.id'),
          startTime: _utils.Time.date(this.get('overtimeChangeDate')).startOf('day').unix(),
          weeklyOvertime: current.get('weeklyOvertime'),
          dailyOvertime: current.get('dailyOvertime'),
          dailyDoubletime: current.get('dailyDoubletime'),
          seventhDayOvertime: current.get('seventhDayOvertime'),
          seventhDayDoubletime: current.get('seventhDayDoubletime'),
          saturdayMultiplier: current.get('saturdayMultiplier'),
          sundayMultiplier: current.get('sundayMultiplier')
        }).save().then(function (ov) {
          _this5.set('scheduledOvertimePeriod', ov);
          return ov;
        });
      }
      return Ember.RSVP.reject((0, _utils.loc)("Change date must be set"));
    },


    // save the changes to the organizations general settings
    saveOrganization: function saveOrganization() {
      var _getProperties = this.getProperties('organization', 'safetyMessage', 'defaultStartTime', 'defaultEndTime', 'breakHours', 'breakMinutes', 'breakPolicyMessage'),
          organization = _getProperties.organization,
          safetyMessage = _getProperties.safetyMessage,
          defaultStartTime = _getProperties.defaultStartTime,
          defaultEndTime = _getProperties.defaultEndTime,
          breakHours = _getProperties.breakHours,
          breakMinutes = _getProperties.breakMinutes,
          breakPolicyMessage = _getProperties.breakPolicyMessage;

      var timeAccuracyMessage = this.get('accuracyMessage');
      if (!Ember.isNone(this.get('newAccuracyMessage'))) {
        timeAccuracyMessage = this.get('newAccuracyMessage');
      }

      // if Cost Codes are disabled, disable the dependant properties too
      if (!this.get('organization.trackCostCode')) {
        this.get('organization').setProperties({
          requireCostCode: false,
          useProjectCostCodeScoping: false
        });
      }

      var getDuration = function getDuration(date) {
        return _moment.default.duration({
          hours: date.hours(),
          minutes: date.minutes(),
          seconds: 0
        }).asSeconds();
      };

      defaultStartTime = getDuration(_utils.Time.date(defaultStartTime));
      defaultEndTime = getDuration(_utils.Time.date(defaultEndTime));
      var defaultBreakDuration = _moment.default.duration(breakHours + ':' + breakMinutes + ':00').asSeconds();

      if (defaultStartTime === _moment.default.duration({ hours: 7 }).asSeconds()) {
        defaultStartTime = '_-NULL-_';
      }

      if (defaultEndTime === _moment.default.duration({ hours: 16 }).asSeconds()) {
        defaultEndTime = '_-NULL-_';
      }

      if (defaultBreakDuration === _moment.default.duration({ minutes: 30 }).asSeconds()) {
        defaultBreakDuration = '_-NULL-_';
      }

      if (defaultEndTime < defaultStartTime) {
        return Ember.RSVP.reject((0, _utils.loc)('Default End Time must be after the Default Start Time'));
      }

      organization.setProperties({
        safetySignatureMessage: safetyMessage,
        timeAccuracyMessage: timeAccuracyMessage,
        defaultStartTime: defaultStartTime,
        defaultEndTime: defaultEndTime,
        defaultBreakDuration: defaultBreakDuration,
        breakPolicyMessage: breakPolicyMessage
      });

      this.saveNotifcationSettings();

      return organization.save().then(function () {
        location.reload();
      });
    },
    clockStringFormat: function clockStringFormat() {
      var hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var minutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var seconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      var paddedHours = this.zeroPad(hours, 2);
      var paddedMinutes = this.zeroPad(minutes, 2);
      var paddedSeconds = this.zeroPad(seconds, 2);

      return paddedHours + ':' + paddedMinutes + ':' + paddedSeconds;
    },
    zeroPad: function zeroPad(val) {
      var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

      var zeroes = '0'.repeat(length);
      var asInt = parseInt(val, 10);

      return ('' + zeroes + asInt).substr(-length);
    },
    getDayOffset: function getDayOffset(timestamp, dayOfWeek, type) {
      var offset = dayOfWeek;

      if (type === 20) {
        var curDay = _utils.Time.date(timestamp).day();
        offset = (dayOfWeek - curDay + 7) % 7;
      }

      return offset;
    },


    // send the new pay period to the database and save it
    addPayPeriod: function addPayPeriod(payPeriod) {
      var _this6 = this;

      return payPeriod.save().then(function (model) {
        _this6.set('scheduledPayPeriod', model);
        return true;
      });
    },
    saveOvertime: function saveOvertime(overtime) {
      var _this7 = this;

      return overtime.save().then(function (model) {
        _this7.set('scheduledOvertimePeriod', model);
        return model;
      });
    },


    /**
     * delete the scheduled pay period change from the database
     */
    deleteScheduledPayPeriodChangeFunction: function deleteScheduledPayPeriodChangeFunction() {
      var _this8 = this;

      if (!Ember.isNone(this.get('scheduledPayPeriod'))) {
        var model = this.get('scheduledPayPeriod');
        return model.destroyRecord().then(function () {
          _this8.set('scheduledPayPeriod', null);
        });
      }
    },
    deleteScheduledOvertimeChangeFunction: function deleteScheduledOvertimeChangeFunction() {
      var _this9 = this;

      (true && !(!Ember.isNone(this.get('scheduledOvertimePeriod'))) && Ember.assert("You must have a scheduledOvertimePeriod before you can delete it", !Ember.isNone(this.get('scheduledOvertimePeriod'))));

      return this.get('scheduledOvertimePeriod').destroyRecord().then(function (model) {
        _this9.set('scheduledOvertimePeriod', null);
        return model;
      });
    },
    undoChanges: function undoChanges() {
      var organization = this.get('organization');
      if (organization.get('hasDirtyAttributes')) {
        organization.rollbackAttributes();
      }
    },


    seventhDayOvertimeListObserver: Ember.observer('newOvertimePeriod.seventhDayOvertime', function () {
      var isActive = this.get('newOvertimePeriod.seventhDayOvertime');
      var list = this.get('seventhDayOvertimeList');

      list.forEach(function (item) {
        if (item.get('key') === isActive) {
          item.set('_selected', true);
        } else {
          item.set('_selected', false);
        }
      });
    }),

    saturdayOvertimeListObserver: Ember.observer('newOvertimePeriod.saturdayMultiplier', function () {
      var isActive = this.get('newOvertimePeriod.saturdayMultiplier');
      var list = this.get('saturdayOvertimeList');

      list.forEach(function (item) {
        if (item.get('key') === isActive) {
          item.set('_selected', true);
        } else {
          item.set('_selected', false);
        }
      });
    }),

    sundayOvertimeListObserver: Ember.observer('newOvertimePeriod.sundayMultiplier', function () {
      var isActive = this.get('newOvertimePeriod.sundayMultiplier');
      var list = this.get('sundayOvertimeList');

      list.forEach(function (item) {
        if (item.get('key') === isActive) {
          item.set('_selected', true);
        } else {
          item.set('_selected', false);
        }
      });
    }),

    toggleCustomOvertimeOption: Ember.observer('cWeeklyOvertime', 'cDailyOvertime', 'cDailyDoubletime', 'cSeventhDayOvertime', 'cSeventhDayDoubletime', 'cSaturdayMultiplier', 'cSundayMultiplier', function () {
      this.set('newOvertimePeriod.weeklyOvertime', this.get('cWeeklyOvertime') ? this.get('newOvertimePeriod.weeklyOvertime') : null);
      this.set('newOvertimePeriod.dailyOvertime', this.get('cDailyOvertime') ? this.get('newOvertimePeriod.dailyOvertime') : null);
      this.set('newOvertimePeriod.dailyDoubletime', this.get('cDailyDoubletime') ? this.get('newOvertimePeriod.dailyDoubletime') : null);
      this.set('newOvertimePeriod.seventhDayOvertime', this.get('cSeventhDayOvertime') ? this.get('newOvertimePeriod.seventhDayOvertime') : null);
      this.set('newOvertimePeriod.seventhDayDoubletime', this.get('cSeventhDayDoubletime') ? this.get('newOvertimePeriod.seventhDayDoubletime') : null);
      this.set('newOvertimePeriod.saturdayMultiplier', this.get('cSaturdayMultiplier') ? this.get('newOvertimePeriod.saturdayMultiplier') : null);
      this.set('newOvertimePeriod.sundayMultiplier', this.get('cSundayMultiplier') ? this.get('newOvertimePeriod.sundayMultiplier') : null);
    }),

    isDisclaimerTooShort: Ember.computed.lt('organization.disclaimer.length', kMinDisclaimerLength),
    isDisclaimerTooLong: Ember.computed.gt('organization.disclaimer.length', kMaxDisclaimerLength),
    disclaimerHasError: Ember.computed.or('isDisclaimerTooLong', 'isDisclaimerTooShort'),
    disclaimerErrorMessage: Ember.computed('disclaimerHasError', 'isDisclaimerTooShort', 'isDisclaimerTooLong', function () {
      if (!this.get('disclaimerHasError')) {
        return null;
      }

      if (this.get('isDisclaimerTooLong')) {
        return (0, _utils.loc)('Time Card Disclaimer is too long');
      } else if (this.get('isDisclaimerTooShort')) {
        return (0, _utils.loc)('Time Card Disclaimer is required');
      } else {
        return (0, _utils.loc)('Time Card Disclaimer is not valid');
      }
    }),

    enableDigitalSignaturesObserver: Ember.observer('enableDigitalSignatures', function () {
      // when checked, set signatureDate to current pay period
      if (this.get('enableDigitalSignatures')) {
        this.set('enableDigitalSignaturesThisSession', true);
        this.set('organization.signatureDate', this.get('payPeriodDates.firstObject.dates.start'));
      }
      // when unchecked, clear signatureDate
      else {
          this.set('organization.signatureDate', '_-NULL-_');
          this.set('organization.lockOnSelfSignature', false);
        }
    }),

    initSignatureSettings: function initSignatureSettings() {
      this.set('enableDigitalSignaturesThisSession', false);
      this.set('enableDigitalSignatures', this.get('organization.isSignaturesEnabled'));
      this.setPayPeriodDateOptions();
    },
    setPayPeriodDateOptions: function setPayPeriodDateOptions() {
      var now = _utils.Time.date().unix();

      var currentDates = this.get('payPeriodService').getPayPeriod(now);
      var previousDates = this.get('payPeriodService').getPayPeriod(currentDates.get('start') - 1);

      var dateRange = function dateRange(d) {
        return '(' + d.startDate.format('ll') + ' - ' + d.endDate.format('ll') + ')';
      };

      var payPeriodDates = [{
        dates: currentDates,
        label: (0, _utils.loc)('The current pay period') + ' ' + dateRange(currentDates)
      }];

      if (this.get('organization.createdOn') <= previousDates.get('end')) {
        payPeriodDates.push({
          dates: previousDates,
          label: (0, _utils.loc)('The previous pay period') + ' ' + dateRange(previousDates)
        });
      }

      this.set('payPeriodDates', payPeriodDates);
    },
    setCustomOvertime: function setCustomOvertime(overtime) {
      if (!this.get('cWeeklyOvertime')) {
        overtime.set('weeklyOvertime', '_-NULL-_');
      }

      if (!this.get('cDailyOvertime')) {
        overtime.set('dailyOvertime', '_-NULL-_');
      }

      if (!this.get('cDailyDoubletime')) {
        overtime.set('dailyDoubletime', '_-NULL-_');
      }

      if (!this.get('cSeventhDayOvertime')) {
        overtime.set('seventhDayOvertime', false);
      }

      if (!this.get('cSeventhDayDoubletime')) {
        overtime.set('seventhDayDoubletime', '_-NULL-_');
      }

      if (!this.get('cSaturdayMultiplier')) {
        overtime.set('saturdayMultiplier', '_-NULL-_');
      }

      if (!this.get('cSundayMultiplier')) {
        overtime.set('sundayMultiplier', '_-NULL-_');
      }
    },
    getCompanyMembers: function getCompanyMembers() {
      var _this10 = this;

      this.get('store').findAll('member', { organization_id: this.get('organization.id'), archived_on: null }).then(function (results) {
        _this10.set('members', results);
        _this10.getNotificationPreferences();
      });
    },
    getNotificationPreferences: function getNotificationPreferences() {
      var _this11 = this;

      this.get('store').findAll('organizationNotificationPreferences', {}).then(function (results) {
        _this11.set('notificationPrefs', results);
        _this11.setAccuracyPrefs();
        _this11.setSafetyPrefs();
        _this11.setBreakPrefs();
        _this11.setSelectableMembers();
      });
    },
    setAccuracyPrefs: function setAccuracyPrefs() {
      var notificationPrefs = this.get('notificationPrefs');

      var accuracyItems = notificationPrefs.filter(function (item) {

        if (item.get('inaccurateTimeReport') > 0) {
          return item;
        }
      });

      this.setProperties({
        accuracyPrefs: accuracyItems
      });
    },
    setSafetyPrefs: function setSafetyPrefs() {
      var notificationPrefs = this.get('notificationPrefs');

      var safetyItems = notificationPrefs.filter(function (item) {
        if (item.get('injuryReport') > 0) {
          return item;
        }
      });

      this.setProperties({
        safetyPrefs: safetyItems
      });
    },
    setBreakPrefs: function setBreakPrefs() {
      var notificationPrefs = this.get('notificationPrefs');

      var breakItems = notificationPrefs.filter(function (item) {
        if (item.get('breakReport') > 0) {
          return item;
        }
      });

      this.setProperties({
        breakPrefs: breakItems
      });
    },
    setSelectableMembers: function setSelectableMembers() {
      var _this12 = this;

      var members = this.get('members');

      var selectableMembers = members.filter(function (member) {
        if (_this12.get('access').canTakeActionFor('manageTimeEntries', member)) {
          return member;
        }
      });
      this.set('members', selectableMembers.sortBy('firstName'));
      this.setSelectedSafetyMembers();
      this.setSelectedAccuracyMembers();
      this.setSelectedBreakMembers();
    },
    setSelectedSafetyMembers: function setSelectedSafetyMembers() {
      var members = this.get('members').sortBy('firstName');
      var notificationPrefs = this.get('safetyPrefs');
      var selectedMembers = [];

      members.forEach(function (member) {
        if (!Ember.isEmpty(notificationPrefs)) {
          if (notificationPrefs.isAny('memberId', member.get('id'))) {
            selectedMembers.push(member);
            selectedMembers = selectedMembers.sortBy('lastName');
            member.set('selectedSafety', true);
          }
        }
      });

      this.set('safetyLength', selectedMembers.length);
      this.set('members', members.sortBy('firstName'));
    },
    setSelectedAccuracyMembers: function setSelectedAccuracyMembers() {
      var members = this.get('members');
      var notificationPrefs = this.get('accuracyPrefs');
      var selectedMembers = [];

      members.forEach(function (member) {
        if (notificationPrefs.isAny('memberId', member.get('id'))) {
          selectedMembers.push(member);
          selectedMembers = selectedMembers.sortBy('lastName');
          member.set('selectedAccuracy', true);
        }
      });

      this.set('accuracyLength', selectedMembers.length);
      this.set('members', members.sortBy('firstName'));
    },
    setSelectedBreakMembers: function setSelectedBreakMembers() {
      var members = this.get('members');
      var notificationPrefs = this.get('breakPrefs');
      var selectedMembers = [];

      members.forEach(function (member) {
        if (notificationPrefs.isAny('memberId', member.get('id'))) {
          selectedMembers.push(member);
          selectedMembers = selectedMembers.sortBy('lastName');
          member.set('selectedBreaks', true);
        }
      });

      this.set('breaksLength', selectedMembers.length);
      this.set('members', members.sortBy('firstName'));
    },
    saveNotifcationSettings: function saveNotifcationSettings() {
      var _this13 = this;

      var allMembers = this.get('members');
      var notificationPrefs = this.get('notificationPrefs');

      var newMembers = allMembers.filter(function (member) {
        if (!notificationPrefs.isAny('memberId', member.get('id'))) {
          return member;
        }
      });
      var prefsToSave = [];

      allMembers.forEach(function (member) {

        notificationPrefs.forEach(function (pref) {

          if (pref.get('memberId') === member.get('id')) {

            var injuryReport = member.get('selectedSafety');
            var inaccurateTimeReport = member.get('selectedAccuracy');
            var breakReport = member.get('selectedBreaks');

            pref.setProperties({
              inaccurateTimeReport: inaccurateTimeReport ? 1 : 0,
              injuryReport: injuryReport ? 1 : 0,
              breakReport: breakReport ? 1 : 0
            });

            if (!prefsToSave.isAny('memberId', member.get('id')) && pref.get('hasDirtyAttributes')) {
              prefsToSave.push(pref);
            }
          }
        });
      });

      newMembers.forEach(function (member) {
        var inaccurateTimeReport = member.get('selectedAccuracy') ? 1 : 0;
        var injuryReport = member.get('selectedSafety') ? 1 : 0;
        var breakReport = member.get('selectedBreaks') ? 1 : 0;

        var newPref = _this13.get('store').createRecord('organization-notification-preferences', {
          memberId: member.get('id'),
          inaccurateTimeReport: inaccurateTimeReport,
          injuryReport: injuryReport,
          breakReport: breakReport
        });

        if (!prefsToSave.isAny('memberId', member.get('id'))) {
          prefsToSave.push(newPref);
        }
      });

      prefsToSave.forEach(function (pref) {
        pref.save();
      });
    },


    selectedSafetyMembersLength: Ember.computed('members.@each.selectedSafety', function () {
      if (!Ember.isNone(this.get('members'))) {
        var selectedMembers = this.get('members').filter(function (member) {
          if (member.get('selectedSafety')) {
            return member;
          }
        });

        return selectedMembers.length;
      }
    }),

    safetyMembersSelected: Ember.computed('selectedSafetyMembersLength', function () {
      var length = this.get('selectedSafetyMembersLength');
      if (length > 0) {
        return true;
      }
      return false;
    }),

    selectedAccuracyMembersLength: Ember.computed('members.@each.selectedAccuracy', function () {
      if (!Ember.isNone(this.get('members'))) {
        var selectedMembers = this.get('members').filter(function (member) {
          if (member.get('selectedAccuracy')) {
            return member;
          }
        });

        return selectedMembers.length;
      }
    }),

    accuracyMembersSelected: Ember.computed('selectedAccuracyMembersLength', function () {
      var length = this.get('selectedAccuracyMembersLength');
      if (length > 0) {
        return true;
      }
      return false;
    }),

    selectedBreaksMembersLength: Ember.computed('members.@each.selectedBreaks', function () {
      if (!Ember.isNone(this.get('members'))) {
        var selectedMembers = this.get('members').filter(function (member) {
          if (member.get('selectedBreaks')) {
            return member;
          }
        });

        return selectedMembers.length;
      }
    }),

    breaksMembersSelected: Ember.computed('selectedBreaksMembersLength', function () {
      var length = this.get('selectedBreaksMembersLength');
      if (length > 0) {
        return true;
      }
      return false;
    }),

    handleOvertimeError: function handleOvertimeError(err) {
      var errStr = (0, _utils.loc)('Something went wrong, Please try again later.');
      if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') {
        var code = Ember.get(err, 'errors.firstObject.code');

        if (code === 801) {
          errStr = (0, _utils.loc)("You can't save an overtime period for more than 90 days ago");
        } else if (code === 898) {
          errStr = (0, _utils.loc)('You must select a later date!');
        } else if (code === 903) {
          errStr = (0, _utils.loc)('7th Day Overtime needs to be less hours than Daily Doubletime.');
        } else if (code === 902) {
          errStr = (0, _utils.loc)('Daily Overtime needs to be less hours than Daily Doubletime.');
        } else if (code === 904) {
          errStr = (0, _utils.loc)("The provided Saturday Multiplier is not a valid option");
        } else if (code === 905) {
          errStr = (0, _utils.loc)("The provided Sunday Multiplier is not a valid option");
        }
      } else if (typeof err === 'string') {
        errStr = err;
      }

      return Ember.RSVP.reject(errStr);
    },


    actions: {

      // calls the function that will save there general settings
      saveGeneralSettings: function saveGeneralSettings() {
        return this.saveOrganization();
      },
      openGeneralDialog: function openGeneralDialog() {
        this.set('showGeneralDialog', true);
      },
      closeGeneralDialog: function closeGeneralDialog() {
        this.set('showGeneralDialog', false);
        this.undoChanges();
      },


      //open overtime dialog
      currentOvertimeDialog: function currentOvertimeDialog() {
        this.set('overtimeDialog', true);
      },


      //close overtime dialog
      cancelOvertimePeriod: function cancelOvertimePeriod() {
        this.set('overtimeDialog', false);
      },


      // save new overtime period
      saveOvertimePeriod: function saveOvertimePeriod() {
        this.set('overtimeNotificationBox', true);
        this.set('overtimeClickDisabled', true);
        return this.addOvertimePeriod().then(function () {
          return true;
        });
      },


      // handles select menu action, if custom is chosen button is displayed so they may edit there custom overtime rules
      selectOvertimeAction: function selectOvertimeAction(item) {
        this.setOverTimeObject(item.get('key'));
      },


      // deletes the scheduled overtime change and allows them to create a new one
      deleteScheduledOvertimeChange: function deleteScheduledOvertimeChange() {
        this.deleteScheduledOvertimeChangeFunction();
        this.set('overtimeNotificationBox', false);
        this.set('overtimeClickDisabled', false);
      },


      //open pay period dialog
      currentPayPeriodDialog: function currentPayPeriodDialog() {
        this.set('payPeriodDialog', true);
      },


      //close pay period dialog and reset the values
      cancelPayPeriod: function cancelPayPeriod() {
        this.set('payPeriodDialog', false);
      },


      // save new pay period
      savePayPeriod: function savePayPeriod() {
        var _this14 = this;

        this.set('payPeriodNotificationBox', true);
        this.set('payPeriodClickDisabled', true);

        if (!Ember.isNone(this.get('newPayPeriodType')) && !Ember.isNone(this.get('newDayOne'))) {
          var startTime = _utils.Time.date(this.get('payPeriodChangeDate')).startOf('day').unix();
          // var offset = Time.timezone(startTime);

          var payPeriodType = this.get('newPayPeriodType');
          var dayOne = this.getDayOffset(startTime, this.get('newDayOne'), payPeriodType);

          var payPeriod = this.get('store').createRecord('organization-pay-period', {
            organizationId: this.get('organization.id'),
            payPeriodType: payPeriodType,
            dayOne: dayOne,
            dayTwo: this.get('newDayTwo'),
            startDate: startTime
          });

          return this.addPayPeriod(payPeriod).catch(function (err) {
            return _this14.handlePayPeriodError(err);
          });
        } else {
          return new Ember.RSVP.reject('You must select a pay period type and a pay period beginning work day');
        }
      },
      handlePayPeriodError: function handlePayPeriodError(err) {
        var errStr = (0, _utils.loc)('Something went wrong, Please try again later.');
        if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') {
          if (err.errors[0] === 898) {
            errStr = (0, _utils.loc)('You must select a later date!');
          }
        } else if (typeof err === 'string') {
          errStr = err;
        }
        return Ember.RSVP.reject(errStr);
      },


      // deletes the scheduled pay period change and allows them to create a new change
      deleteScheduledPayPeriodChange: function deleteScheduledPayPeriodChange() {
        this.deleteScheduledPayPeriodChangeFunction();
        this.set('payPeriodNotificationBox', false);
        this.set('payPeriodClickDisabled', false);
      },
      showCustomOptionsDialog: function showCustomOptionsDialog() {
        var overtime = this.get('store').createRecord('organization-overtime-period', {
          organizationId: this.get('organization.id'),
          weeklyOvertime: 40,
          dailyOvertime: null,
          dailyDoubletime: null,
          seventhDayOvertime: false,
          seventhDayDoubletime: null,
          saturdayMultiplier: null,
          sundayMultiplier: null
        });

        this.set('newOvertimePeriod', overtime);
        this.set('customOptionsDialog', true);
      },
      hideCustomOptionsDialog: function hideCustomOptionsDialog() {
        this.set('customOptionsDialog', false);
        this.set('newOvertimePeriod', null);
        this.get('overtimeRules').forEach(function (item) {
          return item.set('_selected', false);
        });
      },
      editCustomRules: function editCustomRules() {
        this.set('customOptions', true);
      },
      cancelCustomRules: function cancelCustomRules() {
        this.set('customOptions', false);
      },


      // handles the daily time flag select menu action and sets the organizations time flag to the value selected
      selectItemAction: function selectItemAction(item) {
        this.set('organization.totalTimeFlag', item.key);
      },


      //in the current pay period dialog, depending on what is selected it displays specific options and sets values
      selectItem: function selectItem(item) {
        if (item.get('key') === '10') {
          this.set('weeklySelected', true);
          this.set('biWeeklySelected', false);
          this.set('semiMonthlySelected', false);
          this.set('monthlySelected', false);
          this.set('newPayPeriodType', 10);
        }

        if (item.get('key') === '20') {
          this.set('biWeeklySelected', true);
          this.set('weeklySelected', false);
          this.set('semiMonthlySelected', false);
          this.set('monthlySelected', false);
          this.set('newPayPeriodType', 20);
        }

        if (item.get('key') === '30') {
          this.set('semiMonthlySelected', true);
          this.set('weeklySelected', false);
          this.set('biWeeklySelected', false);
          this.set('monthlySelected', false);
          this.set('newPayPeriodType', 30);
        }

        if (item.get('key') === '40') {
          this.set('monthlySelected', true);
          this.set('weeklySelected', false);
          this.set('biWeeklySelected', false);
          this.set('semiMonthlySelected', false);
          this.set('newPayPeriodType', 40);
        }
      },


      // select day of the month selected for day_two
      dayOfMonthSelectedTwo: function dayOfMonthSelectedTwo(item) {
        this.set('newDayTwo', item.key);
      },


      // select day of the week selected for day_one
      daySelected: function daySelected(item) {
        this.set('newDayOne', item.key);
      },
      saveCustomOvertime: function saveCustomOvertime() {
        var _this15 = this;

        var overtime = this.get('newOvertimePeriod');
        overtime.set('startTime', _utils.Time.date(this.get('overtimeChangeDate')).startOf('day').unix());
        this.setCustomOvertime(overtime);
        return this.saveOvertime(overtime).catch(function (err) {
          return _this15.handleOvertimeError(err);
        });
      },
      sundayMultiplier: function sundayMultiplier(item) {
        this.set('newOvertimePeriod.sundayMultiplier', item.key);
      },
      saturdayMultiplier: function saturdayMultiplier(item) {
        this.set('newOvertimePeriod.saturdayMultiplier', item.key);
      },
      seventhDayOvertime: function seventhDayOvertime(item) {
        this.set('newOvertimePeriod.seventhDayOvertime', item.key);
      },


      /**
       * Time Card Approval dialog - Save button clicked
       *
       * @event saveApprovalSettings
       * @param {dialog}
       */
      saveApprovalSettings: function saveApprovalSettings() {
        if (this.get('features.signatures') && this.get('disclaimerHasError')) {
          return Ember.RSVP.reject(this.get('disclaimerErrorMessage'));
        } else {
          return this.saveOrganization();
        }
      },


      /**
       * Triggered "Time Card Approval" "Change" button is clicked
       *
       * @event openApprovalDialog
       */
      openApprovalDialog: function openApprovalDialog() {
        this.set('showApprovalDialog', true);
      },


      /**
       * Time Card Approval dialog - cancel or close button clicked
       *
       * @event cancelApprovalDialog
       */
      cancelApprovalDialog: function cancelApprovalDialog() {
        this.undoChanges();
        this.initSignatureSettings();
        this.set('showApprovalDialog', false);
      },
      openTimeRoundingDialog: function openTimeRoundingDialog() {
        this.set('timeRoundingDialog', true);
        if (Ember.isNone(this.get('organization.timeRoundingInterval')) || this.get('organization.timeRoundingInterval') === 0) {
          this.set('organization.timeRoundingInterval', 15 * 60);
        }if (Ember.isNone(this.get('organization.timeRoundingType'))) {
          this.set('organization.timeRoundingType', 10);
        }
      },
      closeTimeRoundingDialog: function closeTimeRoundingDialog() {
        this.set('timeRoundingDialog', false);
        this.get('organization').rollbackAttributes();
      },
      setRoundingInterval: function setRoundingInterval(value) {
        this.set('organization.timeRoundingInterval', parseInt(value) * 60);
      },
      setRoundingType: function setRoundingType(value) {
        this.set('organization.timeRoundingType', parseInt(value));
      },
      openAppVersionDialog: function openAppVersionDialog() {
        this.set('showMinimumAppDialog', true);
      },
      closeMinimumAppDialog: function closeMinimumAppDialog() {
        this.set('showMinimumAppDialog', false);
        this.get('organization').rollbackAttributes();
      },
      setMinimumAppVersion: function setMinimumAppVersion(value) {
        this.set('organization.minimumAppVersion', value.get('key'));
      },
      setSignatureDate: function setSignatureDate(value) {
        this.set('organization.signatureDate', value);
      },
      showOvertimePreviewDialog: function showOvertimePreviewDialog() {
        this.set('overtimePreviewDialog', true);
      },
      hideOvertimePreviewDialog: function hideOvertimePreviewDialog() {
        this.set('overtimePreviewDialog', false);
      },
      openSafetyDialog: function openSafetyDialog() {
        this.set('showSafetyDialog', true);
      },
      closeSafetyDialog: function closeSafetyDialog(rollback) {
        this.set('showSafetyDialog', false);
        this.get('organization').rollbackAttributes();

        if (rollback) {
          this.get('safetySelectedArray').forEach(function (member) {
            member.set('selectedSafety', false);
          });
          this.get('accuracySelectedArray').forEach(function (member) {
            member.set('selectedAccuracy', false);
          });
          this.get('breakSelectedArray').forEach(function (member) {
            member.set('selectedBreaks', false);
          });
        }
        this.setProperties({
          safetySelectedArray: [],
          accuracySelectedArray: [],
          breakSelectedArray: [],
          safetyAllSelected: false,
          accuracyAllSelected: false,
          breaksAllSelected: false
        });

        if (this.get('safetyMessage') !== this.get('organization.safetySignatureMessage')) {
          this.set('organization.safetySignatureMessage', null);
        }
      },
      openDefaultTimeDialog: function openDefaultTimeDialog() {
        this.set('showDefaultTimeDialog', true);
      },
      closeDefaultTimeDialog: function closeDefaultTimeDialog() {
        this.set('showDefaultTimeDialog', false);
        this.get('organization').rollbackAttributes();
        this.initDefaultEntryTimes();
      },
      changeBreakHours: function changeBreakHours(item) {
        this.set('breakHours', item.key);
      },
      changeBreakMinutes: function changeBreakMinutes(item) {
        this.set('breakMinutes', item.key);
      },


      /*
       * Project Specific Cost Codes
       *
       */

      // When onChangeTrackCostCode is disabled, also disable requireCostCode and useProjectCostCodeScoping
      // this removes the disabled status on requireProject and will allow the clock-in prevention warning to trigger when those options are re-checked
      onChangeTrackCostCode: function onChangeTrackCostCode(newValue) {
        if (!newValue) {
          this.get('organization').setProperties({
            requireCostCode: false,
            useProjectCostCodeScoping: false
          });
        }
      },


      // when requireCostCode is enabled and useProjectCostCodeScoping is already enabled, warn the user that it could prevent clock-ins
      onChangeRequireCostCode: function onChangeRequireCostCode(newValue) {
        // Logger.info('onChangeRequireCostCode', newValue);

        if (newValue && this.get('organization.useProjectCostCodeScoping')) {
          this.send('openWarningCostCodesRequired', 'requireCostCode');
        }
      },


      // when useProjectCostCodeScoping is enabled and requireCostCode is already enabled, warn the user that it could prevent clock-ins
      onChangeUseProjectCostCodeScoping: function onChangeUseProjectCostCodeScoping(newValue) {
        // Logger.info('onChangeUseProjectCostCodeScoping', newValue);

        if (newValue && this.get('organization.requireCostCode')) {
          this.send('openWarningCostCodesRequired', 'useProjectCostCodeScoping');
        }
      },
      openWarningCostCodesRequired: function openWarningCostCodesRequired(trigger) {
        // Logger.info('openWarningCostCodesRequired');
        this.setProperties({
          showWarningCostCodesRequired: true,
          showWarningCostCodesRequiredTrigger: trigger
        });
      },
      closeWarningCostCodesRequired: function closeWarningCostCodesRequired() {
        // Logger.info('closeWarningCostCodesRequired');

        this.set('showWarningCostCodesRequired', false);
      },
      disableUseProjectCostCodeScoping: function disableUseProjectCostCodeScoping(trigger) {
        // Logger.info('disableUseProjectCostCodeScoping', trigger);

        this.get('organization').set(trigger, false);
        this.set('seenWarningCostCodesRequiredOnce', false);
        this.send('closeWarningCostCodesRequired');
      },
      confirmUseProjectCostCodeScoping: function confirmUseProjectCostCodeScoping(trigger) {
        var _get$setProperties;

        // Logger.info('confirmUseProjectCostCodeScoping', trigger);

        this.get('organization').setProperties((_get$setProperties = {}, _defineProperty(_get$setProperties, trigger, true), _defineProperty(_get$setProperties, 'requireProject', true), _get$setProperties));

        this.send('closeWarningCostCodesRequired');
      },
      openTimeAccuracySelectDialog: function openTimeAccuracySelectDialog() {
        this.set('showTimeAccuracySelectDialog', true);
      },
      closeAndRollbackTimeAccuracySelectDialog: function closeAndRollbackTimeAccuracySelectDialog() {
        this.get('accuracySelectedArray').forEach(function (member) {

          member.set('selectedAccuracy', member.get('accuracyOriginalValue'));
        });
        this.set('accuracySelectedArray', []);
        this.set('accuracyAllSelected', false);
        this.set('showTimeAccuracySelectDialog', false);
      },
      closeTimeAccuracySelectDialog: function closeTimeAccuracySelectDialog() {
        this.set('showTimeAccuracySelectDialog', false);
      },
      openSafetySelectDialog: function openSafetySelectDialog() {
        this.set('showSafetySelectDialog', true);
      },
      closeAndRollbackSafetySelectDialog: function closeAndRollbackSafetySelectDialog() {

        this.get('safetySelectedArray').forEach(function (member) {
          member.set('selectedSafety', member.get('safetyOriginalValue'));
        });

        this.set('safetySelectedArray', []);
        this.set('safetyAllSelected', false);
        this.set('showSafetySelectDialog', false);
      },
      closeSafetySelectDialog: function closeSafetySelectDialog() {
        this.set('showSafetySelectDialog', false);
      },
      openBreaksSelectDialog: function openBreaksSelectDialog() {
        this.set('showBreaksSelectDialog', true);
      },
      closeAndRollbackBreaksSelectDialog: function closeAndRollbackBreaksSelectDialog() {
        this.get('breakSelectedArray').forEach(function (member) {
          member.set('selectedBreaks', member.get('breaksOriginalValue'));
        });

        this.setProperties({
          breakSelectedArray: [],
          breaksAllSelected: false,
          showBreaksSelectDialog: false
        });
      },
      closeBreaksSelectDialog: function closeBreaksSelectDialog() {
        this.set('showBreaksSelectDialog', false);
      },
      addMemberToSafetyList: function addMemberToSafetyList(member) {

        member.set('safetyOriginalValue', !member.get('selectedSafety'));

        var array = this.get('safetySelectedArray');

        if (array.indexOf(member) === -1) {
          array.push(member);
        }
      },
      addMemberToAccuracyList: function addMemberToAccuracyList(member) {

        member.set('accuracyOriginalValue', !member.get('selectedAccuracy'));

        var array = this.get('accuracySelectedArray');

        if (array.indexOf(member) === -1) {
          array.push(member);
        }
      },
      addMemberToBreakList: function addMemberToBreakList(member) {

        member.set('breaksOriginalValue', !member.get('selectedBreak'));

        var array = this.get('breakSelectedArray');

        if (array.indexOf(member) === -1) {
          array.push(member);
        }
      },
      selectAllSafetyMembers: function selectAllSafetyMembers() {
        var array = this.get('safetySelectedArray');
        if (array.length !== this.get('members.length')) {
          this.get('members').forEach(function (member) {
            member.set("selectedSafety", true);
            array.push(member);
          });
        } else {
          this.set('safetySelectedArray', []);

          this.get('members').forEach(function (member) {
            member.set("selectedSafety", false);
          });
        }
      },
      selectAllAccuracyMembers: function selectAllAccuracyMembers() {
        var array = this.get('accuracySelectedArray');
        if (array.length !== this.get('members.length')) {
          this.get('members').forEach(function (member) {
            member.set("selectedAccuracy", true);
            array.push(member);
          });
        } else {
          this.set('accuracySelectedArray', []);

          this.get('members').forEach(function (member) {
            member.set("selectedAccuracy", false);
          });
        }
      },
      selectAllBreakMembers: function selectAllBreakMembers() {
        var array = this.get('breakSelectedArray');
        if (array.length !== this.get('members.length')) {
          this.get('members').forEach(function (member) {
            member.set("selectedBreaks", true);
            array.push(member);
          });
        } else {
          this.set('breakSelectedArray', []);

          this.get('members').forEach(function (member) {
            member.set("selectedBreaks", false);
          });
        }
      },
      openOvertimeHistoryDialog: function openOvertimeHistoryDialog() {
        this.set('showOvertimeHistoryDialog', true);
      },
      closeOvertimeHistoryDialog: function closeOvertimeHistoryDialog() {
        this.set('showOvertimeHistoryDialog', false);
      },
      showOtDetails: function showOtDetails(overtime) {
        this.setProperties({
          showOtDetailsDialog: true,
          selectedOvertimePeriod: overtime
        });
      },
      closeOtDetails: function closeOtDetails() {
        this.setProperties({
          showOtDetailsDialog: false,
          selectedOvertimePeriod: null
        });
      },
      viewScheduledOvertimeChange: function viewScheduledOvertimeChange() {
        this.setProperties({
          showScheduledDetailsDialog: true
        });
      },
      closeScheduledOvertimeChange: function closeScheduledOvertimeChange() {
        this.setProperties({
          showScheduledDetailsDialog: false
        });
      },
      openSignInDialog: function openSignInDialog() {
        this.setProperties({
          showSignInDialog: true
        });
      },
      closeSignInDialog: function closeSignInDialog() {
        this.setProperties({
          showSignInDialog: false
        });
      },
      showCovidQuestions: function showCovidQuestions() {
        this.setProperties({
          covidQuestionsVisible: true
        });
      },
      hideCovidQuestions: function hideCovidQuestions() {
        this.setProperties({
          covidQuestionsVisible: false
        });
      }
    }
  });
});