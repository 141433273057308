define('busy-app/components/projects/labor-budget', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-projects-labor-budget'],

		store: Ember.inject.service('store'),
		features: Ember.inject.service('features'),

		// passed in model
		model: null,

		laborCost: Ember.computed('model.project.budgetCostModel.cost', function () {
			// make a copy, don't pass through a reference
			return parseFloat(Ember.get(this, 'model.project.budgetCostModel.cost')) + 0;
		}),

		laborHours: Ember.computed('model.project.budgetHoursModel.hours', function () {
			// make a copy, don't pass through a reference
			return parseFloat(Ember.get(this, 'model.project.budgetHoursModel.hours')) + 0;
		}),

		init: function init() {
			this._super();

			// the page should only update on blur, not onChange,
			//   so, attach some oneWay properties we can use to prevent 2 way binding
			Ember.get(this, 'model.subprojects').forEach(function (p) {
				Ember.set(p, 'budgetCostModelCostValue', Ember.computed.oneWay('budgetCostModel.cost'));
				Ember.set(p, 'budgetHoursModelHoursValue', Ember.computed.oneWay('budgetHoursModel.hours'));
			});
		},


		actions: {
			saveHours: function saveHours(newValue, item) {
				newValue = parseFloat(newValue) + 0;
				Ember.set(item, 'budgetHoursModel.hours', newValue);
				var value = item.get('budgetHoursModel.hours');
				var seconds = parseInt(parseFloat(value) * 3600);
				var mSeconds = item.get('budgetHoursModel.seconds');
				if (!isNaN(seconds) && !Ember.isNone(seconds) && seconds !== mSeconds) {
					var budgetSeconds = this.get('store').createRecord('project-hours-budget', {
						projectId: item.id,
						budgetSeconds: seconds
					});

					if (budgetSeconds.get('seconds') >= 0) {
						budgetSeconds.save().then(function () {
							var budget = item.get('projectHoursBudget.content');
							budget.update();
						});
					}
				}
			},
			saveCost: function saveCost(newValue, item) {
				newValue = parseFloat(newValue) + 0;
				Ember.set(item, 'budgetCostModel.cost', newValue);
				var costBudget = this.get('store').createRecord('project-cost-budget', {
					projectId: item.id,
					costBudget: parseFloat(item.get('budgetCostModel.cost'))
				});

				if (costBudget.get('cost') >= 0 && (!Ember.isNone(item.get('budgetCostModel')) || item.get('budgetCostModel.cost') !== costBudget.get('cost'))) {
					costBudget.save().then(function () {
						var budget = item.get('projectCostBudget.content');
						budget.update();
					});
				}
			},
			loadProject: function loadProject(item) {
				(true && !(!Ember.isNone(item) && !Ember.isNone(item.get('id'))) && Ember.assert('action requires a project with a valid id <projects.labor-budget:loadProject>', !Ember.isNone(item) && !Ember.isNone(item.get('id'))));

				// add 2 paths to the history then call back once to transition to the first push state

				window.history.pushState(undefined, undefined, '/project/' + item.get('id') + '#tab-labor-budget');
				window.history.pushState(undefined, undefined, '/project/' + item.get('id'));
				window.history.back();
			}
		}
	});
});