define('busy-app/utils/request-handler', ['exports', 'ember-inflector', 'busy-app/utils/request-helpers'], function (exports, _emberInflector, _requestHelpers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var getModelProperty = _requestHelpers.default.getModelProperty;


	/**
  * Private helper method to get an array if ids
  * from the parent model
  *
  * @private
  * @method getParamFromModels
  * @param key {string} the key to be used in finding the properties
  * @param model {DS.Model}
  * @return {array}
  */
	function getParamFromModels(key, model) {
		var prop = null;
		if (!Ember.isNone(model.forEach)) {
			prop = [];
			model.forEach(function (item) {
				var id = getModelProperty(item, key);
				if (!Ember.isNone(id) && prop.indexOf(id) === -1) {
					prop.push(id);
				}
			});
		} else {
			var id = getModelProperty(model, key);
			if (!Ember.isNone(id)) {
				prop = [id];
			}
		}
		return prop;
	}

	function objectIsEmpty(obj) {
		if (Ember.isNone(obj)) {
			return true;
		}

		var length = 0;
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				length = length + 1;
			}
		}
		return length === 0;
	}

	function isJoinAll(type) {
		return (/join/.test(Ember.String.dasherize(type)) && /all/.test(Ember.String.dasherize(type))
		);
	}

	function isOuterJoin(type) {
		return (/join/.test(Ember.String.dasherize(type)) && /outer/.test(Ember.String.dasherize(type))
		);
	}

	function forEachPromise(model, callback) {
		var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		return new Ember.RSVP.Promise(function (resolve) {
			var length = Ember.get(model, 'length') || 0;
			model.forEach(function (item, index, enumerable) {
				callback.call(target, item, index, enumerable);

				if (index >= length - 1) {
					// resolve promise
					Ember.run(target, resolve, null);
				}
			});

			if (length === 0) {
				Ember.run(target, resolve, null);
			}
		});
	}

	/**
  * `Util\RequestHandler`
  *
  */
	exports.default = Ember.Object.extend({
		store: null,

		finishedList: null,

		buildQuery: function buildQuery(operation) {
			var modelType = Ember.get(operation, 'modelType');
			var query = Ember.get(operation, 'params.query');

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));
			(true && !(!Ember.isNone(query)) && Ember.assert('You must set a query params for the operation object.', !Ember.isNone(query)));


			return this.store.query(modelType, query);
		},
		buildQueryRecord: function buildQueryRecord(operation) {
			var modelType = Ember.get(operation, 'modelType');
			var query = Ember.get(operation, 'params.query');

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));
			(true && !(!Ember.isNone(query)) && Ember.assert('You must set a query params for the operation object.', !Ember.isNone(query)));


			return this.store.queryRecord(modelType, query);
		},
		buildFindAll: function buildFindAll(operation) {
			var modelType = Ember.get(operation, 'modelType');
			var query = Ember.get(operation, 'params.query');

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));
			(true && !(!Ember.isNone(query)) && Ember.assert('You must set a query params for the operation object.', !Ember.isNone(query)));


			return this.store.findAll(modelType, query);
		},
		buildFindRecord: function buildFindRecord(operation) {
			var modelType = Ember.get(operation, 'modelType');
			var id = Ember.get(operation, 'params.id');

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));
			(true && !(!Ember.isNone(id)) && Ember.assert('You must set an id for the operation object.', !Ember.isNone(id)));


			return this.store.findRecord(modelType, id);
		},
		buildFindWhereIn: function buildFindWhereIn(operation) {
			var modelType = Ember.get(operation, 'modelType');
			var key = Ember.get(operation, 'params.key');
			var id = Ember.get(operation, 'params.id');
			var query = Ember.get(operation, 'params.query');

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));
			(true && !(!Ember.isNone(key)) && Ember.assert('You must set a key for the operation object.', !Ember.isNone(key)));
			(true && !(!Ember.isNone(id)) && Ember.assert('You must set an id for the operation object.', !Ember.isNone(id)));
			(true && !(!Ember.isNone(query)) && Ember.assert('You must set a query params for the operation object.', !Ember.isNone(query)));


			return this.store.findWhereIn(modelType, key, id, query);
		},
		buildOuterJoin: function buildOuterJoin(operation, parentModel) {
			return this.buildJoinTo(operation, parentModel);
		},
		buildOuterJoinAll: function buildOuterJoinAll(operation, parentModel) {
			return this.buildJoinTo(operation, parentModel);
		},
		buildJoin: function buildJoin(operation, parentModel) {
			return this.buildJoinTo(operation, parentModel);
		},
		buildJoinAll: function buildJoinAll(operation, parentModel) {
			return this.buildJoinTo(operation, parentModel);
		},
		buildJoinTo: function buildJoinTo(operation, parentModel) {
			var type = Ember.get(operation, 'operationType');
			var modelType = Ember.get(operation, 'modelType');
			var joinOn = Ember.get(operation, 'params.joinOn');
			var joinModel = Ember.get(operation, 'params.joinModel');
			var query = Ember.get(operation, 'params.query') || {};
			var alias = Ember.String.camelize(Ember.get(operation, 'alias'));

			var hasMany = isJoinAll(Ember.String.dasherize(type));
			var isOuter = isOuterJoin(Ember.String.dasherize(type));

			(true && !(!Ember.isNone(modelType)) && Ember.assert('You must set a model type in the operation object.', !Ember.isNone(modelType)));


			var modelKey = Ember.String.underscore(joinOn === 'id' ? joinModel + '-id' : 'id');
			var modelProperty = getParamFromModels(joinOn, parentModel);
			if (Ember.isNone(modelProperty)) {
				return Ember.RSVP.resolve(null);
			}

			return this.store.findWhereIn(modelType, modelKey, modelProperty, query).then(function (children) {
				alias = hasMany ? (0, _emberInflector.pluralize)(alias) : alias;

				var promise = Ember.RSVP.resolve(children);
				if (!isOuter) {
					promise = Ember.RSVP.resolve(null);
					if (!Ember.isNone(parentModel.forEach)) {
						var getter = hasMany ? 'filterBy' : 'findBy';
						promise = forEachPromise(parentModel, function (item) {
							var childGet = Ember.get(children, getter);
							var value = childGet.call(children, Ember.String.camelize(modelKey), getModelProperty(item, joinOn));
							Ember.set(item, alias, value);
						});
					} else {
						parentModel.set(alias, hasMany ? children : children.objectAt(0));
					}
				}
				return promise;
			});
		},


		dispatchCall: function dispatchCall(type, operation, parentModel) {
			return this['build' + Ember.String.classify(type)].call(this, operation, parentModel);
		},

		buildRequest: function buildRequest(operations, parents) {
			var _this = this;

			var requests = {};
			var remove = [];

			operations.forEach(function (item) {
				var type = Ember.get(item, 'operationType');
				var alias = Ember.String.camelize(Ember.get(item, 'alias'));

				if (type === 'join' || type === 'joinAll' || type === 'outerJoin' || type === 'outerJoinAll') {
					alias = isJoinAll(type) ? (0, _emberInflector.pluralize)(alias) : alias;
					var joinTo = Ember.get(item, 'params.join');
					alias = alias + '-' + joinTo;

					if (!objectIsEmpty(parents)) {
						var parentPath = _this.findParentPath(item);

						if (!Ember.isEmpty(parentPath)) {
							var parentModel = Ember.get(parents, parentPath);

							if (!Ember.isNone(parentModel)) {
								if (!Ember.isNone(Ember.get(parentModel, 'value'))) {
									parentModel = Ember.get(parentModel, 'value');
								}

								if (Ember.get(parentModel, 'isLoaded') === true || Ember.get(parentModel, 'content.isLoaded') === true) {
									requests[alias] = _this.dispatchCall(type, item, parentModel);
									remove.pushObject(item);
									_this.finishedList.unshiftObject(item);
								}
							}
						}
					}
				} else {
					requests[alias] = _this.dispatchCall(type, item);
					remove.pushObject(item);
					_this.finishedList.unshiftObject(item);
				}
			});

			operations.removeObjects(remove);
			return Ember.RSVP.hashSettled(requests).then(function (data) {
				// convert hashSettled data to regular hash data
				var _data = {};
				for (var i in data) {
					if (data.hasOwnProperty(i)) {
						var val = Ember.get(data[i], 'value');
						if (!Ember.isNone(val)) {
							_data[i.replace(/-[\s\S]*$/, '')] = val;
						}
					}
				}
				return _data;
			});
		},
		findParentPath: function findParentPath(operation) {
			var joinName = Ember.get(operation || {}, 'params.join');
			if (Ember.isNone(joinName)) {
				return '';
			}

			var parentPath = '';
			var currJoin = joinName;
			var finished = this.finishedList;
			var isOuter = false;

			for (var key in finished) {
				if (finished.hasOwnProperty(key)) {
					var item = finished[key];
					if (Ember.get(item, 'modelType') === Ember.String.dasherize(currJoin)) {
						var tempName = Ember.String.camelize(Ember.get(item, 'modelType'));
						if (isJoinAll(Ember.get(item, 'operationType'))) {
							tempName = (0, _emberInflector.pluralize)(tempName);
						}

						parentPath = tempName + '.' + parentPath;
						currJoin = Ember.get(item, 'params.join');
						isOuter = isOuterJoin(Ember.get(item, 'operationType'));
					} else if (Ember.get(item, 'alias') === Ember.String.camelize(currJoin)) {
						parentPath = Ember.get(item, 'alias') + '.' + parentPath;
						currJoin = Ember.get(item, 'params.join');
						isOuter = isOuterJoin(Ember.get(item, 'operationType'));
					}

					// break from the loop if the currJoin is null
					// or we hit an outer join model
					if (Ember.isNone(currJoin) || isOuter) {
						break;
					}
				}
			}
			parentPath = parentPath.replace(/\.$/, '');
			return parentPath;
		}
	});
});