define('busy-app/components/company/integrations/grid/grid-item-base', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		integration: Ember.inject.service('integration'),
		features: Ember.inject.service('features'),
		proFeature: Ember.inject.service('pro-feature'),
		store: Ember.inject.service('store'),

		/**
   * Label identifying the name of the organization-integration
   *
   * @public
   * @property integrationName
   * @type String
   * @example
   * procore
   */
		integrationName: null,

		/**
   * Ember route to follow when this element is clicked
   *
   * @public
   * @property dashboardRoute
   * @type String
   * @default null
   * @example
   * company-settings.procore
   */
		dashboardRoute: null,

		/**
   * URL path to the company logo image. Should begin with '/'
   *
   * @public
   * @property logo
   * @type String
   * @default null
   * @example
   * '/images/integration-logos/integration_logos-02.png'
   */
		logo: null,

		/**
   * Feature flag for this integration
   *
   * @public
   * @property featureFlag
   * @type String
   * @default null
   * @example
   * procore
   */
		featureFlag: null,

		/**
   * Properties
   */

		/**
   * Error type
   *
  	* @public
   * @property errorType
   * @type String
   * @default null
   */
		errorType: null,

		/**
   * Error message
   *
  	* @public
   * @property errorMessage
   * @type String
   * @default null
   */
		errorMessage: null,

		/**
   * Is the integration connected to the organization
   *
  	* @public
   * @property isConnected
   * @type Boolean
   * @default false
   */
		isConnected: false,

		/**
   * Is there an import currently in progress
   *
  	* @public
   * @property isInProgress
   * @type Boolean
   * @default false
   */
		isInProgress: false,

		/**
   * Is data loading in progress
   *
  	* @public
   * @property isLoading
   * @type Boolean
   * @default false
   */
		isLoading: false,

		/**
   * Should the upgrade dialog be displayed
   *
  	* @public
   * @property showUpgradeDialog
   * @type Boolean
   * @default false
   */
		showUpgradeDialog: false,

		/**
   * Computed Properties
   */

		/**
   * Computed property using the `featureFlag` property
   *
   * @public
   * @property featureEnabled
   * @type Boolean
   */
		featureEnabled: Ember.computed('features', function () {
			var featureFlag = this.get('featureFlag');

			return this.get('features').isEnabled(featureFlag);
		}),

		proFeatureManager: null,

		/**
   * Component Lifecycle
   */

		/**
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			(true && !(!Ember.isNone(this.get('integrationName'))) && Ember.assert('grid-item-base :: init :: \'integrationName\' is a required property', !Ember.isNone(this.get('integrationName'))));
			(true && !(!Ember.isNone(this.get('dashboardRoute'))) && Ember.assert('grid-item-base :: init :: \'dashboardRoute\' is a required property', !Ember.isNone(this.get('dashboardRoute'))));
			(true && !(!Ember.isNone(this.get('logo'))) && Ember.assert('grid-item-base :: init :: \'logo\' is a required property', !Ember.isNone(this.get('logo'))));
			(true && !(!Ember.isNone(this.get('featureFlag'))) && Ember.assert('grid-item-base :: init :: \'featureFlag\' is a required property', !Ember.isNone(this.get('featureFlag'))));


			this.set('proFeatureManager', this.get('proFeature').getManager());
		},


		/**
   * Public Methods
   */

		/**
   * Standard setStatus().
   * Custom integrations can override.
   *
   *	sets properties: {
   *		errorType,
   *		errorMessage,
   *		isConnected,
   *		isInProgress,
   *	}
   *
  	* @public
   * @method setStatus
   * @returns {RSVP}
   */
		setStatus: function setStatus() {
			var _this = this;

			if (!this.get('isDestroyed')) {
				if (this.get('featureEnabled')) {
					return this.getStatus().then(function (status) {
						if (!_this.get('isDestroyed')) {
							status = !Ember.isNone(status) ? status : {};

							var _status = status,
							    errorType = _status.errorType,
							    errorMessage = _status.errorMessage,
							    isConnected = _status.isConnected,
							    isInProgress = _status.isInProgress;


							_this.setProperties({
								errorType: errorType,
								errorMessage: errorMessage,
								isConnected: isConnected,
								isInProgress: isInProgress
							});
						}

						return null;
					});
				} else {
					var errorType = null;
					var errorMessage = (0, _utils.loc)('Not Connected');
					var isConnected = false;

					this.setProperties({
						errorType: errorType,
						errorMessage: errorMessage,
						isConnected: isConnected
					});

					return Ember.RSVP.resolve(null);
				}
			}
		},


		/**
   * Standard getStatus().
   * Custom integrations should override with promise returning
   *
   *	{
   *		errorType
   *		errorMessage
   *		isConnected
   *		progress
   *		isInProgress
   *	}
   *
  	* @public
   * @method getStatus
   * @returns {RSVP} containing { errorType, errorMessage, isConnected, progress, isInProgress, }
   */
		getStatus: function getStatus() {
			var _this2 = this;

			var organization = this.get('auth.organization.content');
			var integrationName = this.get('integrationName');

			(true && !(integrationName) && Ember.assert('grid-item-base :: \'integrationName\' is a required property', integrationName));


			this.set('isLoading', true);
			if (!Ember.isNone(organization)) {
				return organization.getIntegrationStatus(integrationName).catch(function (reason) {
					_this2.setProperties({
						errorType: (0, _utils.loc)('Unknown Error'),
						errorMessage: reason,
						isConnected: false
					});

					throw reason;
				}).finally(function () {
					_this2.set('isLoading', false);
				});
			} else {
				return Ember.RSVP.resolve();
			}
		},


		/**
   * Transition to the specified route
   *
  	* @public
   * @method transitionTo
   * @param {String} route - The route to load
   * @returns {Transition}
   */
		transitionTo: function transitionTo(route) {
			return Ember.getOwner(this).lookup('route:application').transitionTo(route);
		},


		actions: {
			clickHandler: function clickHandler() {
				var _this3 = this;

				var featureFlag = this.get('featureFlag');
				var dashboardRoute = this.get('dashboardRoute');

				this.get('proFeatureManager').requestAction(featureFlag, function () {
					return _this3.transitionTo(dashboardRoute);
				});
			},
			proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
				this.get('proFeatureManager').openModal();
			},
			proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
				this.get('proFeatureManager').closeModal();
			},
			proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
				this.get('proFeatureManager').onAllow();
			}
		}
	});
});