define('busy-app/utils/models/filters/project', ['exports', 'busy-app/utils/models/filters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	function _orderProjects(data, id) {
		if (!Ember.isNone(id)) {
			var project = data.findBy('id', id);
			if (!Ember.isNone(project)) {
				var parentProject = _orderProjects(data, project.get('parentProjectId'));
				if (!Ember.isNone(parentProject)) {
					project.set('parentProject', parentProject);
				}
				return project;
			}
		}
		return null;
	}

	/**
  * `Util/Model/Filter/Project`
  *
  * @class Project
  * @namespace Utils.Models.Filters
  * @extends Utils.Models.Filter.Base
  */
	exports.default = _base.default.extend({
		findByParentProject: function findByParentProject(modelType, id, query) {
			Ember.deprecate("findByParentProject is deprecated, please use findWithAllChildren", false, {
				id: 'utils.models.filters.project',
				until: '4.5.0'
			});
			return this.findWithAllChildren(modelType, id, query);
		},
		findWithAllChildren: function findWithAllChildren(modelType, id, query) {
			var _this = this;

			query = query || {};
			if (!query.deleted_on) {
				query.deleted_on = '_-NULL-_';
			}

			return this.store.findAll('project-lookup', { parent_id: id }).then(function (lookups) {
				var ids = [id];
				lookups.forEach(function (item) {
					if (ids.indexOf(item.get('childId')) === -1) {
						ids.push(item.get('childId'));
					}
				});
				return _this.store.findWhereIn(modelType, 'project_id', ids, query);
			});
		},
		findWithDirectChildren: function findWithDirectChildren(modelType, id, query) {
			var _this2 = this;

			query = query || {};
			if (!query.deleted_on) {
				query.deleted_on = '_-NULL-_';
			}

			return this.store.findAll('project-lookup', { parent_id: id, is_direct: true }).then(function (lookups) {
				var ids = [id];
				lookups.forEach(function (item) {
					if (ids.indexOf(item.get('childId')) === -1) {
						ids.push(item.get('childId'));
					}
				});
				return _this2.store.findWhereIn(modelType, 'project_id', ids, query);
			});
		},
		findParentProjects: function findParentProjects(ids) {
			var _this3 = this;

			ids = typeof ids === 'string' ? [ids] : ids;
			var projectIds = ids.copy();
			return this.store.findWhereIn('project-lookup', 'child_id', ids).then(function (lookups) {
				lookups.forEach(function (item) {
					if (projectIds.indexOf(item.get('parentId')) === -1) {
						projectIds.push(item.get('parentId'));
					}
				});
				return _this3.store.findByIds('project', projectIds);
			});
		},


		/**
   * Gets a project with the parent projects
   *
   * @public
   * @method findFullProject
   * @params ids {string} Project UUID string
   * @return {EmberPromise}
   */
		findFullProject: function findFullProject(id) {
			if (Ember.isNone(id)) {
				return Ember.RSVP.resolve(null);
			}
			// return this.findParentProjects(id).then(projects => {
			// 	return _orderProjects(projects, id);
			// });
			return this.findFullProjects([id]);
		},


		/**
   * Gets an array of projects with the parent projects attached and a project info model
   * attached to the root level project.
   *
   * @public
   * @method findFullProjects
   * @params ids {array} An array of project ids
   * @return {EmberPromise}
   */
		findFullProjects: function findFullProjects(ids) {
			var _this4 = this;

			(true && !(!Ember.isNone(ids) && (typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object') && Ember.assert('findFullProjects requires an array of ids', !Ember.isNone(ids) && (typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object'));


			if (Ember.isEmpty(ids)) {
				return Ember.RSVP.resolve([]);
			}

			var projectIds = ids.copy();

			return this.findParentProjects(ids).then(function (projects) {
				var topLevelProjects = [];
				projectIds.forEach(function (id) {
					var project = projects.findBy('id', id);
					if (topLevelProjects.indexOf(project.get('rootProjectId')) === -1) {
						topLevelProjects.push(project.get('rootProjectId'));
					}
				});

				return Ember.RSVP.hash({
					projects: projects,
					projectInfos: _this4.findProjectInfos(projects.mapBy('id'))
				}).then(function (data) {
					data.projectInfos.forEach(function (info) {
						var project = data.projects.findBy('id', info.get('projectId'));
						if (!Ember.isNone(project)) {
							project.set('projectInfo', info);
						}
					});

					var projectArray = [];
					projectIds.forEach(function (id) {
						var project = _orderProjects(projects, id);
						projectArray.pushObject(project);
					});
					return projectArray;
				});
			});
		},


		/**
   * Takes an array of root project ids and returns an array of project info models
   *
   * @public
   * @method findProjectInfos
   * @params rootIds {array} An array of root project ids
   * @return {EmberPromise}
   */
		findProjectInfos: function findProjectInfos(rootIds) {
			(true && !(!Ember.isNone(rootIds) && (typeof rootIds === 'undefined' ? 'undefined' : _typeof(rootIds)) === 'object') && Ember.assert('findProjectInfos requires an array of rootIds', !Ember.isNone(rootIds) && (typeof rootIds === 'undefined' ? 'undefined' : _typeof(rootIds)) === 'object'));


			if (Ember.isEmpty(rootIds)) {
				return Ember.RSVP.resolve([]);
			}
			return this.store.findWhereIn('project-info', 'project_id', rootIds, { deleted_on: '_-NULL-_' });
		}
	});
});