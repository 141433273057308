define('busy-app/utils/models/filters/time-off-entry', ['exports', 'busy-app/utils/models/filters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * gets the open time entry model
   *
   * @method findOpenEntry
   */
		findOpenEntry: function findOpenEntry(memberId) {
			//var _this = this;
			var query = {
				end_time: '_-NULL-_',
				member_id: memberId
			};

			return this.store.query('time-entry', query).then(function (entries) {
				return entries.objectAt(0);
			});
		},

		findOpenEntrySocket: function findOpenEntrySocket(memberId) {
			//var _this = this;
			var query = {
				end_time: '_-NULL-_',
				member_id: memberId
			};

			return this.store.socket('time-entry', query);
		},

		findAllOpenEntries: function findAllOpenEntries(memberId) {
			var query = {
				end_time: '_-NULL-_',
				member_id: memberId
			};

			return this.store.query('time-entry', query);
		},

		/**
   * returns a date range of time-entries with no dependent
   * models included.
   *
   * @public
   * @method range
   * @param start {number} start epoch date
   * @param end {number} end epoch date
   * @param memberId {string} member model id
   * @param projectId {string} project model id
   * @param costCodeId {string} cost-code model id
   * @returns {promise}
   */
		range: function range(start, end, memberId, projectId, costCodeId) {
			(true && !(!Ember.isNone(start) && !Ember.isNone(end)) && Ember.assert("start and end are required for store.filter('time-entry').range()", !Ember.isNone(start) && !Ember.isNone(end)));
			(true && !(typeof start === 'number') && Ember.assert("start must be a valid epoch time in seconds of type number", typeof start === 'number'));
			(true && !(typeof end === 'number') && Ember.assert("end must be a valid epoch time in seconds of type number", typeof end === 'number'));


			var query = {
				_gte: { end_time: start },
				_lte: { start_time: end },
				_desc: ['start_time']
			};

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			if (!Ember.isNone(projectId)) {
				query.project_id = projectId;
			}

			if (!Ember.isNone(costCodeId)) {
				query.cost_code_id = costCodeId;
			}
			return this.store.query('time-entry', query);
		},
		findAllByRange: function findAllByRange(start, end, memberId, projectId, costCodeId, exclusive) {
			(true && !(!Ember.isNone(start) && !Ember.isNone(end)) && Ember.assert("start and end are required for store.filter('time-entry').range()", !Ember.isNone(start) && !Ember.isNone(end)));
			(true && !(typeof start === 'number') && Ember.assert("start must be a valid epoch time in seconds of type number", typeof start === 'number'));
			(true && !(typeof end === 'number') && Ember.assert("end must be a valid epoch time in seconds of type number", typeof end === 'number'));


			var query = {
				_gte: { end_time: start },
				_lte: { start_time: end },
				_desc: ['start_time']
			};

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			if (!Ember.isNone(costCodeId)) {
				query.cost_code_id = costCodeId;
			}

			if (!Ember.isNone(projectId) && (projectId === '_-NULL-_' || exclusive)) {
				query.project_id = projectId;
				projectId = null;
			}

			if (!Ember.isNone(projectId)) {
				return this.store.filter('project').findWithAllChildren('time-entry', projectId, query);
			} else {
				return this.store.findAll('time-entry', query);
			}
		},
		getRelatedModels: function getRelatedModels(timeEntry) {
			if (Ember.isNone(timeEntry)) {
				return Ember.RSVP.resolve({
					entry: null,
					breaks: null,
					project: null,
					costCode: null,
					timeOff: null
				});
			}

			var projectPromise = this.store.filter('project').findFullProject(timeEntry.get('projectId'));

			var costCodePromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('costCodeId'))) {
				costCodePromise = this.store.findRecord('costCode', timeEntry.get('costCodeId'));
			}

			var timeOffPromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('timeOffId'))) {
				timeOffPromise = this.store.findRecord('time-off', timeEntry.get('timeOffId'));
			}

			return Ember.RSVP.hash({
				entry: timeEntry,
				breaks: this.store.query('time-entry-break', { time_entry_id: timeEntry.id }),
				project: projectPromise,
				costCode: costCodePromise,
				timeOff: timeOffPromise
			});
		}
	});
});