define('busy-app/components/company/integrations/integration-dashboard', ['exports', 'ember-data', 'moment', 'busy-app/utils/logger', '@busy-web/utils'], function (exports, _emberData, _moment, _logger, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var PromiseObject = _emberData.default.PromiseObject;
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		store: Ember.inject.service('store'),
		subscription: Ember.inject.service('subscription'),
		features: Ember.inject.service('features'),
		integration: Ember.inject.service('integration'),

		/**
   * Public Properties
   */

		/**
   * Label identifying the name of the organization-integration
   *
   * @public
   * @property integrationName
   * @type String
   */
		integrationName: null,

		/**
   * Target property from the parent controller
   *
   * @public
   * @property parentTarget
   * @type EmberObject
   */
		parentTarget: null,

		/**
   * @public
   * @property organizationIntegration
   * @type {Models.OrganizationIntegration}
   * @default null
   */
		organizationIntegration: null,

		/**
   * @public
   * @property lastAttempt
   * @type {Models.IntegrationAttempt}
   * @default null
   */
		lastAttempt: null,

		/**
   * Is the data for the `organization-integration` and last `integration-attempt` loading
   *
  	 * @public
   * @property isLoading
   * @type {boolean}
   * @default false
   */
		isLoading: false,
		isLoadingOrganizationIntegration: false,
		isLoadingLastAttempt: false,

		/**
   * Short categorization of the error
   *
  	 * @public
   * @property errorType
   * @type {boolean}
   */
		errorType: null,

		/**
   * Non-Localized error message
   *
  	 * @public
   * @property errorMessage
   * @type {boolean}
   */
		errorMessage: null,

		/*
   *
   */
		recentIntegrationAttemptErrors: null,

		/**
   * Private Properties
   */

		/**
   * Minimum time to wait before refreshing the model if there is not an import in progress
   *
   * @private
   * @property _refreshTimeoutMin
   * @type {number}
   */
		_refreshTimeoutMin: 1000 * 4,
		// _refreshTimeoutMin: 32 * 1000,

		/**
   * Maximum time to wait before refreshing the model if there is not an import in progress
   *
   * @private
   * @property _refreshTimeoutMax
   * @type {number}
   */
		_refreshTimeoutMax: 1000 * 8,
		// _refreshTimeoutMax: 64 * 1000,

		/**
   * Minimum time to wait before refreshing the model if there is an import in progress
   *
   * @private
   * @property _refreshTimeoutMinInProgress
   * @type {number}
   */
		_refreshTimeoutMinInProgress: 1000 * 1,
		// _refreshTimeoutMinInProgress: 1000 / 2,

		/**
   * Maximum time to wait before refreshing the model if there is an import in progress
   *
   * @private
   * @property _refreshTimeoutMaxInProgress
   * @type {number}
   */
		_refreshTimeoutMaxInProgress: 1000 * 2,
		// _refreshTimeoutMaxInProgress: 1000 / 1,

		/**
   * Collection of timers created from later() calls
   *
   * @private
   * @property _timers
   * @type {EmberObject}
   */
		_timers: Ember.Object.create(),

		/**
   * Public Computed Properties
   */

		/**
   * Computed alias on `auth.organization.content`
   *
   * @public
   * @property organization
   * @type {Models.Organization}
   */
		organization: Ember.computed.alias('auth.organization.content'),

		/**
   * Is there an `integration-attempt` for this `organization-integration`. Computed `bool` on `lastAttempt.id`
   *
   * @public
   * @property hasAttempt
   * @type {boolean}
   */
		hasAttempt: Ember.computed.bool('organizationIntegration.lastAttemptId'),

		/**
   * Does the latest `integration-attempt` have an `endTime`. Computed `bool` on `lastAttempt.endTime`
   *
   * @public
   * @property hasAttemptEndTime
   * @type {boolean}
   */
		hasAttemptEndTime: Ember.computed.bool('lastAttempt.endTime'),

		/**
   * Is there a completed import for this `organization-integration`. Computed `and` on `hastAttempt`, `hasAttemptEndTime`
   *
   * @public
   * @property hasImportCompleted
   * @type {boolean}
   */
		hasImportCompleted: Ember.computed.and('hasAttempt', 'hasAttemptEndTime'),

		hasImportErrors: Ember.computed.bool('errorsSinceSuccess.count'),
		lastAttemptSuccessful: Ember.computed.bool('lastAttempt.success'),
		lastAttemptFailed: Ember.computed.not('lastAttemptSuccessful'),

		/**
   * Is there currently an import running on this `organization-integration`. Computed on `organizationIntegration` and `lastAttempt`
   *
   * @public
   * @property isImportInProgress
   * @type {boolean}
   */
		//isImportInProgress: computed('organizationIntegration', 'lastAttempt', function() {
		//return (
		//	!isEmpty(this.get('organizationIntegration')) &&
		//	!isEmpty(this.get('lastAttempt')) &&
		//	this.get('organizationIntegration.enabled') &&
		//	!this.get('lastAttempt.endTime')
		//);
		//}),
		isImportInProgress: Ember.computed('lastAttempt.progress', 'hasAttemptEndTime', function () {
			var progress = this.get('lastAttempt.progress');

			if (this.get('hasAttemptEndTime') || Ember.isNone(progress) || parseInt(progress, 10) === 100) {
				return false;
			} else {
				return true;
			}
		}),

		/**
   * Format to an int with a percent sign. Computed on `lastAttempt.progress`
   *
   * @public
   * @property importProgressPercent
   * @type {string}
   */
		importProgressPercent: Ember.computed('lastAttempt.progress', function () {
			var progressInt = parseInt(this.getWithDefault('lastAttempt.progress', 0), 10);
			return Number.isInteger(progressInt) ? progressInt + '%' : '0%';
		}),

		/**
   * @public
   * @property lastSyncTimeLocalized
   * @type {string}
   */
		lastSyncTimeLocalized: Ember.computed('lastAttempt.endTime', function () {
			var timestamp = this.get('lastAttempt.endTime');

			if (Ember.isNone(timestamp)) {
				return null;
			} else {
				return _utils.Time.dateFormat((0, _moment.default)(timestamp * 1000), "DD MMMM, YYYY @ hh:mm A");
			}
		}),

		/**
   * Provide the most recently successful `IntegrationAttempt` using a PromiseObject
   *
   * @public
   * @property lastSuccessfulAttempt
   * @type {PromiseObject}
   */
		lastSuccessfulAttempt: Ember.computed('organizationIntegration.lastAttemptId', function () {
			var lastAttemptId = this.get('organizationIntegration.lastAttemptId');

			return PromiseObject.create({
				promise: Ember.isNone(lastAttemptId) ? Ember.RSVP.resolve() : this.getLastSuccessfulAttempt()
			});
		}),

		/**
   * Provide the total number of errors that have occurred for this integration.
   * The result of the PromiseObject is an object, so the number must be accessed via `errorsTotal.count`
   *
   * @public
   * @property errorsTotal
   * @type {PromiseObject}
   */
		errorsTotal: Ember.computed('organizationIntegration.lastAttemptId', function () {
			this.get('organizationIntegration.lastAttemptId');

			return PromiseObject.create({
				promise: this.getNumErrorsSinceTimestamp(0) // '0' to get all errors
			});
		}),

		/**
   * Provide the total number of errors that have occurred for this integration since the last time a sync was successful.
   * The result of the PromiseObject is an object, so the number must be accessed via `errorsSinceSuccess.count`
   *
   * @public
   * @property errorsSinceSuccess
   * @type {PromiseObject}
   */
		errorsSinceSuccess: Ember.computed('organizationIntegration.lastAttemptId', 'lastSuccessfulAttempt.{isFulfilled,startTime}', function () {
			// if there hasn't yet been a sync, or we don't yet have the state of the sync, return 0
			if (Ember.isNone('organizationIntegration.lastAttemptId') || !this.get('lastSuccessfulAttempt.isFulfilled')) {
				return PromiseObject.create({
					promise: Ember.RSVP.resolve(Ember.Object.create({ count: 0 }))
				});
			}

			var startTime = this.get('lastSuccessfulAttempt.startTime');

			// if there's never been a successful sync, return the total number of errors
			return PromiseObject.create({
				promise: Ember.isNone(startTime) ? this.get('errorsTotal') : this.getNumErrorsSinceTimestamp(startTime)
			});
		}),

		/**
   * Component Lifecycle
   */

		/**
   * @private
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();

			(true && !(!Ember.isNone(this.get('integrationName'))) && Ember.assert('integration-dashboard :: \'integrationName\' is a required property', !Ember.isNone(this.get('integrationName'))));


			return this.initData();
			//.then(() => {
			//	return this.setRecentIntegrationAttemptErrors();
			//});
		},


		/**
   * @private
   * @method willDestroyElement
   */
		willDestroyElement: function willDestroyElement() {
			this.cancelTimers();
		},


		/**
   *	data management for organizationIntegration and lastAttempt
   */

		/**
   * Set the `organization-integration` and the latest `integration-attempt` . Toggle `isLoading` on and off.
   *
   * @private
   * @method initData
   * @return {Models.OrganizationIntegration}
   */
		initData: function initData() {
			var _this = this;

			this.set('isLoading', true);

			return this.setOrganizationIntegration().then(function (organizationIntegration) {
				if (!Ember.isNone(organizationIntegration) && organizationIntegration.get('enabled')) {
					_this.setLastAttempt().finally(function () {
						return _this.setLastAttemptForever();
					});
				} else {
					_this.set('lastAttempt', null);
				}

				return organizationIntegration;
			}).finally(function () {
				_this.set('isLoading', false);

				// return this.setOrganizationIntegrationForever();
			});
		},


		/**
   * Re-Initialize the component. Clear timers and re-call `initData()`
   *
   * @private
   * @method reInit
   * @return {Models.OrganizationIntegration}
   */
		reInit: function reInit() {
			this.cancelTimers();

			return this.initData();
		},


		/**
   * Set's `organization-integration` for the current `organization` and `integrationName` on `organizationIntegration`
   *
   * @private
   * @method setOrganizationIntegration
   * @return {Models.OrganizationIntegration}
   */
		setOrganizationIntegration: function setOrganizationIntegration() {
			var _this2 = this;

			return this.getOrganizationIntegration().then(function (organizationIntegration) {
				if (!_this2.get('isDestroyed')) {
					_this2.set('organizationIntegration', organizationIntegration);
				}

				return organizationIntegration;
			});
		},


		/**
   * @private
   * @method getOrganizationIntegration
   * @return {Models.OrganizationIntegration}
   */
		getOrganizationIntegration: function getOrganizationIntegration() {
			var _this3 = this;

			var organization = this.get('organization');
			var integrationName = this.get('integrationName');

			this.set('isLoadingOrganizationIntegration', true);
			return organization.getIntegration(integrationName).finally(function () {
				if (!_this3.get('isDestroyed')) {
					_this3.set('isLoadingOrganizationIntegration', false);
				}
			});
		},


		/**
   * Set's the latest `integration-attempt` for the current `organizationIntegration` on `lastAttempt`
   * Returns a `null` promise if the `organizationIntegration` has no attempts.
   *
   * @private
   * @method setLastAttempt
   * @return {Models.IntegrationAttempt}
   */
		setLastAttempt: function setLastAttempt() {
			var _this4 = this;

			return this.setOrganizationIntegration().then(function (organizationIntegration) {
				if (!_this4.get('isDestroyed') && !_this4.get('isDestroying')) {
					return Ember.RSVP.resolve(null);
				} else if (Ember.isNone(organizationIntegration)) {
					// || isNone(organizationIntegration.get('lastAttemptId'))) {
					return Ember.RSVP.resolve(null);
				}

				return _this4.getLastAttempt(organizationIntegration).then(function (lastAttempt) {
					if (!_this4.get('isDestroyed') && !_this4.get('isDestroying')) {
						_this4.set('lastAttempt', lastAttempt);
					}
				});
			});
		},
		setRecentIntegrationAttemptErrors: function setRecentIntegrationAttemptErrors() {
			var _this5 = this;

			var ninetyDaysAgo = _utils.Time.date().add(-90, 'days').unix();

			var query = {
				organization_integration_id: this.get('organizationIntegration.id'),
				success: false,
				_gte: { start_time: ninetyDaysAgo },
				page_size: 999999
			};

			return this.get('store').query('integration-attempt', query).then(function (recentIntegrationAttemptErrors) {
				if (!_this5.get('isDestroyed')) {
					_this5.set('recentIntegrationAttemptErrors', recentIntegrationAttemptErrors);
				}

				return recentIntegrationAttemptErrors;
			});
		},


		/**
   * @private
   * @method getLastAttempt
   * @return {Models.IntegrationAttempt}
   */
		getLastAttempt: function getLastAttempt(organizationIntegration) {
			var _this6 = this;

			this.set('isLoadingLastAttempt', true);

			return organizationIntegration.getLastAttempt().finally(function () {
				if (!_this6.get('isDestroyed')) {
					_this6.set('isLoadingLastAttempt', false);
				}
			});
		},


		/**
   * Use `page_size` of 1 and sorting the result by `start_time` to get the most recently successful integrationAttempt
   *
   * @private
   * @method getLastSuccessfulAttempt
   * @return {Models.IntegrationAttempt}
   */
		getLastSuccessfulAttempt: function getLastSuccessfulAttempt() {
			var query = {
				organization_integration_id: this.get('organizationIntegration.id'),
				success: true,
				page_size: 1,
				_desc: ['start_time']
			};

			return this.get('store').query('integration-attempt', query).then(function (result) {
				return !Ember.isNone(result) ? result.get('firstObject') : null;
			});
		},


		/**
   * Since all this method wants is the total number of matching results, the result set itself is ignored.
   * The query uses a `page_size` of 1 and relies on the provided meta data instead of retrieving all the matching records.
   *
   * `meta.totalRows` is the desired data
   *
   * @private
   * @method getNumErrorsSinceTimestamp
   * @return {Number}
   */
		getNumErrorsSinceTimestamp: function getNumErrorsSinceTimestamp(startTime) {
			var query = {
				organization_integration_id: this.get('organizationIntegration.id'),
				success: false,
				_gt: { start_time: startTime },
				page_size: 1
			};

			return this.get('store').query('integration-attempt', query).then(function (errorAttempt) {
				var numErrors = Ember.isEmpty(errorAttempt) ? 0 : errorAttempt.get('meta.totalRows');

				return Ember.Object.create({ count: numErrors });
			});
		},


		/**
   * Create's a randomly generated timeout which calls `setLastAttempt()` and then itself recursively
   *
   * @private
   * @method setLastAttemptForever
   */
		setLastAttemptForever: function setLastAttemptForever() {
			var _this7 = this;

			var refreshTimeout = this.getRandomRefreshTimeout();

			this.addTimeout('setLastAttempt', function () {
				return _this7.setLastAttempt().finally(function () {
					return _this7.setLastAttemptForever();
				});
			}, refreshTimeout);
		},


		/*
   *	Actions Support
   */

		/**
   * Gets the grantUrl from the RPC then opens a new window to that location
   *
   * @private
   * @method connectIntegration
   */
		connectIntegration: function connectIntegration() {
			var _this8 = this;

			_logger.default.info(this, 'connectIntegration');

			this.set('isLoading', true);

			if (this.get('organizationIntegration.enabled')) {
				_logger.default.warn('IntegrationDashboard', 'connectIntegration', 'already connected');
				return this.refresh();
			}

			return this.rpcGrantUrl().then(function (results) {
				_logger.default.info(_this8, 'connectIntegration', 'grantUrl', { results: results });

				if (results && results.data && results.data.grant_url) {
					var oauthUrl = results.data.grant_url;

					_logger.default.info(_this8, 'connectIntegration', 'grantUrl', { oauthUrl: oauthUrl });

					return window.location = oauthUrl;
				} else {
					return results;
				}
			}).catch(function (err) {
				return _this8.rpcErrorHandler(err);
			}).finally(function () {
				return _this8.set('isLoading', false);
			});
		},


		/**
   * Sends a disconnect request to the RPC then re-initializes the component
   *
   * @private
   * @method disconnectIntegration
   * @return {Models.OrganizationIntegration}
   */
		disconnectIntegration: function disconnectIntegration() {
			var _this9 = this;

			this.set('isLoading', true);

			return this.rpcDisconnect().then(function () {
				return _this9.reInit();
			}).catch(function (err) {
				return _this9.rpcErrorHandler(err);
			}).finally(function () {
				return _this9.set('isLoading', false);
			});
		},


		/**
   * Sends a disconnect request to the RPC then re-initializes the component
   *
   * @private
   * @method startManualImport
   * @return {Models.OrganizationIntegration}
   */
		startManualImport: function startManualImport() {
			var _this10 = this;

			var customErrorHandlers = {
				// if the server says there's an import in progress, don't bother with an error, just refresh
				409: function _() {
					return _this10.refresh();
				}
			};

			this.set('isLoading', true);

			return this.rpcTriggerImport().then(function () {
				return _this10.reInit();
			}).catch(function (err) {
				return _this10.rpcErrorHandler(err, customErrorHandlers);
			}).finally(function () {
				return _this10.set('isLoading', false);
			});
		},


		/*
   *	Integration Service RPC Wrappers
   */

		/**
   * Calls `integration.rpcGrantUrl()` with the current `integrationName`
   *
   * @private
   * @method rpcGrantUrl
   * @return {string}
   */
		rpcGrantUrl: function rpcGrantUrl() {
			return this.get('integration').rpcGrantUrl(this.get('integrationName'));
		},


		/**
   * Calls `integration.rpcDisconnect()` with the current `integrationName`
   *
   * @private
   * @method rpcDisconnect
   * @return {string}
   */
		rpcDisconnect: function rpcDisconnect() {
			return this.get('integration').rpcDisconnect(this.get('integrationName'));
		},


		/**
   * Calls `integration.rpcTriggerImport()` with the current `integrationName`
   *
   * @private
   * @method rpcTriggerImport
   * @return {string}
   */
		rpcTriggerImport: function rpcTriggerImport() {
			return this.get('integration').rpcTriggerImport(this.get('integrationName'));
		},


		/**
   * Calls `integration.rpcErrorHandler()` with the current `integrationName` and sets the results to `errorType` and `errorMessage`
   *
   * @private
   * @method rpcErrorHandler
   * @param err {EmberObject}
   * @param err.status {number}
   * @param err.errorType {string}
   * @param err.errorMessage {string}
   * @param [customHandlers={}] {object}
   * @return {string}
   */
		rpcErrorHandler: function rpcErrorHandler(err) {
			var customHandlers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			(true && !(!Ember.isNone(err)) && Ember.assert('rpcErrorHandler :: \'err\' is a required argument', !Ember.isNone(err)));
			(true && !((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') && Ember.assert('rpcErrorHandler :: \'err\' must be an object', (typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object'));


			var error = this.get('integration').rpcErrorHandler(err, customHandlers);

			this.set('errorType', error.get('errorType'));
			this.set('errorMessage', error.get('errorMessage'));
		},


		/*
   *	Timers
   */

		/**
   * Call the given `cb` function after the specified `timeout`. Reference is stored in the `_timers` object, using the `key`, so it can be cancelled.
   *
   * @private
   * @method addTimeout
   * @param key {string} - Hash Key to reference the timer in the `_timers` object
   * @param cb {function} - The function to call after the `timeout` has elapsed
   * @param timeout {number} - How long to wait before invoking the `cb`
   * @return {EmberObject} - Reference to the `_timers` object
   */
		addTimeout: function addTimeout(key, cb, timeout) {
			(true && !(!Ember.isNone(key)) && Ember.assert('addTimeout :: \'key\' is a required argument', !Ember.isNone(key)));
			(true && !(!Ember.isNone(cb)) && Ember.assert('addTimeout :: \'cb\' is a required argument', !Ember.isNone(cb)));
			(true && !(!Ember.isNone(timeout)) && Ember.assert('addTimeout :: \'timeout\' is a required argument', !Ember.isNone(timeout)));
			(true && !(typeof cb === 'function') && Ember.assert('addTimeout :: \'cb\' must be a function', typeof cb === 'function'));
			(true && !(Number.isInteger(timeout)) && Ember.assert('addTimeout :: \'timeout\' must be an integer', Number.isInteger(timeout)));


			var timer = Ember.run.later(this, cb, timeout);

			return this.get('_timers').set(key, timer);
		},


		/**
   * @private
   * @method getTimer
   * @param key {string} - Hash Key to reference the timer in the `_timers` object
   * @return {EmberObject} - The timer object
   */
		getTimer: function getTimer(key) {
			(true && !(!Ember.isNone(key)) && Ember.assert('getTimer :: \'key\' is a required argument', !Ember.isNone(key)));


			return this.get('_timers').get(key);
		},


		/**
   * Cancel the specific timer
   *
  	 * @private
   * @method cancelTimer
   * @param key {string} - Hash Key to reference the timer in the `_timers` object
   */
		cancelTimer: function cancelTimer(key) {
			(true && !(!Ember.isNone(key)) && Ember.assert('cancelTimer :: \'key\' is a required argument', !Ember.isNone(key)));


			return Ember.run.cancel(this.getTimer(key));
		},


		/**
   * Cancel all timers
   *
  	 * @private
   * @method cancelTimers
   */
		cancelTimers: function cancelTimers() {
			var _this11 = this;

			var timers = this.get('_timers');

			Object.keys(timers).forEach(function (key) {
				_this11.cancelTimer(key);
			});
		},


		/**
   *	Util
   */

		/**
   * Using `parentTarget.router`, refresh the route
   *
  	 * @private
   * @method refresh
   * @return {Transition}
   */
		refresh: function refresh() {
			return this.get('parentTarget.router').refresh();
		},


		/**
   * Using `route:application`, transitionTo the requested route
   *
  	 * @private
   * @method transitionTo
   * @param route {string}
   * @param [queryParams={}] {object}
   * @return {Transition}
   */
		transitionTo: function transitionTo(route) {
			var queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			(true && !(!Ember.isNone(route)) && Ember.assert('transitionTo :: \'route\' is a required argument', !Ember.isNone(route)));


			return Ember.getOwner(this).lookup('route:application').transitionTo(route, { queryParams: queryParams });
		},


		/**
   * Get the random int between the min/max timeouts depending on if there's an import in progress
   *
  	 * @private
   * @method getRandomRefreshTimeout
   * @return {number}
   */
		getRandomRefreshTimeout: function getRandomRefreshTimeout() {
			var refreshTimeout = this.get('isImportInProgress') ? this.randRange(this.get('_refreshTimeoutMinInProgress'), this.get('_refreshTimeoutMaxInProgress')) : this.randRange(this.get('_refreshTimeoutMin'), this.get('_refreshTimeoutMax'));

			return refreshTimeout;
		},


		/**
   * Get a number between min/max and optionally apply a transform function on the result
   *
  	 * @private
   * @method randRange
   * @param min {number}
   * @param max {number}
   * @param [transform=Math.round] {function}
   * @return {number}
   */
		randRange: function randRange(min, max) {
			var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Math.round;
			(true && !(!Ember.isNone(min)) && Ember.assert('randRange :: \'min\' is a required argument', !Ember.isNone(min)));
			(true && !(!Ember.isNone(max)) && Ember.assert('randRange :: \'max\' is a required argument', !Ember.isNone(max)));


			if (!Ember.isNone(transform)) {
				(true && !(typeof transform === 'function') && Ember.assert('randRange :: \'transform\' must be a function', typeof transform === 'function'));
			}

			var diff = max - min;
			var result = Math.random() * diff + min;

			return transform ? transform(result) : result;
		},


		/*
   * Actions
   */

		actions: {

			/**
    * Do nothing
    *
    * @event noop
    */
			noop: function noop() {
				//Logger.info(this, 'noop');
			},


			/**
    * @event startManualImport
    */
			startManualImport: function startManualImport() {
				//Logger.info(this, 'startManualImport');

				return this.startManualImport();
			},


			/**
    * @event connectIntegration
    */
			connectIntegration: function connectIntegration() {
				//Logger.info(this, 'connectIntegration');

				return this.connectIntegration();
			},


			/**
    * @event disconnectIntegration
    */
			disconnectIntegration: function disconnectIntegration() {
				//Logger.info(this, 'disconnectIntegration');

				return this.disconnectIntegration();
			},


			/**
    * @event openSubscriptionPage
    */
			openSubscriptionPage: function openSubscriptionPage() {
				//Logger.info(this, 'openSubscriptionPage');

				return this.transitionTo('company-settings').then(function () {
					window.location.hash = '#tab-subscription';
				});
			}
		}
	});
});