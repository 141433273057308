define('busy-app/mixins/draggable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(Ember.Evented, {
		classNames: ['busy-drag-mixin'],
		classNameBindings: ['model.isDragging:drag', 'disabled'],

		isDragging: false,

		attributeBindings: ['draggable'],

		dragContainer: null,
		draggable: true,
		disabled: false,

		model: null,

		setDragging: function setDragging(isDrag) {
			if (Ember.isNone(this.get('model'))) {
				this.set('model', Ember.Object.create({}));
			}
			this.set('model.isDragging', isDrag);
		},
		isDraggable: function isDraggable(evt) {
			var draggable = true;
			if (evt !== undefined && !Ember.isNone(this.get('dragContainer'))) {
				var clickable = this.$(this.get('dragContainer'));
				(true && !(clickable.length > 0) && Ember.assert('The dragContainer was not found in the DOM', clickable.length > 0));


				var left = clickable.position().left;
				var right = left + clickable.width();
				var click = evt.originalEvent.offsetX + Ember.$(evt.target).position().left;
				if (click < left || click > right) {
					draggable = false;
				}
			}
			return draggable;
		},


		/**
   * drag start event handler
   *
   * @public
   */
		dragStart: function dragStart(event) {
			if (this.isDraggable(event) && !this.get('disabled')) {
				this.setDragging(true);
				var dataTransfer = event.originalEvent.dataTransfer;
				dataTransfer.setData('Text', this.get('elementId'));
				this.trigger('dragStartEvent', event);
			} else {
				event.preventDefault();
				return false;
			}
		},


		/**
   * drag end event handler
   *
   * @public
   */
		dragEnd: function dragEnd(event) {
			this.setDragging(false);
			this.trigger('dragEndEvent', event);
			if (this.get('disabled')) {
				event.preventDefault();
				return false;
			}
		}
	});
});