define('busy-app/utils/array-filter', ['exports', 'busy-app/utils/compare', 'busy-app/utils/logger'], function (exports, _compare, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.indexOf = indexOf;
	exports.hasInArray = hasInArray;
	exports.filter = filter;
	exports.filterBy = filterBy;
	exports.difference = difference;
	exports.intersection = intersection;
	exports.union = union;
	exports.filterByQuery = filterByQuery;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function indexOf(array, value) {
		(true && !(Ember.isArray(array)) && Ember.assert("indexOf(array, value) requires an array as parameter 1. You passed " + (typeof array === 'undefined' ? 'undefined' : _typeof(array)), Ember.isArray(array)));

		var idx = -1;
		for (var i = 0; i < array.length; i++) {
			if ((0, _compare.default)(array[i], value)) {
				idx = i;
				break;
			}
		}
		return idx;
	}

	function hasInArray(array, value) {
		return indexOf(array, value) !== -1;
	}

	function filter(array, callback) {
		(true && !(Ember.isArray(array)) && Ember.assert("filter(array, callback) requires an array as parameter 1. You passed " + (typeof array === 'undefined' ? 'undefined' : _typeof(array)), Ember.isArray(array)));
		(true && !(typeof callback === 'function') && Ember.assert("filter(array, callback) requires a function as parameter 2. You passed " + (typeof callback === 'undefined' ? 'undefined' : _typeof(callback)), typeof callback === 'function'));


		return array.filter(callback);
	}

	function filterBy(array, key, value) {
		var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

		return filter(array, function (i) {
			return (0, _compare.default)(Ember.get(i, key), value, options);
		});
	}

	/**
  * difference of sets
  * A{ 1, 2, 3 } - B{ 3, 5 } = C{ 1, 2 }
  *
  * @public
  * @method difference
  * @params a {array}
  * @params b {array}
  * @return {array}
  */
	function difference(a, b) {
		return filter(a, function (i) {
			return !hasInArray(b, i);
		});
	}

	/**
  * intersection of sets
  * A{ 1, 2 } intersectof B{ 2, 3 } = { 2 }
  *
  * @public
  * @method intersection
  * @params a {array}
  * @params b {array}
  * @return {array}
  */
	function intersection(a, b) {
		return filter(a, function (i) {
			return hasInArray(b, i);
		});
	}

	/**
  * union of sets
  * A{ 1, 2 } union B{ 2, 3 } = { 1, 2, 3 }
  *
  * @public
  * @method union
  * @params a {array}
  * @params b {array}
  * @return {array}
  */
	function union(a, b) {
		var diff = difference(a, b);
		return diff.concat(b);
	}

	function eachProperty(value, callback) {
		var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		Object.keys(value).forEach(function (k) {
			var val = Ember.get(value, k);
			callback.call(target, val, k);
		});
	}

	var specialKeys = ['_in', '_not_in', '_not_equal', '_equal', '_desc', '_asc', '_lte', '_gte', '_lt', '_gt', 'page', 'page_size'];

	function filterByQuery(models, query) {
		var _this = this;

		var ret = models;
		var sortBy = void 0;
		var sortDir = void 0;
		eachProperty(query, function (value, key) {
			if (key === '_in') {
				eachProperty(value, function (val, k) {
					var keep = intersection(ret.mapBy(Ember.String.camelize(k)), val);
					ret = filter(ret, function (i) {
						return keep.indexOf(Ember.get(i, Ember.String.camelize(k))) !== -1;
					});
				});
			} else if (key === '_not_in') {
				eachProperty(value, function (val, k) {
					var keep = difference(ret.mapBy(Ember.String.camelize(k)), val);
					ret = filter(ret, function (i) {
						return keep.indexOf(Ember.get(i, Ember.String.camelize(k))) !== -1;
					});
				});
			} else if (key === '_not_equal') {
				eachProperty(value, function (val, k) {
					var filterOut = filterBy(ret, Ember.String.camelize(k), val, { disableCase: true });
					ret = difference(ret, filterOut);
				});
			} else if (key === '_equal') {
				eachProperty(value, function (val, k) {
					ret = filterBy(ret, Ember.String.camelize(k), val, { disableCase: true });
				});
			} else if (key === '_asc' || key === '_desc') {
				sortDir = key === '_desc';
				sortBy = value.map(function (k) {
					return Ember.String.camelize(k);
				});
			} else if (specialKeys.indexOf(key) === -1) {
				if (value === '!null') {
					ret = filterBy(ret, Ember.String.camelize(key), null);
				} else {
					ret = filterBy(ret, Ember.String.camelize(key), value, { disableCase: true });
				}
			} else {
				_logger.Logger.warn(_this, 'key not supported yet [ ' + key + ' ]');
			}
		});

		if (sortBy) {
			var _ret;

			ret = (_ret = ret).sortBy.apply(_ret, _toConsumableArray(sortBy));

			if (sortDir) {
				ret = ret.reverse();
			}
		}

		return ret;
	}
});