define('busy-app/models/wage-history', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		wage: _emberData.default.attr('number', { defaultValue: 0 }),
		wageRate: _emberData.default.attr('number', { defaultValue: 10 }),
		changeDate: _emberData.default.attr('number'),
		overburden: _emberData.default.attr('number', { defaultValue: 0 }),
		effectiveRate: _emberData.default.attr('number'), //, {defaultValue: 0}),
		workWeek: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		member: _emberData.default.belongsTo('member', { inverse: 'wageHistory' }),

		isLocked: Ember.computed('member.lockDate', function () {
			return this.get('changeDate') <= this.get('member.lockDate');
		}),

		isDefault: Ember.computed('changeDate', function () {
			if (this.get('changeDate') === 345600) {
				return true;
			}
			return false;
		}),

		hourlyRate: Ember.computed('wageRate', 'wage', 'workWeek', function () {
			var hours = parseInt(this.get('workWeek'), 10) || 40;
			var rate = parseInt(this.get('wageRate'), 10) || 10;
			var wage = parseFloat(this.get('wage')) || 0;
			if (rate === 30) {
				wage = wage / hours;
			} else if (rate === 40) {
				wage = wage * 12 / 52 / hours;
			} else if (rate === 50) {
				wage = wage / 52 / hours;
			}
			return wage.toFixed(2);
		}),

		isHourly: Ember.computed.equal('wageRate', 10),
		isSalary: Ember.computed.not('isHourly'),

		hourlyCost: Ember.computed('hourlyRate', 'overburden', function () {
			var wage = parseFloat(this.get('hourlyRate')) || 0;
			var bdn = parseFloat(this.get('overburden')) || 0;
			var burdenpct = bdn / 100;
			var overburden = burdenpct * wage;
			return wage + overburden;
		}),

		wageRateTitle: Ember.computed('wageRate', function () {
			var rate = this.get('wageRate');
			if (rate === 10) {
				return (0, _utils.loc)('Hourly');
			} else if (rate === 30) {
				return (0, _utils.loc)('Weekly');
			} else if (rate === 40) {
				return (0, _utils.loc)('Monthly');
			} else if (rate === 50) {
				return (0, _utils.loc)('Yearly');
			} else {
				(true && Ember.warn('WageRate was set to an unknown type'));

				return '';
			}
		}),

		wageRatePer: Ember.computed('wageRate', function () {
			var rate = this.get('wageRate');
			if (rate === 10) {
				return (0, _utils.loc)('/hr');
			} else if (rate === 30) {
				return (0, _utils.loc)('/week');
			} else if (rate === 40) {
				return (0, _utils.loc)('/month');
			} else if (rate === 50) {
				return (0, _utils.loc)('/year');
			} else {
				(true && Ember.warn('WageRate was set to an unknown type'));

				return '';
			}
		}),

		formattedDate: Ember.computed('changeDate', function () {
			var date = '';
			if (!Ember.isNone(this.get('changeDate'))) {
				// hides the Jan 7 1970 and shows
				// beginning
				if (this.get('changeDate') === 345600) {
					date = (0, _utils.loc)('Beginning');
				} else {
					date = _utils.Time.timeFormat(this.get('changeDate'), 'll');
				}
			}
			return date;
		}),

		createdDate: Ember.computed('createdOn', function () {
			var date = '';
			if (!Ember.isNone(this.get('createdOn'))) {
				date = _utils.Time.timeFormat(this.get('createdOn'), 'll');
			}
			return date;
		}),

		wageAmountString: Ember.computed('wage', function () {
			return this.get('wage').toFixed(2);
		}),

		wageAmountPerRateString: Ember.computed('wageAmountString', 'wageRatePer', function () {
			return this.get('wageAmountString') + this.get('wageRatePer');
		}),

		wageString: Ember.computed('wage', 'wageRate', function () {
			var wageRate = this.get('wageRate');
			var wageString = this.get('wage').toFixed(2);
			if (!Ember.isNone(this.get('wage'))) {
				if (wageRate === 10) {
					wageString = wageString + (0, _utils.loc)('/hour');
				} else if (wageRate === 20) {
					wageString = wageString + (0, _utils.loc)('/week');
				} else if (wageRate === 30) {
					wageString = wageString + ' ' + (0, _utils.loc)('Semimonthly');
				} else if (wageRate === 40) {
					wageString = wageString + ' ' + (0, _utils.loc)('Monthly');
				}
			}
			return wageString;
		}),

		overburdenString: Ember.computed('overburden', function () {
			return this.get('overburden') + '%';
		}),

		annualSalary: Ember.computed('wage,wageRate,isHourly', function () {
			var _getProperties = this.getProperties(['wage', 'wageRate', 'isHourly']),
			    wage = _getProperties.wage,
			    wageRate = _getProperties.wageRate,
			    isHourly = _getProperties.isHourly;

			if (isHourly || Ember.isNone(wage) || Ember.isNone(wageRate)) {
				return 0;
			} else if (wageRate === 30) {
				return wage * 52; // convert weekly salary to yearly
			} else if (wageRate === 40) {
				return wage * 12; // convert monthly salary to yearly
			} else if (wageRate === 50) {
				return wage; // return yearly as is
			} else {
				throw Error('Invalid wageRate');
			}
		}),

		getPayPeriodSalary: function getPayPeriodSalary(payPeriodType) {
			var _getProperties2 = this.getProperties(['annualSalary', 'isHourly']),
			    annualSalary = _getProperties2.annualSalary,
			    isHourly = _getProperties2.isHourly;

			if (isHourly || Ember.isNone(payPeriodType) || Ember.isNone(annualSalary)) {
				return 0;
			} else if (payPeriodType === 10) {
				return annualSalary / 52; // calculate weekly salary from yearly
			} else if (payPeriodType === 20) {
				return annualSalary / 26; // calculate bi weekly salary from yearly
			} else if (payPeriodType === 30) {
				return annualSalary / 24; // calculate bi monthly salary from yearly
			} else if (payPeriodType === 40) {
				return annualSalary / 12; // calculate monthly salary from yearly
			} else {
				throw Error('Invalid payPeriodType');
			}
		}
	});
});