define('busy-app/utils/models/managers/hours-and-cost', ['exports', 'busy-app/utils/models/managers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,
		memberId: null,
		projectId: null,
		costCodeId: null,

		sortWithType: 'member',

		getSorted: function getSorted() {
			return this.get('model').sortBy('start').reverse();
		},


		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		fetch: function fetch(start, end, memberId, projectId, costCodeId, sortBy) {
			(true && !(Ember.typeOf(sortBy) === 'number') && Ember.assert('You must pass a sortBy type number: [ 10{member} | 20{project} | 30{costcode} ]', Ember.typeOf(sortBy) === 'number'));


			var _this = this;
			this.set('start', start);
			this.set('end', end);
			this.set('memberId', memberId);
			this.set('projectId', projectId);
			this.set('costCodeId', costCodeId);
			this.set('sortWithType', sortBy);

			var query = {};
			if (!Ember.isNone(start) && !Ember.isNone(end)) {
				query._gte = { end_time: start };
				query._lte = { start_time: end };
			}

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			var cost, seconds;
			if (sortBy === 20) {
				cost = this.store.filter('project').findWithDirectChildren('pay-period-member-project-cost', projectId, query);
				seconds = this.store.filter('project').findWithDirectChildren('pay-period-member-project-seconds', projectId, query);
			} else {
				if (!Ember.isNone(projectId)) {
					query.project_id = projectId;
				}

				if (!Ember.isNone(costCodeId)) {
					query.cost_code_id = costCodeId;
					cost = this.store.findAll('pay-period-member-project-cost-code-cost', query);
					seconds = this.store.findAll('pay-period-member-project-cost-code-seconds', query);
				} else if (!Ember.isNone(projectId)) {
					cost = this.store.findAll('pay-period-member-project-cost', query);
					seconds = this.store.findAll('pay-period-member-project-seconds', query);
				} else {
					cost = this.store.findAll('pay-period-member-cost', query);
					seconds = this.store.findAll('pay-period-member-seconds', query);
				}
			}

			return Ember.RSVP.hash({ cost: cost, seconds: seconds }).then(function (data) {
				var memberIds = [];
				var projectIds = [];
				var costCodeIds = [];
				data.seconds.forEach(function (item) {
					if (sortBy === 10 && memberIds.indexOf(item.get('memberId')) === -1) {
						memberIds.push(item.get('memberId'));
					}

					if (sortBy === 20 && !Ember.isNone(item.get('projectId')) && projectIds.indexOf(item.get('projectId')) === -1) {
						projectIds.push(item.get('projectId'));
					}

					if (sortBy === 30 && !Ember.isNone(item.get('costCodeId')) && costCodeIds.indexOf(item.get('costCodeId')) === -1) {
						costCodeIds.push(item.get('costCodeId'));
					}
				});

				return Ember.RSVP.hash({
					members: _this.store.findByIds('member', memberIds),
					projects: _this.store.findByIds('project', projectIds),
					costCodes: _this.store.findByIds('cost-code', costCodeIds),
					cost: data.cost,
					seconds: data.seconds
				});
			});
		},
		buildCollections: function buildCollections(data) {
			var _this = this;
			var sortBy = 'memberId';
			if (this.get('sortWithType') === 20) {
				sortBy = 'projectId';
			} else if (this.get('sortWithType') === 30) {
				sortBy = 'costCodeId';
			}

			var ids = [];
			data.seconds.forEach(function (model) {
				if (ids.indexOf(model.get(sortBy)) === -1) {
					var member = null;
					if (_this.get('sortWithType') === 10) {
						member = data.members.findBy('id', model.get('memberId'));
					}

					var project = null;
					if (_this.get('sortWithType') === 20) {
						project = data.projects.findBy('id', model.get('projectId'));
						if (!Ember.isNone(project) && _this.get('projectId') === project.id) {
							project.set('isParent', true);
						} else {
							project.set('isParent', false);
						}
					}

					var costCode = null;
					if (_this.get('sortWithType') === 30) {
						costCode = data.costCodes.findBy('id', model.get('costCodeId'));
					}

					// get hours and cost with the sortBy param from fetch()
					var cost = data.cost.filterBy(sortBy, model.get(sortBy));
					var seconds = data.seconds.filterBy(sortBy, model.get(sortBy));

					// add models to the collection
					_this.addCollection(seconds, cost, member, project, costCode);

					ids.push(model.get(sortBy));
				}
			});

			this.set('content', this.get('content').sortBy('start'));
		},


		totalSeconds: Ember.computed('model.@each.totalSeconds', function () {
			var seconds = 0;
			if (!Ember.isEmpty(this.get('model'))) {
				this.get('model').forEach(function (item) {
					seconds += item.get('totalSeconds');
				});
			}
			return seconds;
		}),

		totalCost: Ember.computed('model.@each.totalCost', function () {
			var cost = 0;
			if (!Ember.isEmpty(this.get('model'))) {
				this.get('model').forEach(function (item) {
					cost += item.get('totalCost');
				});
			}
			return cost;
		})
	});
});