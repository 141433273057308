define('busy-app/models/organization', ['exports', '@busy-web/utils', 'ember-data', 'moment'], function (exports, _utils, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    features: Ember.inject.service('features'),
    subscription: Ember.inject.service('subscription'),

    betaTester: _emberData.default.attr('boolean'),
    disclaimer: _emberData.default.attr('string'),
    image: _emberData.default.attr('file'),
    imageUrl: _emberData.default.attr('string', { restrictSend: true }),
    imageThumbUrl: _emberData.default.attr('string', { restrictSend: true }),
    organizationName: _emberData.default.attr('string'),
    overtimeFlag: _emberData.default.attr('boolean'),
    ownedBy: _emberData.default.attr('string'),
    requireCostCode: _emberData.default.attr('boolean'),
    requireProject: _emberData.default.attr('boolean'),
    stripeCustomerId: _emberData.default.attr('string'),
    totalTimeFlag: _emberData.default.attr('number'),
    trackCostCode: _emberData.default.attr('boolean'),
    trackProject: _emberData.default.attr('boolean'),
    trackPaidBreak: _emberData.default.attr('boolean'),
    trackBudgets: _emberData.default.attr('boolean'),
    trackEquipment: _emberData.default.attr('boolean'),
    webGps: _emberData.default.attr('boolean'),
    timeRounding: _emberData.default.attr('boolean'),
    timeRoundingInterval: _emberData.default.attr('number'),
    timeRoundingType: _emberData.default.attr('number'),
    safetySignature: _emberData.default.attr('boolean'),
    safetySignatureMessage: _emberData.default.attr('string'),
    timeAccuracy: _emberData.default.attr('boolean'),
    timeAccuracyMessage: _emberData.default.attr('string'),
    defaultStartTime: _emberData.default.attr('duration-string', { defaultValue: '07:00:00' }),
    defaultEndTime: _emberData.default.attr('duration-string', { defaultValue: '16:00:00' }),
    defaultBreakDuration: _emberData.default.attr('duration-string'),
    useProjectCostCodeScoping: _emberData.default.attr('boolean', { defaultValue: false }),
    minimumAppVersion: _emberData.default.attr('string'),
    breakPolicy: _emberData.default.attr('boolean'),
    breakPolicyCalifornia: _emberData.default.attr('boolean'),
    breakPolicyMessage: _emberData.default.attr('string'),
    busySignInQuestion: _emberData.default.attr('boolean'),

    signatureDate: _emberData.default.attr('number'), // Enable Digital Signatures
    lockOnSelfSignature: _emberData.default.attr('boolean'), // Lock Time Card on Approval
    authoritativeSignature: _emberData.default.attr('boolean', { defaultValue: false }), // Enable Supervisor Signatures

    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    revokedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    organizationInfo: null, //DS.belongsTo('organization-info'),
    projects: _emberData.default.hasMany('project'),
    costCodes: _emberData.default.hasMany('cost-code'),
    costCodeGroups: _emberData.default.hasMany('cost-code-group'),

    // deprecations moved to Subscription service
    trialAccount: Ember.computed.deprecatingAlias('subscription.isTrialAccount', { id: 'organization-trialAccount', until: '99.0.0' }),

    isLinkedCostCodesEnabled: Ember.computed.and('features.projectCostCodes', '{trackCostCode,useProjectCostCodeScoping}'),

    formattedDisclaimer: Ember.computed('disclaimer', function () {
      var disclaimer = this.get('disclaimer');
      disclaimer = disclaimer.replace(/(\n|\n\r)/g, '<br />');
      return Ember.String.htmlSafe(disclaimer);
    }),

    timeRoundingIntervalMinutes: Ember.computed('timeRoundingInterval', function () {
      return this.get('timeRoundingInterval') / 60;
    }),

    setOvertimeFlag: Ember.observer('totalTimeFlag', function () {
      if (this.get('totalTimeFlag') > 0) {
        this.set('overtimeFlag', true);
      } else {
        this.set('overtimeFlag', false);
      }
    }),

    createdOnDate: Ember.computed('createdOn', function () {
      return _utils.Time.timeFormat(this.get('createdOn'), 'MM/DD/YYYY');
    }),

    getIntegration: function getIntegration(integrationName) {
      (true && !(integrationName) && Ember.assert('organization :: getIntegration :: \'integrationName\' is a required parameter', integrationName));

      return this.store.queryRecord('organization-integration', { integration_name: integrationName });
    },
    getIntegrationStatus: function getIntegrationStatus(integrationName) {
      (true && !(integrationName) && Ember.assert('organization :: getIntegrationStatus :: \'integrationName\' is a required parameter', integrationName));


      var status = Ember.Object.create({
        errorType: null,
        errorMessage: null,
        isConnected: false,
        progress: null,
        isInProgress: false
      });

      //  not subscribed
      if (!this.get('subscription.subscriptionStatus.isProAccount')) {
        return Ember.RSVP.Promise.resolve(status);
      }

      return this.getIntegration(integrationName).then(function (organizationIntegration) {
        // not connected
        if (Ember.isNone(organizationIntegration) || !organizationIntegration.get('enabled')) {
          return status;
        }
        status.set('isConnected', true);
        return organizationIntegration.getLastAttempt().then(function (integrationAttempt) {
          if (!Ember.isNone(integrationAttempt)) {
            var isInProgress = Ember.isNone(integrationAttempt.get('endTime')) && !Ember.isNone(integrationAttempt.get('progress'));
            status.set('endTime', integrationAttempt.get('endTime'));
            status.set('progress', integrationAttempt.get('progress'));
            status.set('isInProgress', isInProgress);
            if (!integrationAttempt.get('success')) {
              status.set('errorType', integrationAttempt.get('errorType'));
              status.set('errorMessage', integrationAttempt.get('errorMessage'));
            }
          }
          return status;
        });
      });
    },


    isSignaturesEnabled: Ember.computed.bool('signatureDate'),

    signatureDateTime: Ember.computed('signatureDate', function () {
      return this.get('isSignaturesEnabled') ? _utils.Time.date(this.get('signatureDate')) : null;
    }),

    breakDurationString: Ember.computed('breakTimeHours', 'breakTimeMinutes', function () {
      var _getProperties = this.getProperties('breakTimeHours', 'breakTimeMinutes'),
          breakTimeHours = _getProperties.breakTimeHours,
          breakTimeMinutes = _getProperties.breakTimeMinutes;

      return padTime(breakTimeHours) + ' ' + (0, _utils.loc)('hr') + ' ' + padTime(breakTimeMinutes) + ' ' + (0, _utils.loc)('min');
    }),

    startTimeString: Ember.computed('startTimeHours', 'startTimeMinutes', function () {
      var hour = this.get('startTimeHours');
      var meridian = 'AM';
      if (hour > 12) {
        meridian = 'PM';
        hour = hour - 12;
      }
      var minute = this.get('startTimeMinutes');
      return padTime(hour) + ':' + padTime(minute) + ' ' + meridian;
    }),

    endTimeString: Ember.computed('endTimeHours', 'endTimeMinutes', function () {
      var hour = this.get('endTimeHours');
      var meridian = 'AM';
      if (hour > 12) {
        meridian = 'PM';
        hour = hour - 12;
      }
      var minute = this.get('endTimeMinutes');
      return padTime(hour) + ':' + padTime(minute) + ' ' + meridian;
    }),

    startTimeHours: Ember.computed('defaultStartTime', function () {
      var remaining = this.get('defaultStartTime');
      return Math.floor(remaining / 3600);
    }),

    startTimeMinutes: Ember.computed('defaultStartTime', function () {
      var remaining = this.get('defaultStartTime');
      return Math.floor(remaining % 3600 / 60);
    }),

    endTimeHours: Ember.computed('defaultEndTime', function () {
      var remaining = this.get('defaultEndTime');
      return Math.floor(remaining / 3600);
    }),

    endTimeMinutes: Ember.computed('defaultEndTime', function () {
      var remaining = this.get('defaultEndTime');
      return Math.floor(remaining % 3600 / 60);
    }),

    defaultBreakDurationDefault: _moment.default.duration('00:30:00').asSeconds(),

    defaultBreakDurationWithFallback: Ember.computed('defaultBreakDuration', function () {
      return Ember.isNone(this.get('defaultBreakDuration')) ? this.get('defaultBreakDurationDefault') : this.get('defaultBreakDuration');
    }),

    breakTimeHours: Ember.computed('defaultBreakDuration', function () {
      var defaultBreakDuration = this.get('defaultBreakDurationWithFallback');
      var seconds = defaultBreakDuration;
      return Math.floor(seconds / 3600);
    }),

    breakTimeMinutes: Ember.computed('defaultBreakDuration', function () {
      var defaultBreakDuration = this.get('defaultBreakDurationWithFallback');
      var remaining = defaultBreakDuration;
      return Math.floor(remaining % 3600 / 60);
    }),

    breakDurationSeconds: Ember.computed('defaultBreakDuration', function () {
      return this.get('defaultBreakDurationWithFallback');
    }),

    primaryAccountHolder: Ember.computed('ownedBy', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('member', { id: this.get('ownedBy') }).then(function (data) {
          return data.get('firstObject');
        })
      });
    })
  });


  var padTime = function padTime(time) {
    return time < 10 ? '0' + time : '' + time;
  };
});