define('busy-app/mixins/model-loader', ['exports', 'busy-app/mixins/scroll-loader', 'busy-app/utils/settings'], function (exports, _scrollLoader, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Mixin.create(_scrollLoader.default, {
		/**
   * Data Service Class to load data
   *
   * @public
   * @property store
   * @type DS.Store
   */
		store: Ember.inject.service('store'),

		/**
   * Data retireved from the load query
   *
   * @public
   * @property model
   * @type DS.Model
   */
		model: null,

		/**
   * Public property to check if there is data
   * loadding currently.
   *
   * @public
   * @property isLoading
   * @type boolean
   */
		isLoading: false,

		/**
   * Private property to hole the last
   * queries meta data.
   *
   * @private
   * @property __meta
   * @type object
   */
		__meta: null,

		autoLoad: true,

		/**
   * Model loader method for loading models through a query.
   * In this method the meta.next param will be used for future load more queries.
   *
   * @public
   * @method load
   * @param modelType {string} The model type string to load
   * @param query {object} The query filter object
   * @param replaceData {boolean} Replaces the model property rather than adds to it. default: true
   * @returns {EmberPromise}
   */
		load: function load(modelType) {
			var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			var _this = this;

			var replaceData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
			var autoLoad = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

			// enforce params are correct types
			(true && !(Ember.typeOf(modelType) === 'string') && Ember.assert('modelType in ModelLoader.load() must be of type string', Ember.typeOf(modelType) === 'string'));
			(true && !(Ember.typeOf(query) === 'object') && Ember.assert('query in ModelLoader.load() must be of type object', Ember.typeOf(query) === 'object'));
			(true && !(Ember.typeOf(replaceData) === 'boolean') && Ember.assert('replaceData in ModelLoader.load() must be of type boolean', Ember.typeOf(replaceData) === 'boolean'));


			this.set('autoLoad', autoLoad);

			// set isLoading to true
			this.set('isLoading', true);

			// load data
			return this.get('store').query(modelType, query).then(function (model) {
				if (!_this.get('isDestroyed')) {
					// adds to the current model data or replaces the current data.
					var data = _this._addData(model, replaceData);

					// trigger afterLoad event
					_this.trigger('afterLoad', model);

					return data;
				}
			}).finally(function () {
				return _this.set('isLoading', false);
			});
		},


		/**
   * Retrieves the next set of results from the original load query results.
   *
   * @public
   * @method loadMore
   * @returns {EmberPromise}
   */
		loadMore: function loadMore() {
			// get the next url from the previous load query
			var next = this.get('__meta.next');

			// make sure there is a next
			if (!Ember.isNone(next)) {
				// parse the next url
				var args = this._parseUrlString(next);

				// make sure there is a valid model type string
				if (args.modelType) {
					// load the next results and return the promisse
					return this.load(args.modelType, args.query, false);
				}
			}

			// return a place holder promise.
			return Ember.RSVP.resolve(this.get('model') || []);
		},


		/**
   * Handles adding or replacing the model data
   *
   * @private
   * @method _addData
   * @param model {DS.RecordArray}
   * @param replaceData {boolean}
   * @returns {DS.RecordArray}
   */
		_addData: function _addData(model) {
			var _this2 = this;

			var replaceData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			if (!this.get('isDestroyed')) {
				// set the model if it has not been set
				// else add objects to the model.
				if (replaceData || Ember.isNone(this.get('model'))) {
					this.set('model', null);
					this.set('model', model);
				} else {
					model.forEach(function (item) {
						_this2.get('model').pushObject(item);
					});
				}

				this.set('__meta', model.get('meta'));
			}

			return this.get('model');
		},


		/**
   * Handles parsing the next or previous url from the busybusy api
   *
   * @private
   * @method _parseUrlString
   * @param url {string}
   * @returns {object} key:value with modelType:string, query:object
   */
		_parseUrlString: function _parseUrlString(url) {
			var query = {};

			// remove the api url
			url = url.replace(_settings.default.get('api_url') + '/', '');

			// split the model from the args

			var _url$split = url.split('?'),
			    _url$split2 = _slicedToArray(_url$split, 2),
			    modelType = _url$split2[0],
			    queryString = _url$split2[1];

			if (queryString) {
				var searchParams = new URLSearchParams(queryString);

				searchParams.forEach(function (value, key) {
					query[key] = value;
				});
			}

			// return the modelType and query.
			return { modelType: modelType, query: query };
		}
	});
});