define('busy-app/transforms/date-string', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized, meta) {
			(true && !(meta && typeof meta.format === 'string') && Ember.assert("format must be provided for DS.attr('date-string', { format: 'HH:mm:ss' })", meta && typeof meta.format === 'string'));


			var date = _moment.default.utc(serialized || meta.defaultValue, meta.format);
			var timestamp = date.unix();
			return timestamp;
		},
		serialize: function serialize(deserialized, meta) {
			(true && !(meta && typeof meta.format === 'string') && Ember.assert("format must be provided for DS.attr('date-string', { format: 'HH:mm:ss' })", meta && typeof meta.format === 'string'));


			if (!deserialized) {
				return;
			}

			return (0, _moment.default)(deserialized).format(meta.format);
		}
	});
});