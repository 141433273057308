define('busy-app/utils/models/dispatchers/member-project-pay-period', ['exports', 'busy-app/utils/models/dispatchers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		find: function find(start, end, projectId) {
			var _this = this;

			(true && !(!Ember.isNone(start)) && Ember.assert('You must provide a start {number} to member-pay-period dispatcher', !Ember.isNone(start)));
			(true && !(!Ember.isNone(end)) && Ember.assert('You must provide a end {number} to member-pay-period dispatcher', !Ember.isNone(end)));


			return Ember.RSVP.hash({
				seconds: this.store.findAll('pay-period-member-project-seconds', { start_time: start, end_time: end, project_id: projectId }),
				cost: this.store.findAll('pay-period-member-project-cost', { start_time: start, end_time: end, project_id: projectId })
			}).then(function (data) {
				var projectIds = [];
				data.seconds.forEach(function (item) {
					if (projectIds.indexOf(item.get('projectId')) === -1 && !Ember.isNone(item.get('projectId'))) {
						projectIds.push(item.get('projectId'));
					}
				});

				return Ember.RSVP.hash({
					cost: data.cost,
					seconds: data.seconds,
					projects: _this.store.findByIds('project', projectIds)
				});
			});
		}
	});
});