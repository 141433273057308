define('busy-app/utils/models/managers/time-card-report', ['exports', 'busy-app/utils/models/managers/time-entry-report'], function (exports, _timeEntryReport) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _timeEntryReport.default.extend({
		features: Ember.inject.service('features'),

		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		fetch: function fetch(start, end, memberId, projectId, costCodeId, equipmentId) {
			var exclusive = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
			var sortOrder = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 'desc';
			(true && !(sortOrder === 'desc' || sortOrder === 'asc') && Ember.assert('sortOrder must be a String [desc | asc]', sortOrder === 'desc' || sortOrder === 'asc'));


			this.set('start', start);
			this.set('end', end);
			this.set('sortOrder', sortOrder);

			return this.dispatcherFor('time-entry-segment').findAllByMemberRange(start, end, memberId, projectId, costCodeId, equipmentId, exclusive);
		}
	});
});