define('busy-app/models/time-entry', ['exports', '@busy-web/utils', 'busy-app/mixins/model-sync', 'busy-app/utils/maps', 'busy-app/utils/round-time', 'busy-app/utils/time-entry', 'busy-app/utils/time-entry-break', 'busy-app/utils/time-entry-validator', 'ember-data'], function (exports, _utils, _modelSync, _maps, _roundTime, _timeEntry, _timeEntryBreak, _timeEntryValidator, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_modelSync.default, {
    auth: Ember.inject.service('auth'),

    memberId: _emberData.default.attr('id', { sortable: true, sortBy: ['member.firstName', 'member.lastName'], reverseSortChildren: ['startTime', 'endTime'] }),
    projectId: _emberData.default.attr('id', { sortable: true, sortBy: ['project.fullTitle'], reverseSortChildren: ['startTime', 'endTime'] }),
    costCodeId: _emberData.default.attr('id', { sortable: true, sortBy: ['costCode.costCode'], reverseSortChildren: ['startTime', 'endTime'] }),
    equipmentId: _emberData.default.attr('id', { sortable: true, sortBy: ['equipment.equipmentName'], reverseSortChildren: ['startTime', 'endTime'] }),
    positionId: _emberData.default.attr('id'),
    startTime: _emberData.default.attr('date', { sortable: true, reverseSortBy: ['startTime', 'endTime'], sortChildren: ['member.firstName', 'member.lastName'], reverseSortChildren: ['startTime', 'endTime'] }),
    endTime: _emberData.default.attr('date', { sortable: true }),
    offset: _emberData.default.attr('number'),
    metaOffset: _emberData.default.attr('number'),
    daylightSavingTime: _emberData.default.attr('boolean'),
    metaDaylightSavingTime: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    startLocationId: _emberData.default.attr('id'),
    endLocationId: _emberData.default.attr('id'),
    startDeviceTime: _emberData.default.attr('number'),
    endDeviceTime: _emberData.default.attr('number'),
    startTimeTrusted: _emberData.default.attr('number'),
    endTimeTrusted: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('date'),
    updatedOn: _emberData.default.attr('date'),
    deletedOn: _emberData.default.attr('date'),
    actionType: _emberData.default.attr('number'),

    // model joins
    member: _emberData.default.belongsTo('member'),
    project: _emberData.default.belongsTo('project'),
    costCode: _emberData.default.belongsTo('cost-code'),
    equipment: _emberData.default.belongsTo('equipment'),

    startLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'startLocationId', foreignKey: 'id' }),
    endLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'endLocationId', foreignKey: 'id' }),

    /**
     * returns the open entry break if the time entry is
     * currently open and on break
     *
     * @property openBreak
     * @type {DS.Model}
     */
    openBreak: null,

    isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

    timeEntryBreaks: Ember.computed('id', function () {
      var _this = this;

      var breaks = void 0;
      if (!this.get('isNew')) {
        breaks = _emberData.default.PromiseArray.create({
          promise: this.store.findAll('time-entry-break', { time_entry_id: this.get('id'), deleted_on: null, archived_on: null }).then(function (data) {
            // set open break
            var openBreak = data.find(function (b) {
              return Ember.isNone(b.get('endTime'));
            });
            _this.propertyWillChange('openBreak');
            _this.set('openBreak', openBreak);
            _this.propertyDidChange('openBreak');

            Ember.run.schedule('afterRender', function () {
              // calc break time
              _this.calculateTime();
            });

            // return result
            return data.toArray();
          })
        });
      } else {
        breaks = _emberData.default.PromiseArray.create({ promise: Ember.RSVP.resolve([]) });
      }

      breaks.reload = function () {
        _this.propertyDidChange('timeEntryBreaks');
        return Ember.RSVP.resolve(_this.get('timeEntryBreaks'));
      };

      return breaks;
    }),

    timeEntryLocations: Ember.computed('startTime', 'endTime', function () {
      var end = this.get('endTime') || _utils.Time.now().unix();

      var query = {
        _lte: { time_local: end },
        _gte: { time_local: this.get('startTime') },
        member_id: this.get('memberId'),
        deleted_on: '_-NULL-_',
        archived_on: '_-NULL-_'
      };

      return _emberData.default.PromiseArray.create({
        promise: this.store.query('member-location', query)
      });
    }),

    segments: Ember.computed('id', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.store.findAll('segment', { time_entry_id: this.get('id'), deleted_on: null })
      });
    }),

    syncModels: Ember.observer('endTime', 'updatedOn', function () {
      // segments are not available when time card has no end time
      if (!Ember.isNone(this.get('endTime'))) {
        // call update on segments to get any new segments
        this.sync('segments', !this.get('deletedOn'));
      }
    }),

    now: null,

    /**
     * Total of the time from start to end ( or now ) without break times included.
     *
     * @property regularTime
     * @type number
     */
    regularTime: 0,

    /**
     * Total regular time with total break time removed
     *
     * @property totalTime
     * @type number
     */
    totalTime: 0,

    /**
     * Total of all break times including open break time.
     *
     * @property breakTime
     * @type number
     */
    breakTime: 0,

    /**
     * Total time for the current open break
     *
     * @property openBreakTime
     * @type number
     */
    openBreakTime: 0,

    /**
     * Total time for the open entry time or the open break time
     *
     * This will switch from timeEntry to timeEntryBreak based on the onBreak flag
     *
     * @property elapsedTime
     * @type number
     */
    elapsedTime: 0,

    timerStarted: false,
    timeTilUpdate: 60000,
    timeout: null,
    timeInterval: null,

    setTimeInterval: function setTimeInterval(time) {
      this.set('timeInterval', time);
      this.elapsedTimer();
    },


    preBreakTime: Ember.computed('openBreakTime', function () {
      //returns true if the break will start in the future (for rounding)
      if (this.get('openBreakTime') < 0) {
        return true;
      }
      return false;
    }),

    /**
     * elapsedTimer checks to see if the time entry is open
     * and calls elapsedTimeSetter every second for an open entry
     *
     * @private
     * @method elapsedTimer
     */
    elapsedTimer: function elapsedTimer() {
      var _this2 = this;

      // if the time entry is open
      // set up a time callback for 1 second from now
      if (!Ember.isNone(this.get('timeInterval')) && Ember.isNone(this.get('endTime'))) {
        var timer = this.get('timeInterval');

        // make sure the timeInterval is a valid number
        _utils.Assert.isNumber(timer);

        var seconds = this.get('elapsedTime');
        var timeLeft = Math.floor(seconds * 1000 % timer);
        timer = timer - timeLeft;

        // stop the timer from getting multiple
        // time calls at once.
        if (!this.timerStarted || this.get('timeTilUpdate') > timer) {
          Ember.run.cancel(this.get('timeout'));
          this.timerStarted = true;

          this.set('timeout', Ember.run.later(function () {
            _this2.timerStarted = false;
            _this2.calculateTime();
            _this2.elapsedTimer();
          }, timer));

          this.set('timeTilUpdate', timer);
        }
      }
    },
    calculateTime: function calculateTime() {
      if (!Ember.isNone(this.get('startTime'))) {
        var startTime = this.get('startTime');
        var endTime = this.get('endTime');
        var regularTime = this.calcRegularTime(startTime, endTime);
        var breakTime = this.calcBreakTime(startTime, endTime);
        var openBreakTime = this.calOpenBreakTime();
        var totalTime = regularTime - (breakTime + openBreakTime);

        this.set('regularTime', regularTime);
        this.set('breakTime', breakTime);
        this.set('openBreakTime', openBreakTime);
        this.set('totalTime', totalTime);

        this.propertyWillChange('elapsedTime');
        if (this.get('onBreak')) {
          this.set('elapsedTime', openBreakTime);
        } else {
          this.set('elapsedTime', totalTime);
        }
        this.propertyDidChange('elapsedTime');
      }
    },


    // variables
    calcRegularTime: function calcRegularTime(startTime, endTime) {
      _utils.Assert.funcNumArgs(arguments, 2);
      _utils.Assert.isNumber(startTime);

      var total = 0;
      if (!Ember.isNone(startTime)) {
        if (!Ember.isNone(endTime)) {
          total = endTime - startTime + 1;
        } else {
          total = this.getElapsedRunningTime(startTime);
        }
      }
      return total;
    },
    calcTotalTime: function calcTotalTime() {
      // remove the breakTime from the total
      return this.get('regularTime') - this.get('combinedBreakTime');
    },
    calcElapsedTime: function calcElapsedTime() {
      var total = 0;
      if (this.get('onBreak')) {
        total = this.get('openBreakTime');
      } else {
        total = this.get('totalTime');
      }
      return total;
    },


    /**
     * triggers a refresh of the breakTime
     * when a new break entry is added to the time entry
     *
     * @property breakTime
     * @type Number
     */
    calcBreakTime: function calcBreakTime(startTime, endTime) {
      var time = 0;
      var timeEntryBreaks = this.get('timeEntryBreaks');

      // validate all breaks to make sure they fit within this time entry bounds
      timeEntryBreaks.forEach(function (breakEntry) {
        // reset flags and revalidate breaks;
        breakEntry.resetFlags();

        // validate break time
        breakEntry.validateTime(startTime, endTime);
      });

      // validate all breaks to make sure they dont overlap other breaks
      timeEntryBreaks.forEach(function (breakEntry) {
        // make sure the break is valid
        if (!breakEntry.get('invalidEntry')) {
          timeEntryBreaks.forEach(function (breakEntry2) {
            breakEntry.adjustOverlap(breakEntry2);
          });

          time += breakEntry.get('totalTime');
        }
      });

      return time;
    },
    calOpenBreakTime: function calOpenBreakTime() {
      var total = 0;
      if (this.get('onBreak')) {
        total = this.getElapsedRunningTime(this.get('openBreak.startTime'));
      }
      return total;
    },


    isMultiDayEntry: Ember.computed('startTime', 'endTime', function () {
      if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
        if (_utils.Time.date(this.get('endTime')).date() !== _utils.Time.date(this.get('startTime')).date()) {
          return true;
        }
      }
      return false;
    }),

    dateString: Ember.computed('startTime', 'endTime', 'isMultiDayEntry', function () {
      var date = '';
      if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
        var format = 'h:mm A';
        if (this.get('isMultiDayEntry')) {
          format = 'MMM Do h:mm A';
        }
        date = _timeEntry.default.timeRangeString(this, format);
      }
      return date;
    }),

    dateStringSafe: Ember.computed('startTime', 'endTime', 'isMultiDayEntry', function () {
      var date = '';
      if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
        var format = 'h:mm A';
        if (this.get('isMultiDayEntry')) {
          format = 'MMM Do h:mm A';
        }
        date = _timeEntry.default.timeRangeString(this, format, true);
      }
      return date;
    }),

    validLocationTime: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
      if (!Ember.isNone(this.get('auth.organization'))) {
        return (0, _maps.validTimeOffset)(this.get('auth.organization'));
      }
    }),

    isStartLocationValid: Ember.computed('startLocation.createdOn', 'startLocationId', 'startTime', 'offset', 'validLocationTime', function () {
      var validLocTime = this.get('validLocationTime');
      var isValid = false;
      if (!Ember.isNone(this.get('startLocation.createdOn'))) {
        var locCreatedOn = this.get('startLocation.createdOn') + this.get('offset');
        if (locCreatedOn >= this.get('startTime') - validLocTime && locCreatedOn <= this.get('startTime') + validLocTime) {

          // disable showing (0, 0) GPS locations
          if ((0, _maps.isValidLatLng)(this.get('startLocation'))) {
            isValid = true;
          }
        }
      }
      return isValid;
    }),

    isEndLocationValid: Ember.computed('endLocation.createdOn', 'endLocationId', 'endTime', 'offset', 'validLocationTime', function () {
      var validLocTime = this.get('validLocationTime');
      var isValid = false;
      if (!Ember.isNone(this.get('endLocation.createdOn')) && !Ember.isNone(this.get('endTime'))) {
        var locCreatedOn = this.get('endLocation.createdOn') + this.get('offset');
        if (locCreatedOn >= this.get('endTime') - validLocTime && locCreatedOn <= this.get('endTime') + validLocTime) {

          // disable showing (0, 0) GPS locations
          if ((0, _maps.isValidLatLng)(this.get('endLocation'))) {
            isValid = true;
          }
        }
      }
      return isValid;
    }),

    hasLocations: Ember.computed('isStartLocationValid', 'isEndLocationValid', 'timeEntryBreaks.@each.{isStartLocationValid,isEndLocationValid}', 'timeEntryLocations', function () {
      var validBreaks = this.getWithDefault('timeEntryBreaks', []).filter(function (item) {
        return item.get('isStartLocationValid') || item.get('isEndLocationValid');
      });

      return this.get('isStartLocationValid') || this.get('isEndLocationValid') || validBreaks.length > 0 || this.get('timeEntryLocations.length') > 0;
    }),

    endTimeDay: Ember.computed('endTime', function () {
      var endTime = this.get('endTime');
      return _utils.Time.date(endTime).format('MMM Do');
    }),

    /**
     * returns the total time from start to
     * finish of the timecard not including any
     * break entries
     *
     * @public
     * @method getElapsedRunningTime
     * @returns {number} total time of the entry in seconds
     */
    getElapsedRunningTime: function getElapsedRunningTime(startTime) {
      var total = 0;
      if (!Ember.isNone(startTime)) {
        // Get the models UTC timestamp
        startTime = _utils.Time.timestamp(startTime);

        // Get the current UTC timestamp
        var currentTime = _utils.Time.nistTimestamp();
        var currentOffset = _utils.Time.timezone();

        currentTime = currentTime - currentOffset + this.get('offset');

        // return the difference
        total = currentTime - startTime;
      }
      return total;
    },
    validateBreakTime: function validateBreakTime(breakEntry) {
      _timeEntryBreak.default.validateTime(breakEntry, this.get('startTime'), this.get('endTime'));
    },


    combinedBreakTime: Ember.computed('breakTime', 'openBreakTime', function () {
      return this.get('breakTime') + this.get('openBreakTime');
    }),

    onBreak: Ember.computed('openBreak', function () {
      return !Ember.isNone(this.get('openBreak'));
    }),

    openRoundedBreak: Ember.computed('timeEntryBreaks.@each', function () {
      var currentTime = _utils.Time.nistTimestamp();
      var roundedBreak = [];
      this.get('timeEntryBreaks').forEach(function (breakEntry) {
        if (!Ember.isNone(breakEntry.get('endTime'))) {
          if (breakEntry.get('endTime') > currentTime && breakEntry.get('startTime') < currentTime) {
            roundedBreak.push(breakEntry);
          }
        }
      });
      return roundedBreak;
    }),

    /**
     * elapsedTimeString returns the current running total for
     * the timeEntry or the current running time for the open break entry when
     * the onBreak flag is true.
     *
     * @property elapsedTimeString
     * @type string
     */
    elapsedTimeSecondString: Ember.computed('elapsedTime', function () {
      return this.convertSeconds(this.get('elapsedTime'), true);
    }),

    /**
     * elapsedTimeString returns the current running total for
     * the timeEntry or the current running time for the open break entry when
     * the onBreak flag is true.
     *
     * @property elapsedTimeString
     * @type string
     */
    elapsedTimeString: Ember.computed('elapsedTime', function () {
      return this.convertSeconds(this.get('elapsedTime'));
    }),

    /**
     * regularTimeString returns the current total of the time entry
     * from start to finish or start to current with the break entries
     * not removing any time from the total.
     *
     * @property regularTimeString
     * @type string
     */
    regularTimeString: Ember.computed('regularTime', function () {
      return this.convertSeconds(this.get('regularTime'));
    }),

    /**
     * totalTimeString returns the total time of the time entry
     * from start to finish or start to current minus the break
     * times of the entry.
     *
     * @property totalTimeString
     * @type string
     */
    totalTimeString: Ember.computed('totalTime', function () {
      return this.convertSeconds(this.get('totalTime'));
    }),

    /**
     * returns a total of all the break times including the
     * current incrementing open break
     *
     * @property breakTimeString
     * @type string
     */
    breakTimeString: Ember.computed('breakTime', function () {
      return this.convertSeconds(this.get('breakTime'));
    }),

    /**
     * converts int seconds to hours:minutes(:seconds) string
     *
     * @public
     * @method convertSeconds
     * @param seconds {number} number of seconds to convert.
     * @param includeSeconds {boolean} true if the return string should include seconds `default: false`
     * @return {string}
     */
    convertSeconds: function convertSeconds(seconds) {
      var includeSeconds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      return _utils.Time.convertSecondsString(seconds, 10, true, includeSeconds);
    },


    futureTime: Ember.computed('startTime', 'totalTime', function () {
      var time = '';
      if (this.get('startTime') > _utils.Time.nistTimestamp()) {
        var start = _utils.Time.date(this.get('startTime'));
        var now = _utils.Time.date();
        var format = 'h:mm A';

        if (start.year() !== now.year()) {
          format = 'ddd, MMM Do, YYYY' + ' ' + format;
        } else if (start.month() !== now.month()) {
          format = 'ddd, MMM Do' + ' ' + format;
        } else if (start.date() - now.date() > 6) {
          format = 'ddd, Do ' + format;
        } else if (start.date() - now.date() > 0) {
          format = 'ddd ' + format;
        }
        time = _utils.Time.timeFormat(this.get('startTime'), format);
      }
      return time;
    }),

    futureBreakTime: Ember.computed('openBreak.{startTime,totalTime}', function () {
      var time = '';
      if (this.get('openBreak.startTime') > _utils.Time.nistTimestamp()) {
        var start = _utils.Time.date(this.get('openBreak.startTime'));
        var now = _utils.Time.date();
        var format = 'h:mm A';

        if (start.year() !== now.year()) {
          format = 'ddd, MMM Do, YYYY' + ' ' + format;
        } else if (start.month() !== now.month()) {
          format = 'ddd, MMM Do' + ' ' + format;
        } else if (start.date() - now.date() > 6) {
          format = 'ddd, Do ' + format;
        } else if (start.date() - now.date() > 0) {
          format = 'ddd ' + format;
        }
        time = _utils.Time.timeFormat(this.get('openBreak.startTime'), format);
      }
      return time;
    }),

    fullProjectTitle: Ember.computed('project.fullTitle', function () {
      var title = '';
      if (!Ember.isNone(this.get('project.title'))) {
        title = this.get('project.fullTitle');
      }
      return title;
    }),

    projectTitle: Ember.computed('project.title', function () {
      var title = '';
      if (!Ember.isNone(this.get('project.title'))) {
        title = this.get('project.title');
      }
      return title;
    }),

    costCodeTitle: Ember.computed('costCode.costCode', function () {
      var title = '';
      if (!Ember.isNone(this.get('costCode.costCode'))) {
        title = this.get('costCode.costCode');
      }
      return title;
    }),

    equipmentTitle: Ember.computed('equipment.equipmentName', function () {
      var title = '';
      if (!Ember.isNone(this.get('equipment.equipmentName'))) {
        title = this.get('equipment.equipmentName');
      }
      return title;
    }),

    startString: Ember.computed('startTime', function () {
      return _utils.Time.timeFormat(this.get('startTime'), 'MMMM Do YYYY, h:mm A');
    }),

    startTimeString: Ember.computed('startTime', function () {
      return _utils.Time.timeFormat(this.get('startTime'), 'h:mm A');
    }),

    startDateString: Ember.computed('startTime', function () {
      return _utils.Time.timeFormat(this.get('startTime'), 'MMMM Do, YYYY');
    }),

    endString: Ember.computed('endTime', function () {
      return _utils.Time.timeFormat(this.get('endTime'), 'MMMM Do YYYY, h:mm A');
    }),

    endTimeString: Ember.computed('endTime', function () {
      return _utils.Time.timeFormat(this.get('endTime'), 'h:mm A');
    }),

    endDateString: Ember.computed('endTime', function () {
      return _utils.Time.timeFormat(this.get('endTime'), 'MMMM Do YYYY');
    }),

    isStartOutside: Ember.computed('startTime', 'segmentStartTime', function () {
      return this.get('startTime') === this.get('segmentStartTime');
    }),

    isEndOutside: Ember.computed('endTime', 'segmentEndTime', function () {
      return this.get('endTime') === this.get('segmentEndTime');
    }),

    segmentStartTime: Ember.computed('segments.@each.startTime', function () {
      var start = this.get('segments').mapBy('startTime').reduce(function (a, b) {
        return a < b ? a : b;
      }, this.get('startTime'));
      return start;
    }),

    segmentEndTime: Ember.computed('segments.@each.endTime', function () {
      var end = this.get('segments').mapBy('endTime').reduce(function (a, b) {
        return a < b ? b : a;
      }, this.get('endTime'));
      return end;
    }),

    segmentRegularSeconds: Ember.computed('segments.@each.regularSeconds', function () {
      return this.get('segments').mapBy('regularSeconds').reduce(function (a, b) {
        return a + b;
      }, 0);
    }).meta({ aggregateType: 'sum' }),

    segmentOvertimeSeconds: Ember.computed('seconds.@each.overtimeSeconds', function () {
      return this.get('segments').mapBy('overtimeSeconds').reduce(function (a, b) {
        return a + b;
      }, 0);
    }).meta({ aggregateType: 'sum' }),

    segmentDoubletimeSeconds: Ember.computed('seconds.@each.doubletimeSeconds', function () {
      return this.get('segments').mapBy('doubletimeSeconds').reduce(function (a, b) {
        return a + b;
      }, 0);
    }).meta({ aggregateType: 'sum' }),

    segmentTotalSeconds: Ember.computed('segments.@each.{totalSeconds,updatedOn}', function () {
      return this.get('segments').mapBy('totalSeconds').reduce(function (a, b) {
        return a + b;
      }, 0);
    }).meta({ aggregateType: 'sum' }),

    segmentBreakTimeSeconds: Ember.computed('segments.@each.breakSeconds', function () {
      return this.get('segments').mapBy('breakSeconds').reduce(function (a, b) {
        return a + b;
      }, 0);
    }).meta({ aggregateType: 'sum' }),

    /**
     * Add a break to the time entry breaks list
     *
     * @public
     * @chainable
     * @method reloadBreaks
     * @return {Promise}
     */
    reloadBreaks: function reloadBreaks() {
      if (this.get('timeEntryBreaks.reload')) {
        return this.get('timeEntryBreaks').reload();
      } else {
        return this.get('timeEntryBreaks');
      }
    },
    createBreak: function createBreak(startTime, endTime) {
      var _this3 = this;

      var paidBreak = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      startTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), startTime, { isBreak: true });
      endTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), endTime, { isBreak: true });

      if (!Ember.isNone(this.get('endTime')) && endTime > this.get('endTime')) {
        endTime = this.get('endTime');
      }

      var breakTime = this.store.createRecord('time-entry-break', {
        id: _utils.UUID.generate(),
        timeEntryId: this.id,
        actionType: 50,
        paidBreak: paidBreak,
        startTime: startTime,
        endTime: endTime,
        timeEntry: this
      });

      Ember.RSVP.resolve(this.get('timeEntryBreaks')).then(function () {
        return _this3.get('timeEntryBreaks').pushObject(breakTime);
      });
      return breakTime;
    },
    startBreak: function startBreak() {
      var paidBreak = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var _this4 = this;

      var override = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var startLocation = arguments[2];

      _utils.Assert.test("You have an open break entry already!", Ember.isNone(this.get('openBreak.id')));
      _utils.Assert.test("You can't start a break on a closed time entry!", Ember.isNone(this.get('endTimeGmt')));

      this.propertyWillChange('timeEntryBreaks');
      var startTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), _utils.Time.nistTimestamp(), { isBreak: true });

      var params = {
        timeEntryId: this.id,
        actionType: 10,
        startTime: startTime,
        paidBreak: paidBreak,
        startLocation: startLocation,
        timeEntry: this
      };

      var _break = this.store.createRecord('time-entry-break', params);

      this.set('actionType', 70);
      return this.saveLog().then(function (log) {
        return _break.save(_this4, log.id, override).then(function (brk) {
          _this4.propertyDidChange('timeEntryBreaks');
          return Ember.RSVP.resolve(_this4.get('timeEntryBreaks')).then(function () {
            return brk;
          });
        });
      });
    },
    stopBreak: function stopBreak() {
      var _this5 = this;

      var override = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var endLocation = arguments[1];

      var openBreak = this.get('openBreak');
      _utils.Assert.test("You must provide a time-entry with an openBreak", !Ember.isNone(openBreak) && !Ember.isNone(openBreak.get('id')));

      this.propertyWillChange('timeEntryBreaks');

      var endTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), _utils.Time.nistTimestamp(), { isBreak: true });

      openBreak.set('actionType', 20);
      openBreak.set('endTime', endTime);

      if (!Ember.isNone(endLocation)) {
        openBreak.set('endLocation', endLocation);
      }

      this.set('actionType', 70);
      return this.saveLog().then(function (log) {
        return openBreak.save(_this5, log.id, override).then(function (brk) {
          _this5.propertyDidChange('timeEntryBreaks');
          return Ember.RSVP.resolve(_this5.get('timeEntryBreaks')).then(function () {
            return brk;
          });
        });
      });
    },
    saveBreaks: function saveBreaks(log) {
      var _this6 = this;

      if (Ember.isNone(log)) {
        this.set('actionType', 70);
        return this.saveLog().then(function (_log) {
          return _this6._saveBreaks(_log);
        });
      } else {
        return this._saveBreaks(log);
      }
    },
    _saveBreaks: function _saveBreaks(log) {
      var _this7 = this;

      this.propertyWillChange('timeEntryBreaks');
      return Ember.RSVP.resolve(this.get('timeEntryBreaks')).then(function (breaks) {
        var dirtyBreaks = breaks.filterBy('hasDirtyAttributes');
        return Ember.RSVP.allSettled(dirtyBreaks.map(function (breakEntry) {
          return Promise.resolve(breakEntry.save(_this7, log.id, true));
        })).then(function () {
          _this7.propertyDidChange('timeEntryBreaks');
          return breaks;
        });
      });
    },
    removeBreak: function removeBreak(breakEntry, isResolve) {
      this.propertyWillChange('timeEntryBreaks');
      if (!breakEntry.get('isNew')) {
        breakEntry.deleteRecord(isResolve);
      } else {
        this.get('timeEntryBreaks').removeObject(breakEntry);
        breakEntry.unloadRecord();
      }
      this.propertyDidChange('timeEntryBreaks');

      this.calculateTime();
      return Ember.RSVP.resolve(this.get('timeEntryBreaks'));
    },
    rollbackBreaks: function rollbackBreaks() {
      var _this8 = this;

      if (!this.get('isDeleted')) {
        this.get('timeEntryBreaks').forEach(function (item) {
          if (Ember.get(item, 'isNew')) {
            _this8.store.unloadRecord(item);
          } else if (Ember.get(item, 'hasDirtyAttributes')) {
            item.rollbackAttributes();
          }
        });
      }
    },
    populate: function populate() {
      var actionType = this.get('actionType');

      (true && !(!Ember.isNone(actionType) && typeof actionType === 'number') && Ember.assert("You must provide the action type for a timeEntry action", !Ember.isNone(actionType) && typeof actionType === 'number'));


      if (actionType === 10 || actionType === 11) {
        // clock in and clock in with location
        var startTime = this.get('startTime');

        this.set('startDeviceTime', _utils.Time.timestamp(startTime));
        this.set('offset', _utils.Time.timezone(startTime));
        this.set('daylightSavingTime', _utils.Time.isDST(startTime));
        this.set('startTimeTrusted', _utils.Time.isTrustedTimeType());
      } else if (actionType === 20 || actionType === 21) {
        // clock out and clock out with location
        var endTime = this.get('endTime');
        var endDeviceTime = _utils.Time.timestamp(endTime);
        var metaOffset = _utils.Time.timezone(endTime);

        // convert endTime to the local time-entry offset time
        endTime = endTime - metaOffset + this.get('offset');
        endDeviceTime = endDeviceTime - metaOffset + this.get('offset');

        this.set('endTime', endTime);
        this.set('endDeviceTime', endDeviceTime);
        this.set('metaOffset', metaOffset);
        this.set('metaDaylightSavingTime', _utils.Time.isDST());
        this.set('endTimeTrusted', _utils.Time.isTrustedTimeType());
      } else if (actionType === 30 || actionType === 31) {
        // clock in at and clock in at with location
        var _startTime = this.get('startTime');

        (true && !(!Ember.isNone(_startTime) && typeof _startTime === 'number') && Ember.assert("You must provide a startTime in seconds for a clock-in-at action", !Ember.isNone(_startTime) && typeof _startTime === 'number'));


        this.set('offset', _utils.Time.timezone(_startTime));
        this.set('daylightSavingTime', _utils.Time.isDST(_startTime));
        this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));
        this.set('startDeviceTime', _utils.Time.timestamp());
      } else if (actionType === 40 || actionType === 41) {
        // clock out at and clock out at with location
        var _endTime = this.get('endTime');

        (true && !(!Ember.isNone(_endTime) && typeof _endTime === 'number') && Ember.assert("You must provide a endTime in seconds for a manual entry action", !Ember.isNone(_endTime) && typeof _endTime === 'number'));


        var _metaOffset = _utils.Time.timezone(_endTime);

        this.set('endTime', _endTime);
        this.set('endDeviceTime', _endTime);
        this.set('metaOffset', _metaOffset);
        this.set('metaDaylightSavingTime', _utils.Time.isDST(_endTime));
        this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));
      } else if (actionType === 50) {
        // manual time action
        var _startTime2 = this.get('startTime');
        var _endTime2 = this.get('endTime');

        (true && !(!Ember.isNone(_startTime2) && typeof _startTime2 === 'number') && Ember.assert("You must provide a startTime in seconds for a manual entry action", !Ember.isNone(_startTime2) && typeof _startTime2 === 'number'));
        (true && !(!Ember.isNone(_endTime2) && typeof _endTime2 === 'number') && Ember.assert("You must provide a endTime in seconds for a manual entry action", !Ember.isNone(_endTime2) && typeof _endTime2 === 'number'));

        // start

        this.set('offset', _utils.Time.timezone(_startTime2));
        this.set('daylightSavingTime', _utils.Time.isDST(_startTime2));
        this.set('startDeviceTime', _startTime2);
        this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));

        // end
        this.set('metaOffset', _utils.Time.timezone(_endTime2));
        this.set('metaDaylightSavingTime', _utils.Time.isDST(_endTime2));
        this.set('endDeviceTime', _endTime2);
        this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));
      } else if (actionType === 60 || actionType === 70 || actionType === 80) {
        // edit time and resolve conflict
        var _startTime3 = this.get('startTime');
        if (!Ember.isNone(_startTime3) && !Ember.isNone(this._internalModel._attributes.startTime)) {
          this.set('startDeviceTime', _startTime3);
          this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));
        }

        var _endTime3 = this.get('endTime');
        if (!Ember.isNone(_endTime3) && !Ember.isNone(this._internalModel._attributes.endTime)) {
          this.set('endDeviceTime', _endTime3);
          this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));
        }
        this.checkAddedAttrs();
      }
    },


    /**
     * Adds aditional fields to legacy timecards
     * that were created before the truested time fields.
     *
     * this is to ensure that an edit of old timecards will have the new data.
     *
     */
    checkAddedAttrs: function checkAddedAttrs() {
      if (Ember.isNone(this.get('startDeviceTime'))) {
        this.set('startDeviceTime', this.get('startTime'));
      }

      if (Ember.isNone(this.get('startTimeTrusted'))) {
        this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));
      }

      // only set this for time cards that are not open.
      if (!Ember.isNone(this.get('endTime'))) {
        if (Ember.isNone(this.get('endDeviceTime'))) {
          this.set('endDeviceTime', this.get('endTime'));
        }

        if (Ember.isNone(this.get('endTimeTrusted'))) {
          this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));
        }
      }
    },


    onCreate: Ember.on('didCreate', function () {
      this.set('_isValid', false);
    }),

    onLoad: Ember.on('didLoad', function () {
      if (!this.get('hasDirtyAttributes')) {
        this.set('_isValid', true);
      }
    }),

    onUpdate: Ember.on('didUpdate', function () {
      if (!this.get('hasDirtyAttributes')) {
        this.set('_isValid', true);
      }
    }),

    onRollback: Ember.on('rolledBack', function () {
      if (!this.get('hasDirtyAttributes')) {
        this.set('_isValid', true);
      }
    }),

    onChange: Ember.observer('hasDirtyAttributes', function () {
      if (this.get('hasDirtyAttributes') && this.get('_isValid')) {
        this.set('_isValid', false);
      }
    }),

    validate: function validate(validateOverlap, signInAnswers, authMember, covidRestricted, isFreeAccount) {
      var _this9 = this;

      if (!this.get('_isValid')) {
        return Ember.RSVP.resolve(this.get('member.memberLock')).then(function () {
          return _timeEntryValidator.default.timeEntry(_this9.get('auth.organization'), _this9, validateOverlap, signInAnswers, authMember, covidRestricted, isFreeAccount).then(function (result) {
            if (result.isValid) {
              _this9.set('_isValid', true);
              return true;
            } else {
              return Ember.RSVP.reject(result);
            }
          });
        });
      } else {
        return Ember.RSVP.resolve(true);
      }
    },
    saveLog: function saveLog(actionType) {
      return this.store.createRecord('client-time-entry-log', {
        updaterMemberId: window.BusyApp._application.get('auth.member.id'),
        originalTimeEntryId: this.id,
        mergedTimeEntryId: this.get('mergedTimeEntryId'),
        timeEntryId: this.id,
        startTime: this.get('startTime'),
        offset: this.get('offset'),
        endTime: this.get('endTime'),
        metaOffset: this.get('metaOffset'),
        daylightSavingTime: this.get('daylightSavingTime'),
        metaDaylightSavingTime: this.get('metaDaylightSavingTime'),
        description: this.get('description'),
        memberId: this.get('memberId'),
        projectId: this.get('projectId'),
        costCodeId: this.get('costCodeId'),
        equipmentId: this.get('equipmentId'),
        actionType: actionType || this.get('actionType'),
        timeEntryCreatedOn: this.get('createdOn'),
        timeEntryDeletedOn: this.get('deletedOn'),
        deviceType: 'Web Browser',
        startDeviceTime: this.get('startDeviceTime'),
        endDeviceTime: this.get('endDeviceTime'),
        startTimeTrusted: this.get('startTimeTrusted'),
        endTimeTrusted: this.get('endTimeTrusted'),
        startLocationId: this.get('startLocationId'),
        endLocationId: this.get('endLocationId')
      }).save();
    },


    /**
     * closes any open breaks if the models action type is a type that
     * requires breaks to be closed prior to taking the action
     *
     * @private
     * @method _closeOpenBreak
     * @return {Promise}
     */
    _closeOpenBreak: function _closeOpenBreak() {
      var _this10 = this;

      // close open break for clock out and clock out at actions
      if ([20, 21, 40, 41].indexOf(this.get('actionType')) !== -1) {
        // close the open break if there is an open break
        var breakEntry = this.get('openBreak');
        if (!Ember.isNone(breakEntry)) {
          // stop break action type
          breakEntry.set('actionType', 20);

          // since this is only on clock out action types then use the entries endTime if it has been set
          breakEntry.set('endTime', this.getWithDefault('endTime', _utils.Time.nistTimestamp()));

          // save the time entry log with an edit action type
          return this.saveLog(70).then(function (log) {
            // save the open break
            return breakEntry.save(_this10, log.id, true);
          });
        }
      }

      // return a promise if no breaks were saved
      return Ember.RSVP.resolve();
    },
    deleteRecord: function deleteRecord(isResolve) {
      this.set('actionType', isResolve ? 100 : 90);
      this.set('deletedOn', _utils.Time.utcTimestamp());
      return this;
    },
    save: function save() {
      var forceSave = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var signInAnswers = arguments[1];
      var authMember = arguments[2];

      var _this11 = this;

      var covidRestricted = arguments[3];
      var isFreeAccount = arguments[4];

      var validateOverlap = forceSave === true ? false : true;
      if (!Ember.isNone(this.get('deletedOn'))) {
        return this.saveLog().then(function () {
          return _this11._internalModel.save();
        });
      } else {
        return this.validate(validateOverlap, signInAnswers, authMember, covidRestricted, isFreeAccount).then(function () {
          _this11.populate();
          return _this11._save();
        });
      }
    },
    _save: function _save() {
      var _this12 = this;

      // closes any open breaks before performing certain action types
      // closeOpenBreak will check for the action and close the break
      // if it needs to be closed.
      return this._closeOpenBreak().then(function () {
        // save the model
        return _this12._internalModel.save().then(function () {
          // save the log
          return _this12.saveLog().then(function (log) {
            // save any other break changes
            return _this12.saveBreaks(log).then(function () {
              // return this model
              return _this12;
            });
          });
        });
      });
    }
  });
});