define('busy-app/utils/models/filters/wage-history', ['exports', 'busy-app/utils/models/filters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _base.default.extend({
		findByMemberId: function findByMemberId(id, olderThanDate) {
			(true && !(!Ember.isNone(id)) && Ember.assert('memberId is required for findByMemberId', !Ember.isNone(id)));


			var params = {
				_desc: ['change_date'],
				member_id: id
			};

			if (!Ember.isNone(olderThanDate)) {
				params._lte = {
					change_date: olderThanDate
				};
			}

			return this.store.query('wage-history', params).then(function (models) {
				return models.objectAt(0);
			});
		},
		findByMemberIds: function findByMemberIds(ids, olderThanDate) {
			(true && !(!Ember.isNone(ids) && (typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object') && Ember.assert('an array of memberIds is required for findByMemberIds', !Ember.isNone(ids) && (typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object'));


			var params = {
				_desc: ['change_date'],
				deleted_on: '_-NULL-_'
			};

			if (!Ember.isNone(olderThanDate)) {
				params._lte = {
					change_date: olderThanDate
				};
			}

			return this.store.findWhereIn('wage-history', 'member_id', ids, params);
		}
	});
});