define('busy-app/components/mod-util/each-type', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Component.extend({
		tagName: '',

		type: null,
		typeKey: null,

		model: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			// create content and set default empty array.
			var content = [];

			// get models
			var models = Ember.get(this, 'model');

			if (!Ember.isNone(models)) {
				// enforce the model is an array of models
				(true && !(Array.isArray(models) || Array.isArray(Ember.get(models, 'content'))) && Ember.assert('<component:each-type> `model` must be an array of model objects.', Array.isArray(models) || Array.isArray(Ember.get(models, 'content'))));

				// only generate the day range objects
				// when the model entries in the array.

				if (Ember.get(models, 'length') > 0) {
					var type = Ember.get(this, 'type');

					(true && !(!Ember.isEmpty(type)) && Ember.assert('<component:each-type> `type` must be a string that is a property of models.', !Ember.isEmpty(type)));


					var typeKey = Ember.get(this, 'typeKey');
					if (Ember.isEmpty(typeKey)) {
						typeKey = type + 'Id';
					}

					content = models.map(function (item) {
						return Ember.get(item, typeKey);
					}).uniq().map(function (id) {
						var _ref;

						var typeModel = void 0;
						if (Ember.isNone(id)) {
							typeModel = {
								title: (0, _utils.loc)('Unassigned'),
								equipmentName: (0, _utils.loc)('Unassigned'),
								costCode: (0, _utils.loc)('Unassigned')
							};
						} else {
							typeModel = Ember.get(models.find(function (m) {
								return Ember.get(m, typeKey) === id;
							}) || {}, type);
						}

						var filter = models.filter(function (m) {
							return Ember.get(m, typeKey) === id;
						}).map(function (m) {
							return createTypeObject(m);
						});

						return _ref = { type: type }, _defineProperty(_ref, typeKey, id), _defineProperty(_ref, type, typeModel), _defineProperty(_ref, 'model', filter), _ref;
					});
				}
			}
			Ember.set(this, 'content', content);
		}
	});


	var createTypeObject = function createTypeObject(model) {
		var isTimeOff = Ember.get(model, 'isTimeOff');
		if (isTimeOff) {
			var endTime = Ember.get(model, 'seconds') > 0 ? _utils.Time.date(Ember.get(model, 'dateStamp')).add(Ember.get(model, 'seconds'), 'seconds').unix() : Ember.get(model, 'dateStamp');

			Ember.set(model, 'startTime', Ember.get(model, 'dateStamp'));
			Ember.set(model, 'endTime', endTime);

			Ember.set(model, 'segments', [{ seconds: [{ regularSeconds: Ember.get(model, 'seconds'), overtimeSeconds: 0, doubletimeSeconds: 0 }] }]);
		}
		return model;
	};
});