define('busy-app/helpers/date-format', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dateFormat = dateFormat;


	/**
  * `DateFormat`
  *
  * Takes a timestamp and a format string and displays the date.
  *
  */
	/**
  * @module helpers
  *
  */
	function dateFormat(params, namedArgs) {
		var timestamp = params[0];
		var offset = namedArgs.offset;
		var isDST = namedArgs.dst;
		var isLocal = namedArgs.local;
		var format = namedArgs.format;
		var timezone = '';

		(true && !(typeof timestamp === 'number' && timestamp > 0) && Ember.assert("date-format requires a timestamp as the first parameter", typeof timestamp === 'number' && timestamp > 0));


		if (Ember.isNone(format)) {
			format = 'L LT';
		}

		if (!Ember.isNone(offset) && !Ember.isNone(isDST)) {
			timezone = ' <sup>' + _utils.Time.timezoneString(offset, isDST) + '</sup>';
		}

		var date = _utils.Time.date(timestamp);
		if (isLocal === true) {
			date.local();
		}

		return date.format(format) + timezone;
	}

	exports.default = Ember.Helper.helper(dateFormat);
});