define('busy-app/utils/models/managers/payroll-member', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,
		type: 'member',
		titleString: 'Name',
		typeString: 'Employee',
		hasImage: true,

		fetch: function fetch(start, end) {
			this.set('start', start);
			this.set('end', end);

			(true && !(!Ember.isNone(start)) && Ember.assert('You must provide a start {number} to PayrollMemberManager', !Ember.isNone(start)));
			(true && !(!Ember.isNone(end)) && Ember.assert('You must provide a end {number} to PayrollMemberManager', !Ember.isNone(end)));


			return Ember.RSVP.hash({
				memberData: this.store.dispatcher('member-pay-period').find(start, end)
			}).then(function (data) {
				return {
					memberCost: data.memberData.cost,
					memberSeconds: data.memberData.seconds,
					wageHistory: data.memberData.wage,
					members: data.memberData.members
				};
			});
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			var members = data.members.sortBy('firstName', 'lastName');
			members.forEach(function (member) {
				if (Ember.isNone(member.get('archivedOn')) || member.get('archivedOn') > _this.get('start')) {
					var actualCost = data.memberCost.findBy('memberId', member.id);
					var actualSeconds = data.memberSeconds.findBy('memberId', member.id);
					var wage = _this.getWageHistory(data.wageHistory.filterBy('memberId', member.id), _this.get('start'));
					if (!Ember.isNone(actualSeconds) && actualSeconds.get('totalSeconds') > 0 || wage.get('wageRate') === 30 || wage.get('wageRate') === 40 || wage.get('wageRate') === 50) {
						var memberData = _this.buildCostData(member, actualSeconds, actualCost, wage);
						_this.addInternalModel(memberData);
					}
				}
			});
		},
		getWageHistory: function getWageHistory(wages, timestamp) {
			// sort wages
			var _wages = wages.sortBy('changeDate');
			var wage = _wages.objectAt(0);
			_wages.forEach(function (item) {
				if (item.get('changeDate') > wage.get('changeDate') && item.get('changeDate') <= timestamp) {
					wage = item;
				}
			});
			return wage;
		},
		buildCostData: function buildCostData(member, memberSeconds, memberCost, wage) {
			var PayrollMemberCollection = this.collectionFor();
			var data = {
				type: this.get('type'),
				id: member.get('id'),
				isModel: true,
				startTime: this.get('start'),
				endTime: this.get('end'),
				member: member, wage: wage, memberCost: memberCost, memberSeconds: memberSeconds
			};
			return PayrollMemberCollection.push(data);
		},


		/**
   * Gets Estimated Hourly Cost for the given time period
   * from all contents in content Array
   * and adds them up and returns the total.
   *
   * @public
   * @method estCostHourly
   * @returns int
   */
		estCostHourly: Ember.computed('model.@each.estCostHourly', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('estCostHourly');
				});
			}
			return total;
		}),

		/**
   * Gets Estimated Salary Cost for the given time period
   * from all contents in content Array who have a wage
   * type of anything but hourly and adds them up and returns the total.
   *
   * @public
   * @method estCostSalary
   * @returns int
   */
		estCostSalary: Ember.computed('model.@each.estCostSalary', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('estCostSalary');
				});
			}
			return total;
		}),

		/**
   * Gets Total Cost for payPeriod and converts it to a String
   *
   * @public
   * @method totalCostString
   */
		totalCost: Ember.computed('model.@each.totalCost', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('totalCost');
				});
			}
			return total;
		}),

		totalSeconds: Ember.computed('model.@each.totalSeconds', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('totalSeconds');
				});
			}
			return total;
		}),

		/**
   * Calculates Total Time for the given Payperiod and converts it to a String
   *
   * @public
   * @method totalTimeString
   */
		totalTimeString: Ember.computed('totalSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('totalSeconds'));
			return time.hours + ' hrs ' + time.minutes + ' mins';
		}),

		regularSeconds: Ember.computed('model.@each.memberRegularSeconds', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('memberRegularSeconds');
				});
			}
			return total;
		}),

		/**
   * Calculates Regular Time for the given Payperiod and converts it to a String
   *
   * @public
   * @method regularTimeString
   */
		regularTimeString: Ember.computed('regularSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('regularSeconds'));
			return time.hours + ' hrs ' + time.minutes + ' mins';
		}),

		overtimeSeconds: Ember.computed('model.@each.memberOvertimeSeconds', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('memberOvertimeSeconds');
				});
			}
			return total;
		}),

		/**
   * Calculates Overtime for the given Payperiod and converts it to a String
   *
   * @public
   * @method overtimeTimeString
   */
		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('overtimeSeconds'));
			return time.hours + ' hrs ' + time.minutes + ' mins';
		}),

		doubletimeSeconds: Ember.computed('model.@each.memberDoubletimeSeconds', function () {
			var total = 0;
			var content = this.get('model');
			if (!Ember.isNone(content)) {
				content.forEach(function (item) {
					total += item.get('memberDoubletimeSeconds');
				});
			}
			return total;
		}),

		/**
   * Calculates Doubletime for the given Payperiod and converts it to a String
   *
   * @public
   * @method doubletimeTimeString
   */
		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('doubletimeSeconds'));
			return time.hours + ' hrs ' + time.minutes + ' mins';
		})
	});
});