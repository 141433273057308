define('busy-app/utils/integrations/google-maps', ['exports', 'busy-app/utils/integrations/base', 'busy-app/utils/settings'], function (exports, _base, _settings) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _base.default.extend({
				featureName: 'integrations/google-maps',

				load: function load(callback) {

						/**
       * google maps api
       */
						var firstSript = document.getElementsByTagName('script')[0];

						//<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9l6NtNAfY-MyplNr9zELO2NPx8b8yX5U&libraries=places"></script>
						var gmaps = document.createElement('script');
						gmaps.async = true;
						gmaps.src = 'https://maps.googleapis.com/maps/api/js?key=' + _settings.default.get('google_maps_key') + '&libraries=places';

						// append google maps
						firstSript.parentNode.insertBefore(gmaps, firstSript);

						// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						// '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						// })(window,document,'script','dataLayer', Settings.get('google_tag_id'));

						callback();
				}
		});
});